// @ts-check
const { te } = require('date-fns/locale');
const { isBannerEmail } = require('./emails');
const { typeMustExtend } = require('./util/type-utils');
const { CONTRACT_ID_FORMAT } = require('./constants/contracts');

//------------------------------------------------------------------------------

/**
 * Feel free to add to these freely:
 * @typedef {never
 *   | "IS_ACACIA_CAPITAL_CORPORATION"
 *   | "IS_APRIL_HOUSING"
 *   | "IS_BANNER"
 *   | "IS_BMC_INVESTMENTS"
 *   | "IS_CLEAR_HEIGHT"
 *   | "IS_CARDINAL"
 *   | "IS_CARDINAL_DEVELOPMENT"
 *   | "IS_CONVENE"
 *   | "IS_EOS"
 *   | "IS_FCP"
 *   | "IS_GARDINER_AND_THEOBALD"
 *   | "IS_GREENPOINTE"
 *   | "IS_HARBOR"
 *   | "IS_INDUSTRIOUS"
 *   | "IS_INTRUST_PROPERTY_GROUP"
 *   | "IS_JRK"
 *   | "IS_KENNEDY_WILSON"
 *   | "IS_KETTLER"
 *   | "IS_LIVCOR_ADMIN"
 *   | "IS_LIVCOR"
 *   | "IS_MAA"
 *   | "IS_MAGNOLIA"
 *   | "IS_METROVATION"
 *   | "IS_MORGAN_CONTRACTS"
 *   | "IS_MORGAN_BUDGETING"
 *   | "IS_MORGAN"
 *   | "IS_MRA"
 *   | "IS_OLYMPUS_PROPERTY"
 *   | "IS_OLYMPUS_DEVELOPMENT"
 *   | "IS_OSSO"
 *   | "IS_PAULSCORP"
 *   | "IS_PRG_REAL_ESTATE"
 *   | "IS_RIDC"
 *   | "IS_RUDIN"
 *   | "IS_STARLIGHT"
 *   | "IS_STARWOOD"
 *   | "IS_STONEWEG"
 *   | "IS_SUMMIT"
 *   | "IS_TIDES"
 *   | "IS_TITAN_CORP"
 *   | "IS_TOURMALINE"
 *   | "IS_UNIT_DEMO"
 *   | "IS_BANNER_DEMO_ENV_1"
 *   | "IS_WATERTON"
 *   | "IS_ACC"
 *   | "IS_KPR"
 *   | "IS_ACC_DEV"
 *   | "IS_RADCO"
 *   | "IS_LIVCOR_ROI"
 *   | "IS_MILEWAY_DEMO"
 *   | "IS_FLYNN"
 *   | "IS_DEMO_ENV_GU"
 *   | "IS_DAYRISE"
 *   | "IS_AIR"
 *   | "IS_DEMO_ENV_CAPEX"
 *   | "IS_UPCAMPUS"
 *   | "IS_AIRBNB"
 *   | "IS_LANTOWER"
 *   | "IS_GRIFFIN"
 *   | "IS_29TH_STREET"
 *   | "IS_ABACUS"
 *   | "IS_DEMO_ENV_GL"
 *   | "IS_WILLOW_BRIDGE"
 * } KNOWN_TEAM_KEYS
 * `db.teams.distinct("name")`
 *
 * @typedef {never
 *   | "BH" // IS_LIVCOR_SUBGROUP_BH
 *   | "GREYSTAR" // IS_LIVCOR_SUBGROUP_GREYSTAR
 *   | "NOSUBGROUP" // IS_FCP_SUBGROUP_NOSUBGROUP
 * } KNOWN_MANAGER_PROFILE_SUB_GROUP_NAMES
 * `db.managerProfiles.distinct("subGroupName")`
 *
 * @typedef {never
 * } KNOWN_MANAGER_PROFILE_COMPANY_NAMES
 * `db.managerProfiles.distinct("companyName")`
 *
 * @typedef {never
 *   | 'PROPERTY_MANAGER'
 * } KNOWN_UFG_NAMES
 *
 * @typedef {'number' | 'contractorID' | 'total' | 'buildingID' | 'title'} InvoiceDedupFieldName
 * 
 * @typedef {Object} DateOptions
 * @property {string} locale
 * @property {string} timeZone
 */

/** @type {Record<string, DateOptions>} */
const DATE_OPTIONS = {
  NewYork: { locale: 'en-US', timeZone: 'America/New_York' },
};
const DEFAULT_DATE_OPTIONS = DATE_OPTIONS.NewYork;

const USER_FEATURES = /** @type {const} */ ([
  { code: 'featureAddNewProject', name: 'Add New Project' },
  { code: 'featureAddBudget', name: 'Add Budget' },
  { code: 'featureEditCostItem', name: 'Edit Cost Item' },
  { code: 'featureEditBuilding', name: 'Edit Building' },
  { code: 'featureAddSection', name: 'Add Section' },
  { code: 'featureAddCostItem', name: 'Add Cost Item' },
  { code: 'featureAddInvoice', name: 'Add Invoice' },
  { code: 'featureAddContract', name: 'Add Contract' },
  { code: 'featureUseBidSetup', name: 'Bid Setup' },
  { code: 'featureUseBidProjectInfo', name: 'Project Info' },
  { code: 'featureUseBidDocuments', name: 'Documents' },
  { code: 'featureUseBidForm', name: 'Bid Form' },
  { code: 'featureUseBidVendors', name: 'Vendors' },
  { code: 'featureAddHolds', name: 'Add Holds' },
  { code: 'featureCancelContract', name: 'Cancel Contract' },
  { code: 'featureSaveReports', name: 'Save Reports' },
  { code: 'featureEditCompanySettings', name: 'Edit Company Settings' },
  {
    code: 'featureEditCostItemShellPermissions',
    name: 'Edit Cost Item/Shell Permissions',
  },
  { code: 'featureAddChangeOrder', name: 'Add Change order' },
  { code: 'featureUseBannerUpload', name: 'Enable Banner Upload' },
  {
    code: 'featureUseMilestoneInteraction',
    name: 'Enable Milestone Interaction',
  },
  {
    code: 'featureUseDocumentsInteraction',
    name: 'Enable Documents Interaction',
  },
  {
    code: 'featureAddVendor',
    name: 'Enable Add Vendor',
  },
  {
    code: 'featureCreateHoldsReallocation',
    name: 'Create Holds Reallocation',
  },
  {
    code: 'featureUseEditCashFlowRules',
    name: 'Edit Cash Flow Rules',
  },
  {
    code: 'featureUseReports',
    name: 'Reports',
  },
  {
    code: 'featureDeleteProjectShellSection',
    name: 'Delete Project Shell Section',
  },
  {
    code: 'featureRenameProjectShellSection',
    name: 'Rename Project Shell Section',
  },
  {
    code: 'featureUseDeleteInvoiceFromContractRoom',
    name: 'Delete Invoice From Contract Room',
  },
  {
    code: 'featureUseDeleteChangeOrderFromContractRoom',
    name: 'Delete Change Order From Contract Room',
  },
  {
    code: 'userFeatureUseManagerVendorInvites',
    name: 'Enable Manage Vendor Invites Modal',
  },
  {
    code: 'userFeatureProjectShellCreateCmFeeInvoice',
    name: 'Enable Creating CM Fee Invoice from Project Shell',
  },
  {
    code: 'userFeatureViewDrawReports',
    name: 'Enable View Reports button in Draw Report Tab (Project Shell)',
  },
  // Depends on another user Feature “featureAddNewProject”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseDisableAddProject',
    name: 'Prevents adding a project shell, hides add project button',
    requiredFeatureFlags: ['featureUseDisableAddProject'],
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseDisableEditProject',
    name: 'Hides edit shell option in row 3 dot menu on dashboard',
    requiredFeatureFlags: ['featureUseDisableEditProject'],
  },
  {
    code: 'userFeatureEditDashboardInvoicesDrawNumber',
    name: 'Enables the editing of draw number column in invoices table on dashboard',
    requiredFeatureFlags: ['featureUseInvoicesDrawNumber'],
  },
  {
    code: 'userFeatureUseDuplicateChangeOrder',
    name: 'Allows user to duplicate a change order in contract room',
    requiredFeatureFlags: ['featureUseDuplicateChangeOrders'],
  },
  {
    code: 'userFeatureMarkInvoiceAsSendToAP',
    name: 'Allows user to mark invoice invoice as sent to ap on dashboard invoices, contract room and invoice details',
  },
  {
    code: 'userFeatureAllowEditingInvoiceNumberAndTitle',
    name: 'Allows user to edit invoice number and title',
    requiredFeatureFlags: ['featureAllowEditingInvoiceNumberAndTitle'],
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseDisableDeleteProject',
    name: 'Hides delete shell option in row 3 dot menu on dashboard',
    requiredFeatureFlags: ['featureUseDisableDeleteProject'],
  },
  {
    code: 'userFeatureUseMassEdit',
    name: 'Provides ability to make bulk edits in the Dashboard without having to reload every time after making a change',
    requiredFeatureFlags: ['featureUseMassEdit'],
  },
  // Depends on another user Feature “featureAddBudget”. Need to include this for this feature to work.
  {
    code: 'featureBudgetAddRevision',
    name: 'Enable ability to add a budget revision from the Budget tab in Dashboard',
  },
   // Depends on another user Feature “featureAddBudget”. Need to include this for this feature to work.
  {
    code: 'featureBudgetRevisionsAddRevision',
    name: 'Enable ability to add a budget revision from the Budget Revisions tab in Dashboard',
  },

  {
    code: 'userFeatureUseProjectStageInShellNavbar',
    name: 'Enable ability to see project stage dropdown in shell navbar',
  },
  {
    code: 'userFeatureDeleteVendors',
    name: 'Shows the Delete Option in vendors table Edit menu',
  },
  {
    code: 'userFeatureAddEditVendors',
    name: 'Shows the Add contact and edit options in vendors table Edit menu',
  },
  {
    code: 'userFeatureAddApprovedCODocuments',
    name: 'Shows the add documents button on approved CO',
  },
  {
    code: 'userFeatureDeleteApprovedCODocuments',
    name: 'Shows the delete button on approved CO documents',
  },
  {
    code: 'userFeatureAddApprovedInvoiceDocuments',
    name: 'Shows the add documents button on approved Invoice',
  },
  {
    code: 'userFeatureDeleteApprovedInvoiceDocuments',
    name: 'Shows the delete button on approved Invoice documents',
  },
  {
    code: 'userFeatureAddApprovedContractDocuments',
    name: 'Shows the add documents button on approved Contract',
  },
  {
    code: 'userFeatureDeleteApprovedContractDocuments',
    name: 'Shows the delete button on approved Contract documents',
  },
  {
    code: 'userFeatureUseDocusignDocumentMenu',
    name: 'Shows the three dot menu on docusign attachments',
  },
  {
    code: 'userFeatureAddContractRoomUnitPrice',
    name: 'Shows add unit price option in unit prices section menu in contract room',
  },

  {
    code: 'userFeatureEditShellTrackerUnitNumber',
    name: 'Enables editing the unit number in shell cost tracker table',
  },
  // Depends on another user Feature “featureAddBudget”. Need to include this for this feature to work.
  {
    code: 'featureBudgetSaveSnapshot',
    name: 'Enable Save Snapshot from the Budget tab in Dashboard',
  },
  {
    code: 'featureBudgetDeleteSnapshot',
    name: 'Enable DELETE Snapshot from the snapshot tab in the Dashboard',
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseAssignCostItemGLCode',
    name: 'Enable “Assign GL Code” in the Cost Item three-dot-menu',
    requiredFeatureFlags: ['featureUseAssignCostItemToGlCode'],
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseDisableDeleteCostItem',
    name: 'Hides delete cost item option in row 3 dot menu in project shell',
    requiredFeatureFlags: ['featureUseDisableDeleteCostItem'],
  },
  {
    code: 'userFeatureAddComplexNonContract',
    name: 'Provide ability to add complex non-contract to a cost item in 3 dot menu',
    requiredFeatureFlags: ['featureAddComplexNonContract'],
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureAddNonContract',
    name: 'Provide ability to add non-contract to a cost item in 3 dot menu',
    requiredFeatureFlags: ['featureAddNonContract'],
  },
  // Depends on another user Feature “featureEditCostItem”. Need to include this for this feature to work.
  {
    code: 'userFeatureUseProjectShellCostTrackerBidOutOption',
    name: 'Provide ability to bid out a contract from shell cost item three dot menu',
    requiredFeatureFlags: ['featureUseProjectShellCostTrackerBidOutOption'],
  },
  {
    code: 'featureLoadMilestoneTemplates',
    name: 'Enable loading Milestones from a template',
  },
  {
    code: 'featureCreateNewMilestone',
    name: 'Enable creating a new milestone from scratch',
  },
  // Depends on another user Feature “featureUseMilestoneInteraction”. Need to include this for this feature to work.
  {
    code: 'featureDeleteAllMilestones',
    name: 'Enable deleting all milestones',
  },
  {
    code: 'userFeatureUseCommentsOnCostItems',
    name: 'Provide the Comments Column and commenting functionality at Cost item level',
    requiredFeatureFlags: ['featureUseCommentsOnCostItems'],
  },
  {
    code: 'userFeatureExportLongTermCSV',
    name: 'Provide the ability to export csv from long term budget table',
  },
  {
    code: 'userFeatureDisableEditCostItemOnLTBTable',
    name: 'Shows the three dot menu on cost item in long term budget table',
    requiredFeatureFlags: ['featureDisableLTBEditMenu'],
  },
  {
    code: 'userFeatureRenameAndEditCostCodeOnLongTermBudgetTable',
    name: 'Shows the options to rename cost item and edit cost code in long term budget table three dot cost item menu',
  },
  {
    code: 'userFeatureDeleteCostItemOnLongTermBudgetTable',
    name: 'Shows the option to delete cost item in long term budget table three dot cost item menu',
  },
  {
    code: 'featureEditSOV',
    name: 'Enable editing SOV GL Code and Tags from the Contract Schedule of Value section in Contract Room',
  },
  // Need both Team Feature Flags “featureUseMarkInvoicePTD” and “featureUseMarkInvoicePTDinTable” to be turned on.
  {
    code: 'userFeatureUseMarkInvoicePTD',
    name: 'Enable to manually mark an invoice Paid',
    requiredFeatureFlags: ['featureUseMarkInvoicePTD', 'featureUseMarkInvoicePTDinTable'],
  },
  // with this permission is allowed to Add, Delete and Edit a Change Order Draft
  {
    code: 'userFeatureManageChangeOrderDrafts',
    name: 'Enable to Add, Delete and Edit a Change Order Draft',
    requiredFeatureFlags: ['featureUseContractDraftChangeOrders'],
  },

   // with this permission is allowed to Add, Delete and Edit a Invoice Draft
   {
    code: 'userFeatureManageInvoiceDrafts',
    name: 'Enable to Add, Delete and Edit a Invoice Draft',
    requiredFeatureFlags: ['featureUseContractDraftInvoices'],
  },
  {
    code: 'userFeatureDashboardHideTabCosts',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabBudget',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabUnderwritingBudget',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabSnapshots',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabBudgetRevisions',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabLongTermBudget',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabCashflow',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabInvoices',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabContracts',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideTabChangeOrders',
    name: 'Hide Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardTabSiteWalkForm',
    name: 'Show Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardTabRenovations',
    name: 'Show Dashboard tab',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideSectionTop',
    name: 'Hide Dashboard Section',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideSectionPropertyFilter',
    name: 'Hide Dashboard Section',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideArchiveProjects',
    name: 'Hide Dashboard Section',
    default: false,
  },
  {
    code: 'userFeatureDashboardHideSectionTagFilters',
    name: 'Hide Dashboard Section',
    default: false,
  },
  {
    code: 'userFeatureEditLongTermDescription',
    name: 'Edit Long Term Description',
  },
  {
    code: 'userFeatureEditLongTermDates',
    name: 'Edit Long Term Dates',
  },
  {
    code: 'userFeatureRenameCostItem',
    name: 'Enable “Rename” in the Cost Item three-dot-menu',
  },
  {
    code: 'userFeatureEditReportPreset',
    name: 'Enables "Edit" preset option in Custom Reports',
  },
  {
    code: 'userFeatureDeleteReportPreset',
    name: 'Enables "Delete" preset option in Custom Reports',
  },
  {
    code: 'userFeatureExportTags',
    name: 'Enables "Export Tags" button in Custom Reports',
  },
  {
    code: 'userFeatureUploadNewTemplate',
    name: 'Enables "Upload New Template" button in Custom Templates',
  },
  {
    code: 'userFeatureDownloadTemplate',
    name: 'Enables "Download Template" button in Custom Templates',
  },
  {
    code: 'userFeatureDeleteTemplate',
    name: 'Enables "Delete Template" button in Custom Templates',
  },
  {
    code: 'userFeatureEditTemplate',
    name: 'Enables "Edit Template" button in Custom Templates',
  },
  {
    code: 'userFeatureImportTags',
    name: 'Enables "Import Tags" Navigation in Reports',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowDataUploader"
  {
    code: 'userFeatureHideDataUploader',
    name: 'Hides "Data Uploader" Navigation in Settings',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowLTBImporterOption"
  {
    code: 'userFeatureHideDataUploaderLongTermBudget',
    name: 'Hides dropdown option "Long Term Budget - Edit" in Data Uploader',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowOtherDataOptions"
  {
    code: 'userFeatureHideDataUploaderInvoiceTagValue',
    name: 'Hides dropdown option "Invoice - Tag Value" in Data Uploader',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowOtherDataOptions"
  {
    code: 'userFeatureHideDataUploaderInvoiceCostItem',
    name: 'Hides dropdown option "Invoice - Cost Item ID',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowOtherDataOptions"
  {
    code: 'userFeatureHideDataUploaderInvoiceCostItemAdvancedDedup',
    name: 'Hides dropdown option "Invoice - Cost Item ID advanced with dedup" in Data Uploader',
  },
  // If Excluded in user profile will show the option irrespective of team wide flag "featureShowInvoiceIDAdvanced"
  {
    code: 'userFeatureHideDataUploaderInvoiceCostItemAdvanced',
    name: 'Hides dropdown option "Invoice - Cost Item ID advanced" in Data Uploader',
  },
  // user feature to enable “Resend” button for non-admin users
  {
    code: 'userFeatureAllowErrorResend',
    name: 'Enable Resend Button in Error Center',
    default: false,
  },
  // user feature to enable “Acknowledge” button for non-admin users
  {
    code: 'userFeatureAllowErrorAcknowledge',
    name: 'Enable Acknowledge Button in Error Center',
    default: false,
  }

]);
/** @typedef {(typeof USER_FEATURES[number]['code'])} UserFeatureKey */

const FEATURE_GENERATOR_MAP = (() => {
  const IS_ENTERPRISE_MFA = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY || tempRoles.IS_APRIL_HOUSING;
  const IS_ENTERPRISE_MFA_ADMIN = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_LIVCOR_ADMIN || tempRoles.IS_OLYMPUS_PROPERTY_ADMIN || tempRoles.IS_APRIL_HOUSING_ADMIN;

  const IS_ENTERPRISE_MFA_OP_ADMIN = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_LIVCOR_OPERATING_PARTNER_ADMIN ||
    tempRoles.IS_OLYMPUS_PROPERTY_OPERATING_PARTNER_ADMIN ||
    tempRoles.IS_APRIL_HOUSING_OPERATING_PARTNER_ADMIN;

  const IS_ENTERPRISE_MFA_CAPEX_MEMBER = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_LIVCOR_CAPEX_MEMBER ||
    tempRoles.IS_OLYMPUS_PROPERTY_CAPEX_MEMBER ||
    tempRoles.IS_APRIL_HOUSING_CAPEX_MEMBER;

  const IS_ENTERPRISE_MFA_ANALYST = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_LIVCOR_ANALYST ||
    tempRoles.IS_OLYMPUS_PROPERTY_ANALYST ||
    tempRoles.IS_APRIL_HOUSING_ANALYST;

  const USE_VENDOR_CODES = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_FCP ||
    tempRoles.IS_STARWOOD ||
    tempRoles.IS_INDUSTRIOUS ||
    tempRoles.IS_CONVENE ||
    tempRoles.IS_MAGNOLIA ||
    tempRoles.IS_EOS ||
    tempRoles.IS_ACC_DEV ||
    tempRoles.IS_OSSO ||
    tempRoles.IS_CARDINAL ||
    tempRoles.IS_CARDINAL_DEVELOPMENT ||
    tempRoles.IS_OLYMPUS_DEVELOPMENT ||
    tempRoles.IS_RIDC ||
    tempRoles.IS_ACC ||
    tempRoles.IS_WATERTON ||
    tempRoles.IS_FLYNN ||
    tempRoles.IS_RADCO ||
    tempRoles.IS_DAYRISE ||
    tempRoles.IS_DEMO_ENV_GU ||
    tempRoles.IS_AIR ||
    tempRoles.IS_UPCAMPUS ||
    tempRoles.IS_MRA ||
    tempRoles.IS_AIRBNB;

  /** *************** CASHFLOW SECTION 1************************ */

  /** Cashflows at CostItem level */
  const featureUseCostItemBasedCashflows = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_TOURMALINE ||
    tempRoles.IS_MORGAN_BUDGETING ||
    tempRoles.IS_MORGAN ||
    tempRoles.IS_INDUSTRIOUS ||
    tempRoles.IS_CLEAR_HEIGHT ||
    tempRoles.IS_CONVENE ||
    tempRoles.IS_RIDC ||
    tempRoles.IS_MAGNOLIA ||
    tempRoles.IS_EOS ||
    tempRoles.IS_GREENPOINTE ||
    tempRoles.IS_BANNER ||
    tempRoles.IS_GARDINER_AND_THEOBALD ||
    tempRoles.IS_SUMMIT ||
    tempRoles.IS_TIDES ||
    tempRoles.IS_BANNER_DEMO_ENV_1 ||
    tempRoles.IS_KPR ||
    tempRoles.IS_DEMO_ENV_GU ||
    tempRoles.IS_DEMO_ENV_CAPEX;

  /** Cashflows at Shell level. No break down to Cost Item level. Show only Total at Cost Item level */
  const featureUseShellBasedCashflows = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_MRA ||
    tempRoles.IS_ACC ||
    tempRoles.IS_PRG_REAL_ESTATE ||
    tempRoles.IS_SUMMIT ||
    tempRoles.IS_TIDES ||
    tempRoles.IS_RIDC ||
    tempRoles.IS_KPR ||
    tempRoles.IS_OSSO ||
    tempRoles.IS_DEMO_ENV_CAPEX ||
    tempRoles.IS_LANTOWER ||
    tempRoles.IS_AIR;

  /** Breakdown Cashflows to Monthly views as oppose to Yearly */
  const featureUseMonthlyCashflows = (/** @type {_Roles} */ tempRoles) =>
    featureUseCostItemBasedCashflows(tempRoles) ||
    featureUseShellBasedCashflows(tempRoles) ||
    IS_ENTERPRISE_MFA(tempRoles);

  const featureUseCostItemBasedCashflowCustomReports = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_INDUSTRIOUS ||
    tempRoles.IS_CLEAR_HEIGHT ||
    tempRoles.IS_MORGAN_BUDGETING ||
    tempRoles.IS_MORGAN ||
    tempRoles.IS_CONVENE ||
    tempRoles.IS_MAGNOLIA ||
    tempRoles.IS_EOS ||
    tempRoles.IS_CARDINAL ||
    tempRoles.IS_CARDINAL_DEVELOPMENT ||
    tempRoles.IS_GARDINER_AND_THEOBALD ||
    tempRoles.IS_BANNER_DEMO_ENV_1 ||
    tempRoles.IS_DEMO_ENV_GU ||
    tempRoles.IS_ABACUS;

  /** Shows Stage column in dashboard cost tracker */
  const featureUseStagesInDashboardTracker = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_STONEWEG ||
    tempRoles.IS_GARDINER_AND_THEOBALD ||
    tempRoles.IS_KENNEDY_WILSON ||
    tempRoles.IS_BMC_INVESTMENTS ||
    tempRoles.IS_STARWOOD ||
    tempRoles.IS_CLEAR_HEIGHT ||
    tempRoles.IS_TOURMALINE ||
    tempRoles.IS_CONVENE ||
    tempRoles.IS_MAGNOLIA ||
    tempRoles.IS_OLYMPUS_DEVELOPMENT ||
    tempRoles.IS_RIDC ||
    tempRoles.IS_INDUSTRIOUS ||
    tempRoles.IS_SUMMIT ||
    tempRoles.IS_ACC ||
    tempRoles.IS_WATERTON ||
    tempRoles.IS_FLYNN ||
    tempRoles.IS_RADCO ||
    tempRoles.IS_KETTLER ||
    tempRoles.IS_DEMO_ENV_GU ||
    tempRoles.IS_AIR ||
    tempRoles.IS_AIRBNB ||
    tempRoles.IS_MRA ||
    tempRoles.IS_LANTOWER ||
    tempRoles.IS_GRIFFIN ||
    tempRoles.IS_29TH_STREET;

    

  /** To be used with customers who want GL Code Budgets. Creates a GL Codes Table in Cost Tracker */
  const featureUseGLCodeBudgets = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
    tempRoles.IS_MILEWAY_DEMO || 
    tempRoles.IS_CARDINAL || 
    tempRoles.IS_CARDINAL_DEVELOPMENT || 
    tempRoles.IS_RADCO ||
    tempRoles.IS_UPCAMPUS ||
    tempRoles.IS_ABACUS ||
    tempRoles.IS_DEMO_ENV_GL;
  /* */

  const featureUseFundingSources = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_UPCAMPUS;

  const featureUseReAssignInvoiceAcrossShells = (/** @type {_Roles} */ tempRoles) =>
    tempRoles.IS_BANNER ||
    tempRoles.IS_OSSO ||
    tempRoles.IS_STARWOOD ||
    tempRoles.IS_29TH_STREET;

  /** dummy value with type specified that {@link typeMustExtend} can pick up on */
  const mapType = /** @type {{ [ key: string ]: (tempRoles: _Roles) => any }} */ (
    /** @type {unknown} */ (null)
  );

  const _UNCATEGORIZED = typeMustExtend(mapType, {
    /** [Bespoke] Allows Owner to show subset of GL codes in the add project modal based on property */
    featureUseGlFormatAssetTag: (tempRoles) => tempRoles.IS_KETTLER,

    // featureCurrencySystem: (tempRoles) => false,
    /** Feature for Supporting Currency other than USD. Static FX Rates defined by user in Settings */
    featureCurrencySystem: (tempRoles) => tempRoles.IS_INDUSTRIOUS || tempRoles.IS_CONVENE || tempRoles.IS_AIRBNB,

    /** @returns {false} Development not completed yet. Disabling in V1. Will finish it up in V2. */
    featureCurrenciesInvoiceRate: (tempRoles) => false,
    /** @returns {false} not yet implemented because `<CurrenciesInputRate />` hasn't got added to `AddContract.tsx` yet */
    featureCurrenciesContractRate: (tempRoles) => false,

    /** cannot be used with any of the following
     * featureUseHoldsColumn OR
     * featureUseBalanceColumn OR
     * featureUseHoldsReallocation */
    featureHoldsAttachToGLCode: (tempRoles) => 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_RADCO || 
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_MORGAN_CONTRACTS || 
      tempRoles.IS_TIDES || 
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,
  });

  const _PERMISSIONS = typeMustExtend(mapType, {
    /** Turns on team switcher for owner. Users cannot switch teams to a team that doesn't have this turned on. */
    featureUseTeamSwitcher: (tempRoles) => true,

    /** Prevents adding a project shell, hides add project button */
    featureUseDisableAddProject: (tempRoles) =>
      ((tempRoles.IS_FCP ||
        tempRoles.IS_TITAN_CORP ||
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
        tempRoles.IS_ABACUS ||
        tempRoles.IS_MILEWAY_DEMO ||
        tempRoles.IS_ACC_DEV ||
        tempRoles.IS_DEMO_ENV_GL) &&
        !tempRoles.IS_BANNER_EMAIL) ||
      (tempRoles.IS_OSSO && !tempRoles.IS_OSSO_ADMIN) ||
      (tempRoles.IS_MORGAN && !tempRoles.IS_MORGAN_ADMIN) ||
      (tempRoles.IS_DAYRISE && !tempRoles.IS_DAYRISE_ADMIN),

    /** Hides edit shell option in row 3 dot menu on dashboard */
    featureUseDisableEditProject: (tempRoles) =>
      (tempRoles.IS_OSSO && !tempRoles.IS_OSSO_ADMIN) || (tempRoles.IS_MORGAN && !tempRoles.IS_MORGAN_ADMIN),

    /** Hides delete shell option in row 3 dot menu on dashboard */
    featureUseDisableDeleteProject: (tempRoles) =>
      ((tempRoles.IS_MORGAN || 
        tempRoles.IS_TOURMALINE || 
        tempRoles.IS_ACC ||
        tempRoles.IS_FCP ||
        tempRoles.IS_TITAN_CORP) && 
        !tempRoles.IS_BANNER_EMAIL) ||
      (tempRoles.IS_OSSO && !tempRoles.IS_OSSO_ADMIN),

    /** Hides delete cost item option in row 3 dot menu in project shell */
    featureUseDisableDeleteCostItem: (tempRoles) =>
      ((tempRoles.IS_MORGAN || 
        tempRoles.IS_TOURMALINE || 
        tempRoles.IS_TITAN_CORP) && 
        !tempRoles.IS_BANNER_EMAIL) ||
      (tempRoles.IS_OSSO && !tempRoles.IS_OSSO_ADMIN) ||
      (tempRoles.IS_ACC && !tempRoles.IS_ACC_ADMIN),

    /** Hides Add Contract option in 3 dot menu of Shell Cost Tracker and Bid-room */
    featureShowAddContractOption: (tempRoles) => !tempRoles.IS_DAYRISE,
  });

  const _TAGS = typeMustExtend(mapType, {
    /** relies on Subsidiary Tag to be present in the system with Location: Create Project Pop up */
    featureValidationRequireProjectSubsidiaryTag: (tempRoles) => false, // temporarily disabling tempRoles.IS_INDUSTRIOUS,

    /** [Bespoke] Invoices held by Industrious require special handling */
    featureInvoicesHeldByIndustrious: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    featureUseFundingSources,

    /** Enforces an ownership field being filled out in multiple places */
    featureUseOwnershipTag: (tempRoles) =>
      featureUseFundingSources(tempRoles) ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_GRIFFIN,
    
    // relies on featureUseOwnershipTag
    featureUseTiAllowance: (tempRoles) => 
      featureUseFundingSources(tempRoles) ||
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_TOURMALINE || 
      tempRoles.IS_BANNER || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_GRIFFIN,

    featureUseTiAllowanceCustomOrgs: (tempRoles) => 
      featureUseFundingSources(tempRoles) ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_UPCAMPUS,

    /** Allows tag ordering to be changed in tag settings (affects order they show up in, in various places) */
    featureUseTagOrdering: (tempRoles) => true,

    /** [Bespoke] Required feature for snapshotting info */
    featureUseIndustriousLogNewProjects: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    /** [Bespoke] Required feature for snapshotting info */
    featureUseIndustriousLogDeadProjects: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    featureShowTagsInNonContract: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_DAYRISE || tempRoles.IS_ACC_DEV,
  });

  const _INVOICES = typeMustExtend(mapType, {
    /** [Bespoke] Shows Third Party ID on invoice approval page */
    featureInvoiceShowCoupaID: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    /** Require Ownership Tag when adding a new invoice. Requires {@link _TAGS.featureUseOwnershipTag}  */
    featureInvoiceEnforceOwnernershipTag: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    /** https://www.notion.so/withbanner/Refresh-Invoice-SOVs-151671b8e45b80ada893e394bece38f7  */
    featureRefreshInvoiceLineItems: (tempRoles) => tempRoles.IS_ACC_DEV,

    /** Shows a singular invoice date in all relevant places */
    featureUseInvoiceDate: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_FCP ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_STONEWEG,

    /** Shows invoice start and end date. Can be used along with singular invoice date */
    featureUseInvoiceStartEndDate: (tempRoles) =>
      !tempRoles.IS_CLEAR_HEIGHT &&
      !tempRoles.IS_CONVENE &&
      !tempRoles.IS_FCP &&
      !tempRoles.IS_PRG_REAL_ESTATE &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_FLYNN &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_BMC_INVESTMENTS,

      featureUseInvoiceSOVNotes: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,

    /** Shows invoice start date and renames column to Post Date. Can be used along with singular invoice date */
    featureInvoiceStartDateIsPostDate: (tempRoles) => tempRoles.IS_PRG_REAL_ESTATE,

    /** Hides send to AP checkbox/info on invoice add/approval */
    featureUseSendToAP: (tempRoles) => true,

    /** Hides send to AP checkbox/info on Add Invoice and relies on featureUseSendToAP */
    featureUseSendToAPHiddenButEnabled: (tempRoles) => false,

    /** relies on featureUseSendToAP */
    featureUseDefaultCheckedForSendToAP: (tempRoles) =>
      !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT && !tempRoles.IS_KENNEDY_WILSON,

    /** relies on featureUseSendToAP */
    featureUseMarkInvoiceAsSendToAP: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseInvoiceReallocation: (tempRoles) => tempRoles.IS_ACC_DEV,


    /** Requires comments when Send To AP is unchecked, depends on featureUseSendToAP */
    featureRequireCommentsOnUncheckSendToAP: (TempRoles) => TempRoles.IS_EOS,

    /** shouldn't use with any enabled AP, it will always take full invoice amount as check amount */
    featureUseMarkInvoicePTD: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_FCP_ADMIN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,


    /** shouldn't use with any enabled AP, it will always take full invoice amount as check amount */
    featureUseMarkInvoicePTDinTable: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FLYNN || 
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_FCP_ADMIN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Provide Ability to Mark any Invoice PTD from the UI 
     *  regardless of paid status through integration
     */
    featureUseMarkInvoicePTDRegardlessPaidStatus: (tempRoles) => 
      tempRoles.IS_TIDES || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,


    /** Enforce adding a date when marking an invoice as Paid */
    featureUseMarkInvoicePTDDateRequired: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FLYNN || 
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_FCP_ADMIN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Uses invoice approved dated in cashflows to categorize invoices */
    featureUseInvoiceApprovedDate: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** Exports invoice table to csv */
    featureInvoiceTableExportInvoicePdfs: (tempRoles) => true,

    /** Allows user to submit $0 invoice */
    featureAllowInvoiceZeroTotalAmount: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_EOS,

    /** Shows invoice status on invoices table as a column */
    featureUseInvoiceStatus: (tempRoles) => true,

    /** Shows Paid to Date column in invoices table */
    featureUseInvoicePaid: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_FCP ||
      tempRoles.IS_ACC || 
      tempRoles.IS_STARWOOD ||
      IS_ENTERPRISE_MFA(tempRoles) ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_LANTOWER,

    /** Shows file name column in invoices table */
    featureUseInvoiceFileName: (tempRoles) =>
      tempRoles.IS_FCP || tempRoles.IS_STARWOOD || tempRoles.IS_TOURMALINE,

    /** Shows Unapproved Data validation invoices in contract room. */
    featureShowDataUploadInInvoices: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MRA ||
      tempRoles.IS_FCP ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_SUMMIT || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG,

    /** When sending invoice to AP through email, attaches cover sheet PDF to process */
    featureUseExportInvoiceSummaryCoversheet: (tempRoles) =>
      tempRoles.IS_MRA ||
      tempRoles.IS_FCP ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN,

    /** Adds a tag called 'Project ID' to invoice export cover sheet */
    featureUseExportInvoiceSummaryCoversheetProjectID: (tempRoles) =>
      tempRoles.IS_CONVENE || 
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MRA,

    featureUseExportInvoiceSummaryCoversheetJobCode: (tempRoles) => tempRoles.IS_RIDC || tempRoles.IS_GRIFFIN,

    /** Shows Contract ID in the Invoice Export Coversheet for export configurations */
    featureUseExportInvoiceSummaryCoversheetContractID: (tempRoles) => tempRoles.IS_RIDC || tempRoles.IS_AIR,

    /** Adds vendor code to invoice export cover sheet */
    featureUseExportInvoiceSummaryCoversheetVendorCode: (tempRoles) =>
      tempRoles.IS_CONVENE || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_FCP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_GRIFFIN,

    /** [DEPRECATED] Currently not hooked up to anything in code base */
    featureUseMRALogoSummaryCoversheet: (tempRoles) => tempRoles.IS_MRA,

    featureShowSubmissionSummary: (tempRoles) => !tempRoles.IS_CARDINAL &&
    !tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureShowFinancialsOnSubmissionSetup: (tempRoles) => tempRoles.IS_CARDINAL||
    tempRoles.IS_CARDINAL_DEVELOPMENT,

    /** Allows user to resend invite email on Approved Contracts, controlls "Manage Vendor Invites" button in 3 dot menu */
    featureResendVendorInviteEmail: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MRA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Auto-resend invoices in error center when createInvoiceExport is called with resendErrorCenterInvoices */
    featureResendErrorCenterInvoices: (tempRoles) => tempRoles.IS_STARWOOD || tempRoles.IS_FCP,
    /** Shows trigger send to AP column in invoices table. Backend adjustments need to be made before turning this flag on (we need to add yardi creds for team). */
    featureUseYardiOnDemand: (tempRoles) => 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_TIDES || 
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_MORGAN_ADMIN || 
      tempRoles.IS_ACC ||
      tempRoles.IS_MAGNOLIA_ADMIN ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_ABACUS,
    
    /** When using YardiOnDemand, don't reset invoice export data on error (user workflow to resend from ErrorCenter only) */
    featureSkipResetInvoiceDataOnError: (tempRoles) => 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_ACC ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_ABACUS,

    /** Depends on featureUseYardiOnDemand, part of the same feature. */
    featureUseYardiExport: (tempRoles) => tempRoles.IS_CLEAR_HEIGHT,

    /** Backend flag for import invoices. Makes it so that we don't insert unapproved invoices through import */
    featureCreateApprovalPackagesForImportedInvoices: (tempRoles) => tempRoles.IS_FCP,

    /** [DEPRECATED] Tries to match up imported invoices to contracts */
    featureImportedInvoicesConnectToContracts: (tempRoles) => false,

    /** Just checks invoice number + building ID as a validation on import */
    featureEnforceInvoiceImportUniquenessOnBuildingLevel: (tempRoles) =>
      !tempRoles.IS_HARBOR && !tempRoles.IS_MAA,

    /** Allows teams to have a default manager set for DV. Need extra detail, used to be called useFeatureFCPProjectManager	 */
    featureUseDVProjectManager: (tempRoles) => tempRoles.IS_FCP || tempRoles.IS_SUMMIT || tempRoles.IS_TOURMALINE || tempRoles.IS_UPCAMPUS,

    /** Allows user to add invoice title through Banner upload modal. */
    featureUseInvoiceTitleAutoAdd: (tempRoles) => tempRoles.IS_FCP,

    /** Allows user to remind vendor to add invoices for a project. Option in contractroom page. */
    featureUseReminderToInvoice: (tempRoles) => tempRoles.IS_CLEAR_HEIGHT,

    /** Forces vendor to check a box and attach a document before adding invoice. */
    featureVendorInvoiceLienWaiver: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

    /** Allows vendor to add invoices to awarded contracts */
    featureUseVendorAddInvoice: (tempRoles) => !tempRoles.IS_LANTOWER && !tempRoles.IS_MORGAN,

    /** Validation to prevent from invoicing over 100% of any SOV. Validation also
     *  check the over invoicing agaist committed value if SOV is still within limit. 
     *  Validation applies to both manager profiles and vendor profiles. Validation 
     *  applied at invoice submission.
     */
    featureInvoicePreventMoreThanSovAtSubmit: (tempRoles) =>
      !tempRoles.IS_BANNER &&
      !tempRoles.IS_LIVCOR &&
      !tempRoles.IS_APRIL_HOUSING &&
      !tempRoles.IS_OLYMPUS_PROPERTY &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_KENNEDY_WILSON,
      
    /** Validation to prevent from invoicing over 100% of any SOV. Validation also
     *  check the over invoicing agaist committed value if SOV is still within limit.
     *  Validation applied at invoice approval */  
    featureInvoicePreventMoreThanSovAtApproval: (tempRoles) =>
      !tempRoles.IS_BANNER &&
      !tempRoles.IS_LIVCOR &&
      !tempRoles.IS_APRIL_HOUSING &&
      !tempRoles.IS_OLYMPUS_PROPERTY &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_KENNEDY_WILSON,


    /** Validation to prevent from over invoicing agaist committed value regardless of the SOV limit. 
     *  Validation applies manager profiles.
     *  Validation applied at invoice submission */  
    featureInvoicePreventMoreThanCommittedAtSubmit: (tempRoles) =>
      !tempRoles.IS_BANNER &&
      !tempRoles.IS_LIVCOR &&
      !tempRoles.IS_APRIL_HOUSING &&
      !tempRoles.IS_OLYMPUS_PROPERTY &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_KENNEDY_WILSON,

    /** Validation to prevent from over invoicing agaist committed value regardless of the SOV limit. 
     *  Validation applies manager profiles.
     *  Validation applied at invoice approval */  
      featureInvoicePreventMoreThanCommittedAtApproval: (tempRoles) =>
        !tempRoles.IS_BANNER &&
        !tempRoles.IS_LIVCOR &&
        !tempRoles.IS_APRIL_HOUSING &&
        !tempRoles.IS_OLYMPUS_PROPERTY &&
        !tempRoles.IS_RIDC &&
        !tempRoles.IS_ACC_DEV &&
        !tempRoles.IS_UPCAMPUS &&
        !tempRoles.IS_BMC_INVESTMENTS &&
        !tempRoles.IS_TOURMALINE &&
        !tempRoles.IS_ACC &&
        !tempRoles.IS_KENNEDY_WILSON,
      
    /** Validation to prevent from invoicing over 100% of any SOV. Validation 
     *  applies to both manager profiles and vendor profiles. Validation applied
     *  at invoice submission. Validation include Pending Invoice to calculate 
     *  over invoice amount. If @featureInvoicePreventMoreThanSovAtApproval is
     *  turned on then validation is extended to approval as well. 
     */
    featureIncludePendingInvoicesInOverinvoiceValidation: (tempRoles) =>
        tempRoles.IS_MORGAN || 
        tempRoles.IS_EOS ||
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION,

    /** Prevent Vendors from invoicing when a project is not ready to be invoiced */
    featureVendorInvoicePreventMoreThanPercent: (tempRoles) => 
      tempRoles.IS_MORGAN_BUDGETING,

    featureUseDVInvoiceReset: (tempRoles) => 
      tempRoles.IS_OSSO || 
      tempRoles.IS_DAYRISE || 
      IS_ENTERPRISE_MFA(tempRoles) ||
      tempRoles.IS_STARWOOD,

    /** Allow Editing Invoice Number and Title post Approval */
    featureAllowEditingInvoiceNumberAndTitle: (tempRoles) => 
      tempRoles.IS_BANNER || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_FCP ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_ACC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_ABACUS,

    /** Allows completing the invoice submission by skipping all other pages. i.e. Setup Package, Approvers, Review & Send  */
    featureUseSimpleInvoiceSubmissionScreen: (tempRoles) => 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_UPCAMPUS,
    
    featureSendMoveOutEmailOnUnitImport: (tempRoles) => tempRoles.IS_AIR,
    featureApprovalForMoveOutOnUnitImport: (tempRoles) => tempRoles.IS_AIR,

    /** Show pending invoice column on create invoice and invoice approval */
    featureUseInvoiceShowPending: (tempRoles) =>
      !tempRoles.IS_BANNER && 
      !tempRoles.IS_LIVCOR &&
      !tempRoles.IS_APRIL_HOUSING &&
      !tempRoles.IS_OLYMPUS_PROPERTY,
      
    /** Renames Invoice Start Date to Period */
    featureInvoiceStartDateIsPeriod: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,

    /** Depends on featureUseInvoiceStartEndDate
     * This flag can be used to hide the end date at following places:
     *   - Contract Room:
     *      - Add Invoice
     *      - Invoice Table
     *      - Approval Package
     *   - Dashboard Invoices Table
     */
    featureHideInvoiceEndDate: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,

    /** Enables selection of only the first day of the month in the date picker, disabling all other days */
    featureUseOnlyFirstDayOfMonth: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,

    /** Allow team to be exempt from invoice start date editing blocker */
    featureAllowAllTeamMembersEditStartDateInvoicesTable: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,
  });

  const _VALIDATIONS = typeMustExtend(mapType, {
    /** Validation check to make sure Summit has assigned Project Categories before running a draw */
    featureUseSummitCategories: (tempRoles) => tempRoles.IS_SUMMIT || tempRoles.IS_TITAN_CORP || tempRoles.IS_DEMO_ENV_CAPEX,

    featureValidationRequireSovUnit: (tempRoles) => false,

    /** Requires (forces) Vendor Code to submit Invoices */
    featureValidationRequireVendorCode: (tempRoles) => 
    tempRoles.IS_FCP ||
    tempRoles.IS_ACC_DEV ||
    tempRoles.IS_MRA ||
    tempRoles.IS_AIR, 

    /** Requires vendor code for invoice approvals */
    featureValidationRequireVendorCodeInvoiceApproval: (tempRoles) =>
    tempRoles.IS_FCP ||
    tempRoles.IS_ACC_DEV ||
    tempRoles.IS_MRA,

    /** Requires individual invoice date when adding an invoice  - relies on featureUseInvoiceDate */
    featureValidationRequireDate: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_BMC_INVESTMENTS,

    /** Requires invoice Period/Period Start date when adding an invoice - relies on featureUseInvoiceStartEndDate */
    featureValidationRequirePeriodStartDate: (tempRoles) => tempRoles.IS_ACC_DEV,

    /** Requires vendors to submit at least one document when adding an invoice */
    featureValidationRequireDocument: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_TIDES || 
      tempRoles.IS_EOS || 
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

    /** Requires email when adding a vendor */
    featureVendorModalRequireEmail: (tempRoles) => tempRoles.IS_MORGAN,

    /** Requires vendor code when adding a vendor. See featureUseVendorCode also */
    featureVendorModalRequireCode: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_KETTLER || 
      tempRoles.IS_ACC || 
      tempRoles.IS_MRA ||
      tempRoles.IS_AIR,

    /**
     * @param {_Roles} tempRoles Temp roles
     * @returns {InvoiceDedupFieldName[] | null} Array of fields used for invoice deduping or null for no deduping
     */
    featureInvoiceDedupFields: (/** @type {_Roles} */ tempRoles) => {
      if (
        tempRoles.IS_FCP ||
        tempRoles.IS_CLEAR_HEIGHT ||
        tempRoles.IS_INDUSTRIOUS ||
        tempRoles.IS_BMC_INVESTMENTS ||
        tempRoles.IS_EOS ||
        tempRoles.IS_TIDES ||
        tempRoles.IS_CARDINAL ||
        tempRoles.IS_CARDINAL_DEVELOPMENT ||
        tempRoles.IS_MAGNOLIA ||
        tempRoles.IS_DEMO_ENV_CAPEX ||
        tempRoles.IS_UPCAMPUS ||
        tempRoles.IS_MORGAN
      ) {
        return ['number', 'contractorID'];
      }
      return ['number', 'contractorID', 'total'];
    },

    /** Legacy inconsistent gating to invoice dedup */
    featureCheckForInvoiceDuplication: (tempRoles) => true,

    /** Prevents selecting/awarding a bid to a vendor with an expired compliance */
    featureContractsOnlyAllowYardiCompliantVendors: (tempRoles) => tempRoles.IS_29TH_STREET,

    /** Ignore for now - no clients use this - relies on featureUseCostItemGlCodes */
    featureValidationRequireContractGlCode: (tempRoles) => false, // temporarily disabling tempRoles.IS_INDUSTRIOUS,

    /** Ignore for now - no clients use this - relies on featureUseContractCurrency */
    featureValidationRequireContractCurrency: (tempRoles) => false, // temporarily disabling tempRoles.IS_INDUSTRIOUS,

    /** Requires GL code on SOV when approving bid,co, or invoice */
    featureValidationRequireSovGlCode: (tempRoles) =>
      tempRoles.IS_STARWOOD || 
      tempRoles.IS_MAGNOLIA || 
      tempRoles.IS_EOS || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Requires GL code on SOV when adding a co */
    featureUseSOVGLRequiredForChangeOrder: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_ACC_DEV,

    /** Makes it so that SOV GL code validation only turns on for a specific sub group (e.g. highmark) */
    featureValidationRequireSovGlCodeForSpecificSubGroup: (tempRoles) => tempRoles.IS_STARWOOD,

    /** Duplication protection for SOV names - no clients use this. */
    featureValidationSovUniqWithCurrent: (tempRoles) => false,

    /** When adding a property, makes sure property code is required */
    featureValidationRequirePropertyCode: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_EOS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MRA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_AIR,

    /** Prevents vendors from adding invoices if there's no invoice approval flow on the asset */
    featureValidationRequireApprovalFlowOnVendorAddInvoice: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_FCP ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Prevents managers from adding an invoice if there's no invoice approval flow on the asset */
    featureValidationRequireApprovalFlowOnManagerAddInvoice: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_FCP ||
      tempRoles.IS_ACC ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Makes sure there's a GL code on the cost item and Job code on the shell. Shell Tag must be named "Job Code" */
    featureContractRequirementsForYardi: (tempRoles) => tempRoles.IS_MORGAN,

    featureRequireVendorCodeOnContractApproval: (tempRoles) => tempRoles.IS_WATERTON || tempRoles.IS_AIR,

    featureValidationRequireSubGroup: (tempRoles) => tempRoles.IS_TOURMALINE,
    featureValidationRequireSubGroupExportConfig: (tempRoles) => tempRoles.IS_TOURMALINE,
  });

  const _DATA_VALIDATIONS = typeMustExtend(mapType, {
    /** Allow using a Data Validation flow when submiting invoices. Invoices must be checked and validated before
     * submitted to an approval flow (if exist).
     */
    useFeatureDataValidationInvoices: (tempRoles) =>
      tempRoles.IS_FCP ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_EOS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_GRIFFIN,

    /** Allows users to add data validation non-contract invoices to single sov Contracts */
    featureDataValidationNonContractShowSingleSovContracts: (tempRoles) =>
      tempRoles.IS_FCP || 
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_GRIFFIN,

    featureUseInvoiceAdminTag: (tempRoles) => 
      tempRoles.IS_OSSO || 
      tempRoles.IS_FCP || 
      tempRoles.IS_DAYRISE || 
      IS_ENTERPRISE_MFA(tempRoles) ||
      tempRoles.IS_STARWOOD,

    /** Prevents user to add and validate an invoice if any SOV has negaive balance, 
     *  also adds balance column and auto populates sov value in cost breakdown  */
    featureDVInvoicePreventMoreThanAwarded: (tempRoles) => 
      !tempRoles.IS_BANNER &&
      !tempRoles.IS_LIVCOR &&
      !tempRoles.IS_APRIL_HOUSING &&
      !tempRoles.IS_OLYMPUS_PROPERTY &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_KENNEDY_WILSON,

    /** Allows $0 DV Tasks like invoice */
    featureAllowZeroInvoiceDV: (tempRoles) =>
      tempRoles.IS_LIVCOR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_EOS,

    /**
     * The integration includes a hard total but it is up to the user approving to
     * click Add SOV and add correct number of line items to add up to that total.
     * Both "Integration Amount" and auto-totaled "Amount" will both be shown on DV Invoice approval page.
     */
    featureDvInvoiceShowTargetTotal: (tempRoles) => 
      tempRoles.IS_DAYRISE || 
      tempRoles.IS_FCP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_GRIFFIN,
  });

  const _VENDORS = typeMustExtend(mapType, {
    /** Allows a separate list of vendors per sub group. Important for anyone who uses operating partners. */
    featureUseVendorSubGroup: (tempRoles) =>
      tempRoles.IS_FCP ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_BMC_INVESTMENTS ||
      IS_ENTERPRISE_MFA(tempRoles) ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_OLYMPUS_PROPERTY,

    /** [Bespoke] This auto adds a dummy email to vendors when creating them */
    featureVendorEmailAutoAdd: (tempRoles) => tempRoles.IS_KETTLER,

    /** This allows banner admin to merge vendors */
    featureUseVendorAdminTools: (tempRoles) => true,

    /** Shows the manage vendor invites in contract room */
    featureUseManagerVendorInvites: (tempRoles) => !tempRoles.IS_HARBOR,

    /** Requires vendor code in certain screens such as DV tasks. See featureVendorModalRequireCode also */
    featureUseVendorCode: (tempRoles) => USE_VENDOR_CODES(tempRoles),

    /** Does Not Requires Vendor Code to validate a DV task, overrules featureUseVendorCode */
    featureValidationAllowEmptyVendorCode: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_UPCAMPUS,

    /** Only Admins can edit vendor codes*/
    featureAdminOnlyVendorCodes: (tempRoles) =>  tempRoles.IS_MORGAN,

    /** Shows vendor compliance column in vendors table */
    featureUseVendorsCompliantColumn: (tempRoles) =>
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_FCP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET,

    /** Allows vendors to see contract room documents post approval in their dashboard */
    featureVendorSeeContractDocs: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_MRA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

    featureVendorBidRequirementsSectionWithExplanation: (tempRoles) => true,
  });

  const _SETTINGS_GENERAL = typeMustExtend(mapType, {

    /** DateOptions object. Defaults to en-US locale and US ET timezone */
    featureShowDataUploader: (tempRoles) => tempRoles.IS_BANNER_EMAIL || tempRoles.IS_ACC_DEV_ADMIN  || tempRoles.IS_ACC_ADMIN,
    featureShowInvoiceIDAdvanced: (tempRoles) => tempRoles.IS_ACC_ADMIN || tempRoles.IS_ACC_DEV_ADMIN || tempRoles.IS_BANNER_EMAIL,
    featureShowContractsUploader: (tempRoles) => tempRoles.IS_ACC_ADMIN || tempRoles.IS_ACC_DEV_ADMIN || tempRoles.IS_BANNER_EMAIL,
    featureShowLTBImporterOption: (tempRoles) => tempRoles.IS_ACC_ADMIN || tempRoles.IS_BANNER_EMAIL,
    featureShowOtherDataOptions: (tempRoles) =>  tempRoles.IS_BANNER_EMAIL || tempRoles.IS_ACC_ADMIN,

    /** DateOptions object. Defaults to en-US locale and US ET timezone */
    featureDateOptions: (tempRoles) => DEFAULT_DATE_OPTIONS,

    /** [Bespoke] Allows uploading yardi invoices through excel format - manual */
    featureUseYardiUploader: (tempRoles) => tempRoles.IS_STONEWEG || tempRoles.IS_KETTLER,

    /** [Bespoke] DD Uploader made originally for industrious. */
    featureUseDDUploader: (tempRoles) => false,

    /** Turns on export configuration system for users, to send invoices upon approval or in a weekly csv. */
    featureUseExportConfigurations: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_FCP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION_ADMIN ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MRA ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** */
    featureExportYardiInvoices: (tempRoles) =>
      tempRoles.IS_STARWOOD || tempRoles.IS_FCP || tempRoles.IS_CLEAR_HEIGHT,

    /** Allows non-admins to see company settings. */
    featureUseCompanySettingsNonAdmin: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** [Deprecated] Old functionality for tourmaline */
    featureCanEditEmail: (tempRoles) => tempRoles.IS_BANNER_EMAIL,

    /** Only allows admins to edit approval flows. Typically this is prevented with the company settings blocker. */
    featureBlockAdminApprovalEdit: (tempRoles) => false,

    /** V1 of daily activities email. Not currently in use. */
    featureUseEmailDailyActivities: (tempRoles) => tempRoles.IS_STONEWEG || tempRoles.IS_BANNER,

    /** Daily log of every financial change made in Banner. Only stable for MFA Enterprise accounts. */
    featureUseAuditReports: (tempRoles) => {
      if (tempRoles.IS_BANNER || tempRoles.IS_LIVCOR || tempRoles.IS_APRIL_HOUSING) return ['financialsSum'];
      if (tempRoles.IS_HARBOR) return ['glCodeFinancialsSum'];
      if (tempRoles.IS_MORGAN || tempRoles.IS_WATERTON) return ['financialsSumUsingGetShells'];
      return false;
    },

    /** Show Budget Under Contract Tab on Tasks */
    featureContractTaskTabImprovements: (tempRoles) => true,

    /** Opens a task in the same tab instead of a new tab */
    featureUseOpenTasksInSameTab: (tempRoles) => tempRoles.IS_STARWOOD || tempRoles.IS_EOS,

    /** Shows property code in account permissions screen. */
    featureShowPropertyCodeInAccountSettings: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC ||
      tempRoles.IS_APRIL_HOUSING ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_FCP ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Allows users to see job audits tab in settings. */
    featureUseSettingsJobAudits: (tempRoles) => true,

    /** Require field Square Feet in add/edit building modal */
    featurePropertyModalRequireFieldSquareFeet: (tempRoles) => !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT && !tempRoles.IS_UPCAMPUS && !tempRoles.IS_INDUSTRIOUS,

    featurePlaidBalances: (tempRoles) => tempRoles.IS_TIDES,

    featureHideTasks: (tempRoles) => tempRoles.IS_LIVCOR_ROI,
    featureHideBannerUpload: (tempRoles) => tempRoles.IS_LIVCOR_ROI || tempRoles.IS_AIR || tempRoles.IS_OSSO || tempRoles.IS_WILLOW_BRIDGE,
    
    /** Hide the Errors Table (option) from the left Nav */
    featureHideErrors: (tempRoles) => 
      tempRoles.IS_LIVCOR_ROI || 
      tempRoles.IS_AIR || 
      tempRoles.IS_WILLOW_BRIDGE || 
      tempRoles.IS_APRIL_HOUSING || 
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN,

    featureUseCustomTasksRichDescription: (tempRoles) => tempRoles.IS_MORGAN,
      
    featureHideVendors: (tempRoles) => tempRoles.IS_LIVCOR_ROI,

    /** Disable notifying Submitter of invoice integration errors. User must setup fron 
     *  Notification Settings to notify anyone. 
     */
    featureDisableDefaultErrorNotification: (tempRoles) => tempRoles.IS_MRA || tempRoles.IS_EOS || tempRoles.IS_FCP,
  });

  const _EXPORT_CONFIGURATION = typeMustExtend(mapType, {
    /** Allows export configurations at asset level. */
    featureUseExportConfigurationsWithBuildings: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN,

    /** Allows export configurations at sub group level. */
    featureUseExportConfigurationsWithSubGroups: (tempRoles) =>
      !tempRoles.IS_CLEAR_HEIGHT &&
      !tempRoles.IS_MAGNOLIA &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_CONVENE &&
      !tempRoles.IS_DEMO_ENV_GU && // TODO: rewrite it as negative of featureUseExportConfigurationsWithBuildings
      !tempRoles.IS_FLYNN &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_GRIFFIN,

      featureUseRemoveAttachmentFromContractsAPI: (tempRoles) =>
        tempRoles.IS_AIR,

      featureUseRemoveNonContractsFromContractsAPI: (tempRoles) =>
        tempRoles.IS_AIR,
  });

  const _CONTRACT_ROOM = typeMustExtend(mapType, {
    /** [Bespoke] Contract Room flag (Yes/No). Shows up in Approval Page Summary */
    featureUseLenderApproved: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** [Bespoke] Shows Total Contract Progress in the Schedule of Value section in Contract Room */
    featureUseContractRoomTotalProgress: (tempRoles) => 
      tempRoles.IS_TOURMALINE || 
      tempRoles.IS_RADCO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_LANTOWER,

    /** Provides Start/End date fields when Adding a Contract. Field currently used for DocuSign Integration */
    featureUseContractStartEndDate: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_AIR ||
      tempRoles.IS_STONEWEG,

    /** Allow Submitting Contracts with $0 */
    featureUseAllow0Contracts: (tempRoles) =>
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAA ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_EOS,


    /** Provide a Description field when Adding a Contract in Contract Room */
    featureUseContractDescription: (tempRoles) =>
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Provide a Rich Text Description field when Adding a Contract in Contract Room */
    featureUseRichTextContractDescription: (tempRoles) => 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Allow Change Orders. Turned on for All  */
    featureUseContractChangeOrders: (tempRoles) => true,

    /** Provide ability to save a Change Order as draft. Financials will not be impacted.
     * Any user have access to Contract can make changes
     */
    featureUseContractDraftChangeOrders: (tempRoles) =>
      tempRoles.IS_BANNER || 
      tempRoles.IS_EOS || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MRA ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_GRIFFIN,

      featureUseDraftChangeOrdersSOVTransfer: (tempRoles) => tempRoles.IS_ACC_DEV,

    /** Provide ability to save a Change Order as draft. Financials will not be impacted.
     * Any user have access to Contract can make changes
     */
    featureUseContractDraftInvoices: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_EOS ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_GRIFFIN,

      /** Provide ability to duplicate a Change Order as draft. Financials will not be impacted.
     * Any user have access to Contract can make changes to that draft
     * Relies on featureUseContractDraftChangeOrders
     */
      featureUseDuplicateChangeOrders: (tempRoles) => tempRoles.IS_TIDES  ||
      tempRoles.IS_DEMO_ENV_CAPEX,

    /** Requires SOV level GL Codes. Forces when adding contract. Relies on featureUseSOVGLCodes */
    featureUseSOVGLCodesRequired: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_EOS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Automatically applies GL code setup at Shell level to SOVs. User can overwrite. Relies on featureUseSOVGLCodes */
    featureUseSOVGLCodesDefaultFromShell: (tempRoles) => 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseRenderSovTagAsColumn: (tempRoles) => true,

    /** Overview section of tabs invoices and change orders */
    featureUseOverviewRemainingTileCalcCommittedMinusApproved: (tempRoles) =>
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_EOS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_TIDES,

    /** Team Wide prefix to differentiate from userFeature deleteInvoiceFromContractRoom * */
    featureTeamWideDeleteInvoiceFromContractRoom: (tempRoles) =>
      tempRoles.IS_BANNER_EMAIL || tempRoles.IS_ACC_DEV_ADMIN || tempRoles.IS_ACC_ADMIN ||
      !(tempRoles.IS_EOS || tempRoles.IS_ACC_DEV || tempRoles.IS_ACC),
  

    featureUseChangeOrderPoIncrementNumber: (tempRoles) => 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_WATERTON ||
      tempRoles.IS_AIR,

    featureUseContractRoomSOVDate: (tempRoles) => tempRoles.IS_TIDES  ||
    tempRoles.IS_DEMO_ENV_CAPEX,

    featureUseReAssignInvoiceAcrossShells,

    /** Provide Ability to Reassign non conctract invoice within same Shell */
    featureUseReAssignInvoiceWithinShell: (tempRoles) => 
      featureUseReAssignInvoiceAcrossShells(tempRoles) ||
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_STARWOOD,

      /** Provide Ability to Reassign non conctract invoice within same Shell which is present in a draw report. Depends on featureUseReAssignInvoiceWithinShell and  featureUseReAssignInvoiceAcrossShells should be turned off for that team */
    featureAllowReassignDrawReportInvoice: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_ACC_DEV,

    featureUseDocumentList: (tempRoles) => {
      if(tempRoles.IS_FCP){
        return [
          'Contractor proposal/scope of work (REQUIRED)',
          'Governing plans and specifications (if applicable)',
          'Contract schedule (if applicable)',
          'Contractor COI (REQUIRED)',
          'Property insurance addendum (REQUIRED)'
        ];
      }
      return null;
    },
  });

  const _BID_ROOM = typeMustExtend(mapType, {
    /** Cancel Option to recall a bid. Cannot be cancelled by Vendor or once submitted as a
     * contract (separate Cancel flow for that). Any Admin user who have access to the project
     * can cancel */
    featureUseCancelBidding: (tempRoles) => true,

    /** Allow submit RFIs and Bidding Process */
    featureUseBidSetupAndRFI: (tempRoles) => true,

    /** Gate Editing Bid Form templates only to Admin users. Others are allowed to make a copy of
     * Master Templates but not edit the Master template.
     */
    featureBidFormTemplatesAdminOnlyEditing: (tempRoles) =>
      tempRoles.IS_MORGAN || tempRoles.IS_MORGAN_BUDGETING || tempRoles.IS_LANTOWER,


    featureUseUnitPriceCalculator: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MORGAN,

    /**
     * Unit price section in Bid Form with Qty, Unit Cost, Price broken down.
     * Probably this is not compatible with `featureCurrencySystem`.
     * Would need to be tested through the Vendor Portal "Prepare my bid" flow.
     */
    featureUseUnitPrices: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

      /** Updates bid requirement section of the bidform to allow owner to separately handle expalnation and checkboxes and and have a label with checkboxes */
      featureUseBidFormCheckboxLabels: (tempRoles) => tempRoles.IS_WATERTON,

    /** Provide ability to leave notes (both Owner & Vendor) for each Unit price line item */
    featureUseUnitPricesNotes: (tempRoles) => tempRoles.IS_RUDIN || tempRoles.IS_29TH_STREET,

    featureDontShowShareContractModal: (tempRoles) => tempRoles.IS_MRA,

    /** Provide Rich Text editing functionality in the Bid Room Project Info section */
    featureUseRichText: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_STONEWEG,

    featureShowAdditionalVendorInfo: (tempRoles) => 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_WATERTON ||
      tempRoles.IS_FCP ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_APRIL_HOUSING ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_MAA ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_STONEWEG,

    /** Requires Target Start date to submit */
    featureProjectInfoRequireFieldTargetStartDate: (tempRoles) => !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT && !tempRoles.IS_UPCAMPUS ,
    /** Max contract/SOV line item count */
    featureBidsMaxLineItemCount: (tempRoles) => {
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
          tempRoles.IS_MILEWAY_DEMO || 
          tempRoles.IS_DEMO_ENV_GL || 
          tempRoles.IS_AIR ||
          tempRoles.IS_STARWOOD) 
          return 10;
      if (tempRoles.IS_WATERTON) return 25;
      return 1000;
    },
    /** Max contract requirements count sent to Docusign */
    featureBidsMaxRequirementDocusignCount: (tempRoles) => {
      if (tempRoles.IS_WATERTON) return 11;
      return 1000;
    },

    /** Ability to add a quick bid from contract room without having to go through RFP process.
     *  Different from Adding contract. This allows to add multiple bids with documents.
     */
    featureAddQuickBid: (tempRoles) =>
      tempRoles.IS_OSSO ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FCP ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_WILLOW_BRIDGE,
  });

  const _CM_FEES = typeMustExtend(mapType, {
    /** Provides a CM Fee Button to create CM Fee based on which level Controlled by other flags.
     * Needs to have either featureUseShellForCmFeeInvoices or featureUseProjectForCmFeeInvoices turned on
     * Make sure team.cmConfig is filled as some PDF fields are pulled from there */
    featureUseCreateCmFeeInvoiceButton: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_EOS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_GRIFFIN,

    /** Shell Level CM Fee Functionality. Allowed to run CM Fee across shells for a building.
     * Requires specific Shell with CM Fee type. Needs featureUseProjectCategories turned on
     * to be able to choose categories for shell */
    featureUseShellForCmFeeInvoices: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

    /** Project Level CM Fee Functionality. Allowed to run CM Fee across cost items for a shell.
     * Requires specific cost item with CM Fee GL code setup. */
    featureUseProjectForCmFeeInvoices: (tempRoles) =>
      tempRoles.IS_MRA || 
      tempRoles.IS_TIDES || 
      tempRoles.IS_EOS || 
      tempRoles.IS_TITAN_CORP || 
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE || 
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_GRIFFIN,

    // relies on featureUseProjectForCmFeeInvoices
    featureUseProjectForCmFeeInvoicesWithFeeEligible: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,

    // relies on featureUseProjectForCmFeeInvoices
    featureUseProjectForCmFeeInvoicesAutoIncrementNumber: (tempRoles) => tempRoles.IS_EOS,

    /** Setup default CM Fee percent for all Projects. User can overwrite default value in UI */
    featureProjectedCmFeePercent: (tempRoles) =>
      // prettier-ignore
      (tempRoles.IS_MRA || tempRoles.IS_EOS || tempRoles.IS_DAYRISE || tempRoles.IS_FLYNN) ? 4 : 0,

    /** Update Shell Page Cost Tracker Invoice Table name appropriately */
    featureUseProjectShellInvoiceTable: (tempRoles) =>
      tempRoles.IS_MRA ||
      tempRoles.IS_MAA ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_EOS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_SUMMIT || 
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_AIRBNB,

    /** Rename the CM Fee to DM Fee in multiple locations */
    featureUseProjectShellCmFeeInvoicesRenameToDM: (tempRoles) => 
    tempRoles.IS_MRA || 
    tempRoles.IS_EOS ||
    tempRoles.IS_WATERTON ||
    tempRoles.IS_DEMO_ENV_GU,

    featureUseCmFeeInvoicePdfBillToTagNames: (tempRoles) => {
      if (tempRoles.IS_MRA || tempRoles.IS_TITAN_CORP || tempRoles.IS_DEMO_ENV_CAPEX) {
        return ['Client Name', 'Client Address 1', 'Client Address 2', 'Client ATTN'];
      }

      if (tempRoles.IS_EOS) {
        return ['Legal Name', 'Legal Address 1', 'Legal Address 2', 'Client ATTN'];
      }

      if (tempRoles.IS_TOURMALINE) {
        return ['Address 1', 'Address 2', 'Address 3', 'Address 4'];
      }

      return [];
    },
    // used to align company logo to the left, display invoice detail table before activity table and hide total line for invoice detail table
    featureUseCmFeeInvoicePdfLogoLeftAlign: (tempRoles) => tempRoles.IS_EOS,
    featureUseCmFeeInvoicePdfTerms: (tempRoles) => !tempRoles.IS_EOS,
    featureUseCmFeeInvoicePdfCustomerID: (tempRoles) => tempRoles.IS_EOS,
    featureUseCmFeeInvoicePdfActivitySection: (tempRoles) => true,
    featureUseCmFeeInvoicePdfInvoiceDetailSection: (tempRoles) => true,
    featureUseCmFeeInvoicePdfPaymentInfoSection: (tempRoles) => tempRoles.IS_EOS,
    featureUseCmFeeInvoicePdfFooterSection: (tempRoles) => true,
  });

  const _CHANGE_ORDERS = typeMustExtend(mapType, {
    /** Allow $0 Change Orders */
    featureAllowChangeOrderZeroTotalAmount: (tempRoles) =>
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAA ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_EOS,

    /** Allow users to submit Change Order to Existing SOVs in the original contract as oppose
     * to creatiung Change orders as new SOVs */
    featureUseAllowChangeOrderExistingSOV: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MRA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_EOS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,


    featureChangeOrderMaxLineItemCount: (tempRoles) => {
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_DEMO_ENV_GL) return 10;
      return 1000;
    },

    featureChangeOrderSOVRequiredTags: (tempRoles) =>{
      if(tempRoles.IS_ACC_DEV){
        return ['Budget SOV', 'BMN', 'Change of Cause'];
      }
      return [];
    },

    featureChangeOrderSortByNumber: (tempRoles) => tempRoles.IS_ACC_DEV,
  });

  const _CASHFLOW = typeMustExtend(mapType, {
    // Feature dependencies defined in parent scope
    featureUseMonthlyCashflows,
    featureUseCostItemBasedCashflows,
    featureUseShellBasedCashflows,
    featureUseCostItemBasedCashflowCustomReports,

    featureUseShellBasedCashflowsWithDateTags: (tempRoles) =>
      featureUseShellBasedCashflows(tempRoles) && 
      tempRoles.IS_OSSO,

    /** [GL-Alias] Based Cashflow. Cannot have any other type of Cashflow with it */
    featureUseGlCodeBasedCashflows: (tempRoles) => 
      !featureUseShellBasedCashflows(tempRoles) && 
      !featureUseCostItemBasedCashflows(tempRoles) &&
      featureUseGLCodeBudgets(tempRoles),

    featureLastMonthCashflowMaxBudget: (tempRoles) => {
      if (tempRoles.IS_LIVCOR) return 5e4;
      return -1;
    },

    /** Prevents user to insert duplicate values for the tags mentioned for the certain team */
    featurePreventDuplicateCustomTaskTags: (tempRoles) => {
      if(tempRoles.IS_MORGAN) return ['Invoice Number']
      return []
    },

    /** Set offset from current month to adjust when cashflow projections start */
    featureCashflowProjectionMonthsOffset: (tempRoles) => {
      // if (tempRoles.IS_ACC) return 1;
      return 0;
    },

    /** Add Cashflow Projection budget to Carryover projects */
    featureAddToCarryoverBudgets: (tempRoles) => false,

    /** For cashflow projection, use Committed instead of max(Budget, Committed) when Committed > 0 */
    featureUseBudgetCostOnlyIfCommittedIsZero: (tempRoles) => tempRoles.IS_MORGAN,

    featureDontUseCashflowDashboardChildren: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_KPR ||
      featureUseShellBasedCashflows(tempRoles),

    featureDontUseCashflowShellChildren: (tempRoles) =>
      tempRoles.IS_TOURMALINE || tempRoles.IS_RIDC || featureUseShellBasedCashflows(tempRoles),

    featureUseCashflowInvoiceDateType: (tempRoles) => tempRoles.IS_TOURMALINE,

    featureUseShellBasedCashFlowCustomReports: (tempRoles) =>
      featureUseMonthlyCashflows(tempRoles) && !featureUseCostItemBasedCashflowCustomReports(tempRoles),

    featureRecalculateCashflowsOnLoad: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_MRA ||
      tempRoles.IS_ACC ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_KPR,

    featureUseCashFlowRules: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ABACUS,

    featureUseRemainingCashflowsColumn: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,

    featureUseCashFlowError: (tempRoles) =>
      tempRoles.IS_TOURMALINE || featureUseShellBasedCashflows(tempRoles),  

    featureUseContractCashflows: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ABACUS,

    featureUseInvoicesPaidToDateForRuleBasedCashflows: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,

    featureUseProjectForecastForRuleBasedCashflows: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,
    featureUseCustomReportsModalForCashflowIssues: (tempRoles) => tempRoles.IS_TOURMALINE,
    featureUseWarningOnCashFlowsOutOfRange: (tempRoles) => tempRoles.IS_TOURMALINE || tempRoles.IS_BANNER, // add Banner for testing

    featureUseProjectCategories: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_BMC_INVESTMENTS,
    /** ***************  ************************ */
  });

  const _DASHBOARD_TOP_TILES = typeMustExtend(mapType, {
    /** Show Budget vs Forecast Chart on the Dashboard Page */
    featureUseBudgetvsForecast: (tempRoles) =>
      !tempRoles.IS_METROVATION &&
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show Project By Stage chart (middle section) on Dashboard Page */
    featureUseProjectsBy: (tempRoles) =>
      !tempRoles.IS_METROVATION &&
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_INDUSTRIOUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show Recent Activity Feed on Dashboard Page */
    featureUseRecentActivityFeed: (tempRoles) =>
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** [Bespoke] Show Asset Information on the Chart section of the Dashboard page
     * one Asset selected. If multiple selected a message shown to select only one asset */
    featureUseAssetInformation: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    /** Show Project By Status chart (middle bottom section) on Dashboard Page */
    featureUseProjectsByStatus: (tempRoles) => !tempRoles.IS_STONEWEG && !tempRoles.IS_INDUSTRIOUS && !tempRoles.IS_MORGAN_CONTRACTS,

    /** [Bespoke] Show Top 10 Budgets on the area where Project By Status chart was. Not used anymore */
    featureUseTop10Budgets: (tempRoles) => tempRoles.IS_STONEWEG && !tempRoles.IS_INDUSTRIOUS,

    /** A toggle to Hide the Top section in Dashboard page */
    featureUseHideTopSection: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO && !tempRoles.IS_MORGAN_CONTRACTS,

    /** A toggle to show or hide archive projects in Dashboard page */
    featureUseArchiveProjects: (tempRoles) => true,
  });

  const _DASHBOARD_TABS = typeMustExtend(mapType, {
    /** Base Banner Product. Basically turns on the main dashboard page. Need to be turned on
     * for all Customers not using Enterprise MFA */
    featureUseCapex: (tempRoles) =>
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_MAA ||
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_JRK ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MRA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_FCP ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_STARLIGHT ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Allows users to drag and drop columns to order them to their preference.
     * State is preserved per user. Relies on featureUseCapex to be on */
    featureUseDashboardCostTrackerColumnOrdering: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ACC ||
      tempRoles.IS_MRA ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_AIR ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_MAA ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_FCP ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

      featureUseDashboardCostTrackerWholeTeamColumnOrdering: (tempRoles) => tempRoles.IS_STARWOOD,

    // relies on featureUseCapex to be on
    featureDashboardCostsSovMenu: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_DEMO_ENV_CAPEX,

    /** Provides ability to make bulk edits in the Dashboard without having to reload every time
     * after making a change. Relies on featureUseCapex to be on */
    featureUseMassEdit: (tempRoles) => true,

    /** Freeze the header row of the Dashboard Table columns when scrolling  */
    featureDashboardColumnFreeze: (tempRoles) => true,

    /** Provide flattened Shells on a separate column with Property level nesting. Requires featureDashboardFlattenToProjects */
    featureDashboardColumnOverrideProperty: (tempRoles) => 
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN,

    /** Flatten the Dashboard to Shell level. Each row will be a unique shell No Property level nesting */
    featureDashboardFlattenToProjects: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_MAA ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN,

    /** Legacy Dashboard Page where projects are listed in a list format instead of a dashboard. Not used anymore. */
    featureUseLongProjectTable: (tempRoles) => tempRoles.IS_SUMMIT || tempRoles.IS_BANNER,

    /** Main Property filter in the Dahsboard page. Must turn on for all non Enterprise MFA clients */
    featureUseDashboardPropertyFilter: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_JRK ||
      tempRoles.IS_FCP ||
      tempRoles.IS_MAA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Aggregate all invoices across all properties and provide in a dashboard table */
    featureUseInvoiceTable: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_MAA ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_ACC ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN,

    /** Aggregate all active bids across all properties and provide in a dashboard table */
    featureDashboardBidTable: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MRA ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_WILLOW_BRIDGE,

    /** Aggregate all Change Orders (CO) across all properties and provide in a dashboard table */
    featureDashboardCoTable: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MAA ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_EOS || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_WILLOW_BRIDGE,

      featureDashboardObjectLookupTable: (tempRoles) => tempRoles.IS_MORGAN,

    /** Pivot table for the Contracts across the entire portfolio */
    featureDashboardContractsTable: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MORGAN_CONTRACTS || 
      tempRoles.IS_MILEWAY_DEMO ||  
      tempRoles.IS_AIR ||
      tempRoles.IS_MAA ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KENNEDY_WILSON || // Added Kennedy Wilson to the condition
      tempRoles.IS_TIDES || // Added Team Tides to the condition
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_EOS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_WILLOW_BRIDGE,

    featureUseGLCodeTag: (tempRoles) => tempRoles.IS_BANNER,

    /** Provide ability to create Custom Tables in the Main Dashboard. Tables are driven by Custom Tags */
    featureUseCustomTables: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_STONEWEG,

    /** Provide ability to create Budget Snapshots. Requires shells to have children (featureCreateProjectsForShell) */
    featureUseSnapshots: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_JRK ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_DEMO_ENV_GU,

    /** Automatically create a Budget snapshot upon approval. Depends on featureUseSnapshots being turned on */
    featureCreateSnapshotsOnApproval: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** Provide a Properties Tab in Dashboard Cost Table. Properties Tab show all Property information 
     * including Tags in a table format */
    featureUsePropertiesTab: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,

    /** 
     * Loads Dashboard without financials for quicker loads.
     * Adding a new team here may require other code changes to hide UI that uses financials
     * */
    featureUseNoFinancialsOnDashboard: (tempRoles) => tempRoles.IS_HARBOR,

    // depends on featureUsePropertiesTab
    featureUseAddressColumn: (tempRoles) => tempRoles.IS_HARBOR,

    /** New Budget Transfer Form. Instead of making budget revisions for each line item user can make either
     * funding adjustment or transfer budget from one cost item to another
     */
    featureUseBudgetTransfersFromBudgetHistory: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIR ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE,

     /** [LONG TERM BUDGET MODULE] */
    featureUseLongTermBudgetEditing: (tempRoles) =>
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_BANNER_DEMO_ENV_1 ||  
      tempRoles.IS_KPR || 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_ACC  || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_AIR ||
      tempRoles.IS_DAYRISE,

    featureUseLongTermBudgetTransfer: (tempRoles) => tempRoles.IS_MORGAN || tempRoles.IS_DAYRISE,
    /** [LONG TERM BUDGET MODULE] */
    featureUseLongTermBudgetView: (tempRoles) => 
      tempRoles.IS_BANNER || 
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_ACC  || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_AIR || 
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE,

    /** Enable Long Term Budgets at shell level instead of cost item level. Needs featureUseLongTermBudgetView  */
    featureUseLongTermBudgetShellLevel: (tempRoles) => tempRoles.IS_AIR || tempRoles.IS_ACC,

    featureUseLongTermBudgetCostItemLevelSourceTag: (tempRoles) => tempRoles.IS_MORGAN || tempRoles.IS_MORGAN_BUDGETING,
     
    /** [LONG TERM BUDGET MODULE] */
    featureUseTenYearSelector: (tempRoles) => 
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_AIR || 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_ACC || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE,
    
    featureUseNextYearSelector: (tempRoles) => 
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ACC ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_TIDES,
    
    /** [LONG TERM BUDGET MODULE] */
    featureUseLongTermAudits: (tempRoles) => 
      tempRoles.IS_BANNER || 
      tempRoles.IS_AIR || 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_ACC || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE,

     /** [LONG TERM BUDGET MODULE] */
    featureDisableLTBEditMenu: (tempRoles) =>  tempRoles.IS_ACC && !tempRoles.IS_BANNER_EMAIL,

      /** [LONG TERM BUDGET MODULE] */
    featureDisableLTBRevisions: (tempRoles) =>  false,

    featureUseQuantityPrice: (tempRoles) => tempRoles.IS_MORGAN_BUDGETING || tempRoles.IS_MORGAN || tempRoles.IS_DEMO_ENV_CAPEX,

    /** Provide the Comments Column and commenting functionality at Cost item level */
    featureUseCommentsOnCostItems: (tempRoles) =>
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_ACC || 
      tempRoles.IS_WATERTON || 
      tempRoles.IS_RADCO || 
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_29TH_STREET,

    featureUseContingency: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** Provide a toggle in the dashboard table to see $0 budgets */
    featureUseShowUnbudgetedProjects: (tempRoles) =>
      tempRoles.IS_FCP || tempRoles.IS_KETTLER,


    featureFilterShellsBasedOnLongTermScenario: (tempRoles) => tempRoles.IS_BANNER,

    /** Break the Budget Column to Original, Revised, and Transfer. Need to turn off
     *  featureUseBudgetColumn & featureUseProjectShellColumnBudget to avoid showing 
     *  multiple budget columns
     */
    featureUseOriginalBudgetColumns: (tempRoles) =>
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIR ||
      tempRoles.IS_EOS ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG,

    featureLegacyOriginalGlCodeBudgets: (tempRoles) =>  tempRoles.IS_HARBOR,

    featureUseShowSOVItems: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Makes the Properties Tab the Default tab in the Cost table when page is loaded
     *  Relies on featureUsePropertiesTab
     */
    featureUsePropertiesTabAsDefault: (tempRoles) =>
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DEMO_ENV_GL,
  });

  const _DASHBOARD_ADD_PROJ_SHELL_MODAL = typeMustExtend(mapType, {
    featureUseGLCodesDropdown: (tempRoles) => !tempRoles.IS_FCP && !tempRoles.IS_SUMMIT,

    /** Standard TimeZone field in Add project Modal */
    featureUseAddProjectTimeZone: (tempRoles) =>
      !(
        tempRoles.IS_FCP ||
        tempRoles.IS_STARWOOD ||
        tempRoles.IS_RIDC ||
        tempRoles.IS_KENNEDY_WILSON ||
        tempRoles.IS_SUMMIT ||
        tempRoles.IS_TITAN_CORP ||
        tempRoles.IS_KETTLER ||
        tempRoles.IS_DAYRISE ||
        tempRoles.IS_ACC ||
        tempRoles.IS_FLYNN ||
        tempRoles.IS_CONVENE ||
        tempRoles.IS_AIRBNB ||
        tempRoles.IS_MRA ||
        tempRoles.IS_RADCO ||
        tempRoles.IS_BMC_INVESTMENTS ||
        tempRoles.IS_OSSO ||
        tempRoles.IS_TIDES ||
        tempRoles.IS_29TH_STREET ||
        tempRoles.IS_LANTOWER ||
        tempRoles.IS_UPCAMPUS ||
        tempRoles.IS_GRIFFIN ||
        tempRoles.IS_STONEWEG
      ),

    /** Standard Fiscal Year selector (dropdown field) in Add Project modal */
    featureUseAddProjectFiscalYear: (tempRoles) => 
      !(
        tempRoles.IS_TOURMALINE || 
        tempRoles.IS_STARWOOD || 
        tempRoles.IS_TITAN_CORP || 
        tempRoles.IS_CLEAR_HEIGHT ||
        tempRoles.IS_29TH_STREET ||
        tempRoles.IS_LANTOWER ||
        tempRoles.IS_UPCAMPUS ||
        tempRoles.IS_GRIFFIN ||
        tempRoles.IS_DAYRISE
      ),

    /** Standard Field for Selecting Project type. Default Types are pre loaded at account creation.
     * User have the ability to create additional types. Used to drive approval flows.
     */
    featureUseAddProjectType: (tempRoles) => !tempRoles.IS_STARWOOD,

    /** Standard field to provide Size of the project in Sqft. Value use to drive some finanical
     * calculations per sqft
     */
    featureUseAddProjectSize: (tempRoles) => 
      !tempRoles.IS_STARWOOD && 
      !tempRoles.IS_OSSO && 
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_FCP &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_FLYNN &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_LANTOWER,

    /** Provide a GL Code field in Add Project Modal */
    featureUseAddProjectGLCode: (tempRoles) =>
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_MAGNOLIA &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_INDUSTRIOUS &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_WATERTON &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_SUMMIT &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_CLEAR_HEIGHT &&
      !tempRoles.IS_FLYNN &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_MRA &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_GRIFFIN,

    useFeatureGLCodeAsShellName: (tempRoles) => tempRoles.IS_FCP,

    // Splits GL Code with "GLCODE:GL_NAME" format
    useFeatureSplitGLCode: (tempRoles) => tempRoles.IS_KETTLER,

    /** By Default sets all project shells created to "Budgeted" stage (modifies shell.stage) */
    featureUseDefaultProjectStageBudgeted: (tempRoles) => tempRoles.IS_KETTLER,
  });

  const _DASHBOARD_COST_COLUMNS = typeMustExtend(mapType, {
    featureUseBuildingsColumn: (tempRoles) => true,
    featureUseBuildingsRenameToProperty: (tempRoles) => tempRoles.IS_ACC,

    /** Show the Single Budget column in the Dashboard. Not compatible with Original/Revised Budget columns */
    featureUseBudgetColumn: (tempRoles) =>
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_AIR &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT  &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_STONEWEG,

      /** Shows Project Shell Description as a Column in Dashboard cost table */
      featureUseDashboardColumnShellDescription: (tempRoles) => 
        tempRoles.IS_OSSO || 
        tempRoles.IS_STARWOOD || 
        tempRoles.IS_TIDES ||
        tempRoles.IS_DEMO_ENV_CAPEX ||
        tempRoles.IS_FCP ||
        tempRoles.IS_RADCO ||
        tempRoles.IS_GRIFFIN ||
        tempRoles.IS_LANTOWER,

      featureDisableDashboardColumnShellDescriptionEdit: (tempRoles) => tempRoles.IS_STARWOOD && !tempRoles.IS_STARWOOD_ADMIN,

    /** [Bespoke] Custom Column showing Total Orders placed.
     * Total Orders Placed = Approved Bids + Approved CO + Reimbursables
     */
    featureUseTotalOrdersPlacedColumn: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** Show the Forecast column in the Dashboard Cost Table */
    featureUseForecastColumn: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Rename the Forcast column name to Adjusted Contract */
    featureRenameForecastToAdjustedContract: (tempRoles) => tempRoles.IS_PRG_REAL_ESTATE,

    /** [Bespoke] Use the Forecast column for custom formula.
     * Forecast = Committed + Balance + Pending CO
     */
    featureUseForecastColumnOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** [Bespoke] Custom column for Forecast divided by RSF (Rentable SQFT) forecastOverRSF
     * value is calculated based on the RSF tag present at asset or shell level */
    featureUseForecastOverRSFColumn: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** [Bespoke] Custom column for Forecast divided by PSF (SQFT) forecastOverPSF
     * value is calculated based on the PSF tag present at asset or shell level */
    featureUseForecastOverPSFColumn: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /**  Long Term Budget Remaining (Forecast)column calculated as Long Term Budget - Forecast */
    featureUseLongTermRemaining: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_CLEAR_HEIGHT &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_FLYNN &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_29TH_STREET,

    /** This was a requirement from MAA */
    featureUseDashboardColumnContractRemaining: (tempRoles) => tempRoles.IS_MAA,

    /** Show Committed Column in the Dashboard Cost Table */
    featureUseCommittedColumn: (tempRoles) =>
      !tempRoles.IS_GARDINER_AND_THEOBALD && !tempRoles.IS_PRG_REAL_ESTATE && !tempRoles.IS_RADCO,

    /** Show Approved Bids Column in Dashboard Cost Table */
    featureUseApprovedBidsColumn: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_OSSO,

    /** [Bespoke] Same as Approved Bids Column. Renamed to Orders Placed */
    featureUseOrdersPlacedColumn: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** [Bespoke] Same as Approved Bids column. Renamed to Contract Amount */
    featureRenameApprovedBidsToContractAmount: (tempRoles) =>
      tempRoles.IS_WATERTON || 
      tempRoles.IS_PRG_REAL_ESTATE || 
      tempRoles.IS_FCP || 
      tempRoles.IS_EOS || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_KETTLER ||
      tempRoles.IS_AIRBNB,

    /** Show the Pending CO column in the Dashboard Cost table */
    featureUsePendingCOsColumn: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_PRG_REAL_ESTATE &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show the Approved CO column in the Dashboard Cost table */
    featureUseApprovedCOsColumn: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show the Holds column in the Dashboard Cost table */
    featureUseHoldsColumn: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Support editing holds at the SOV level, rather than cost item */
    featureUseSOVHolds: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_FLYNN || tempRoles.IS_DEMO_ENV_GU ||tempRoles.IS_ACC_DEV,

    /** [Bespoke] Show the Balance column in the Dashboard Cost table. Balance is same as Holds for G&T */
    featureUseBalanceColumn: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** Show the Invoice column in the Dashboard Cost table */
    featureUseInvoicedColumn: (tempRoles) =>
      !tempRoles.IS_MORGAN_BUDGETING && 
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show the Paid To Date column in the Dashboard Cost table */
    featureUsePaidToDateColumn: (tempRoles) =>
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show the Pending Invoice column in the Dashboard Cost table */
    featureUsePendingInvoicesColumn: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Show the Remaining (Forecast) column in the Dashboard Cost table. Remainig is Forecast - Invoiced */
    featureUseRemainingColumn: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_DAYRISE,

    featureUseContingencyColumn: (tempRoles) => tempRoles.IS_BANNER,
    // featureUseContingencyRemainingColumn : (tempRoles) =>
    //   tempRoles.IS_BANNER,
    // featureUseRetainerRemainingColumn : (tempRoles) =>
    //   tempRoles.IS_BANNER,
    featureUseGLCodeColumn: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_KENNEDY_WILSON &&
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_PRG_REAL_ESTATE &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_MAGNOLIA &&
      !tempRoles.IS_SUMMIT &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_SUMMIT,

    featureUseShellGLCodeColumn: (tempRoles) =>
      !tempRoles.IS_FCP &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_SUMMIT,

    /* Changes the forecast formula to equal holds plus revised budgets 
      and adds a column to the dashboard with that data*/
    featureUseForecastFormulaHoldsPlusRevisedBudgetsDashboard: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,

    /* Creates a projected Cost field that equals committed plus pending COs 
      and adds a column to the dashboard with that data*/
    featureUseProjectedCostsDashboard: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,

    /* The same two columns as above, but added to the shell view */
    featureUseForecastFormulaHoldsPlusRevisedBudgetsShellView: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,
    featureUseProjectedCostsShellView: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,

    /** Left Align the GL Code column text. This is for standard GL code column and not for Tags
        Relies on featureUseGLCodeColumn to be on */
    featureUseGLCodeColumnLeftAlign: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_MAA ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_LANTOWER,

    featureUseTagsColumns: (tempRoles) => true,
    featureUseCommentColumn: (tempRoles) => !tempRoles.IS_DEMO_ENV_CAPEX,


    /** Long Term Budget on Project Shell */
    featureUseLongTermBudget: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT,

    featureUseLongTermBudgetColumnBudgetPerDoor: (tempRoles) => tempRoles.IS_MORGAN || tempRoles.IS_DAYRISE,

      // Used to default Project type to cost Item for morgan and populate shell tag with "Long Term Budget Shell Type" tag dropdown types.
    featureHideLongTermProjectType: (tempRoles) => 
      tempRoles.IS_MORGAN || 
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_DAYRISE || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_FCP || 
      tempRoles.IS_ACC || 
      tempRoles.IS_AIR,
    featureUseUnderwritingBudget: (tempRoles) => {
      if (tempRoles.IS_FCP) {
        return tempRoles.IS_FCP_SUBGROUP_NOSUBGROUP && !tempRoles.IS_FCP_UFG_PROPERTY_MANAGER;
      }
      return tempRoles.IS_BANNER_DEMO_ENV_1 || tempRoles.IS_KPR;
    },
    featureUseLifeToDateSpent: (tempRoles) => tempRoles.IS_FCP,

    featureUseDurationInProjectModal: (tempRoles) => !tempRoles.IS_OSSO && !tempRoles.IS_GRIFFIN && !tempRoles.IS_29TH_STREET,

    /** Project Start Date from Add Project Modal as a column in Dashboard Cost Table */
    featureUseProjectStartDate: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_LANTOWER,

    /** Project End Date from Add Project Modal as a column in Dashboard Cost Table */
    featureUseProjectEndDate: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_LANTOWER,
    

    featureUseStagesInDashboardTracker,
    featureUseSubgroupFilter: (tempRoles) => 
      tempRoles.IS_OSSO ||
      tempRoles.IS_FCP ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_STONEWEG,

    featureUseProjectStageFilter: (tempRoles) => featureUseStagesInDashboardTracker(tempRoles),
    featureUseProjectTypeFilter: (tempRoles) =>
      _DASHBOARD_ADD_PROJ_SHELL_MODAL.featureUseAddProjectType(tempRoles) &&
      (tempRoles.IS_MAGNOLIA ||
        tempRoles.IS_KENNEDY_WILSON ||
        tempRoles.IS_RIDC ||
        tempRoles.IS_BMC_INVESTMENTS ||
        tempRoles.IS_CLEAR_HEIGHT ||
        tempRoles.IS_MRA ||
        tempRoles.IS_SUMMIT ||
        tempRoles.IS_ACC ||
        tempRoles.IS_WATERTON ||
        tempRoles.IS_INDUSTRIOUS ||
        tempRoles.IS_OSSO ||
        tempRoles.IS_KETTLER ||
        tempRoles.IS_TIDES ||
        tempRoles.IS_KPR ||
        tempRoles.IS_RADCO ||
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
        tempRoles.IS_OLYMPUS_DEVELOPMENT ||
        tempRoles.IS_MILEWAY_DEMO ||
        tempRoles.IS_FLYNN ||
        tempRoles.IS_DEMO_ENV_GU ||
        tempRoles.IS_DAYRISE ||
        tempRoles.IS_AIR ||
        tempRoles.IS_DEMO_ENV_CAPEX ||
        tempRoles.IS_AIRBNB ||
        tempRoles.IS_LANTOWER ||
        tempRoles.IS_GRIFFIN ||
        tempRoles.IS_29TH_STREET ||
        tempRoles.IS_ABACUS ||
        tempRoles.IS_DEMO_ENV_GL ||
        tempRoles.IS_STONEWEG
        ), // Added Acacia team to featureUseProjectTypeFilter
    
    /** Budget Remaining Column in Dashboard Cost Table. Budget Remaining = Budget - Invoice */
    featureUseBudgetRemaining: (tempRoles) =>
      tempRoles.IS_FCP || tempRoles.IS_STARWOOD || tempRoles.IS_TITAN_CORP,
    
    /** Budget Remaining Column in Dashboard Cost Table. 
     * Budget Remaining = Budget - Committed
     * Called "Remaining to Commit by default"
    */
    featureUseBudgetVarianceColumn: (tempRoles) => 
      tempRoles.IS_TOURMALINE || 
      tempRoles.IS_ACC || 
      tempRoles.IS_MORGAN ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DAYRISE,
    
    /** Budget Remaining Column in Dashboard Cost Table. Budget Remaining = Budget - Committed. Rename to "Budget Remaining"*/
    featureUseBudgetVarianceColumnRenameToBudgetRemaining: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_FLYNN || tempRoles.IS_DAYRISE,
    
    /** Budget Remaining Column in Dashboard Cost Table. Budget Remaining = Budget - Invoice. Rename to "Remaining To Contract"*/
    featureUseBudgetVarianceColumnRenameToRemainingToContract: (tempRoles) => tempRoles.IS_MORGAN,
    
    /** Rename the Budget Column to "Construction Budget" */
    featureRenameBudgetToConstructionBudget: (tempRoles) => tempRoles.IS_PRG_REAL_ESTATE,

    featureRenameProjectStartDateToActualStartDate: (tempRoles) => false,
    featureRenameProjectEndDateToActualEndDate: (tempRoles) => false,

      /** Renames Budget Transfer columns to "Revision"*/
      featureRenameBudgetTransferToRevision: (tempRoles) => 
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
        tempRoles.IS_EOS || 
        tempRoles.IS_RIDC || 
        tempRoles.IS_DEMO_ENV_GU ||
        tempRoles.IS_ABACUS ||
        tempRoles.IS_DEMO_ENV_GL ||
        tempRoles.IS_FLYNN,

      /** Renames Revised Budget columns to "Budget"*/
      featureRenameRevisedBudgetToBudget: (tempRoles) => tempRoles.IS_RIDC,

    /** Use alternative set of Financial Columns and Names oppose to standard. Do not turn on for anyone but 
     *  Cardinal teams unless you really have to :)
     */
    featureUseAlternateGLCodeColumns: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL,

    /** Adds a new column Contract Invoices to the [GL-Alias] table and updates the formula for Current Contract Balance to Finish column */
    featureUseContractInvoicesColumnGL: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL,

    /** Depends on featureUseProjectSummaryAutoForecast and featureUseProjectSummaryAsDrawSummary 
     *  - sort: To sort columns in draw report
     *  - addForecastedCosts: To populate Forecasted Additional Costs column as Forecasted Additional Costs + autoforecast in reports only
     *  - excludedColumns: List of columns to be excluded from draw report export
     *  - columnsOrder(depends on sort): Specify columns order in draw report export, if not define default column ordering will be applied
     * 
     * 
     * {
     *    sort: boolean,
     *    addForecastedCosts: boolean,
     *    excludedColumns: string[],
     *    columnsOrder: string[]
     * } 
    */
    featureUseProjectDrawSummarySkipColumns: (tempRoles) => {
      if(tempRoles.IS_CARDINAL) {
        return {
          sort: true,
          addForecastedCosts: true,
          excludedColumns: [
            'approvedBids',
            'pendingCO',
            'approvedCOs',
            'invoicedContract',
            'invoicedPaidToDate',
            'currentContractBalance',
            'projectedOverUnder',
            'autoForecast',
          ]
        };
      }
      if(tempRoles.IS_CARDINAL_DEVELOPMENT) {
        return {
          sort: true,
          addForecastedCosts: false,
          excludedColumns: [
            'approvedBids',
            'pendingCO',
            'approvedCOs',
            'totalHoldsGL',
            'invoicedContract',
            'invoicedPaidToDate',
            'currentContractBalance',
            'projectedOverUnder',
            'autoForecast',
          ],
          columnsOrder: [
            'costColumnName',
            'originalBudget',
            'budgetTransfer',
            'budget',
            'committedCurrent',
            'totalHoldsGL',
            'invoicedNonContract',
            'estimatedCosts',
            'priorDrawCosts',
            'currentDrawTotal',
            'total',
            'balance',
            'percentComplete',
          ]
        };
      }
      return null;
    },

    /** Adds a new column contract invoices to the gl code summary table and updates the formula for current contract balance to finish column */
    featureUseBlackTypeForProjectedOverUnder: (tempRoles) => false,

    /** Sorts cost codes in ascending order */
    featureUseAscendingSortCostCodes: (tempRoles) => 
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS,

    /** Rename Long Term Remaining column to "Contract Variance to Budget" */
    featureRenameLongTermRemainingToContractVarianceToBudget: (tempRoles) => tempRoles.IS_PRG_REAL_ESTATE,

    /** Rename Long Term Remaining column to Remaining */
    featureRenameLongTermRemainingToRemaining: (tempRoles) => tempRoles.IS_MAA || tempRoles.IS_AIR,

    /** Renames the Approved Bids column to "Contracted" */
    featureRenameApprovedBidsToContracted: (tempRoles) => tempRoles.IS_STARWOOD,

    /** Renames the GL Code column to "Project Code" */
    featureRenameGLCodeToProjectCode: (tempRoles) => false,

    /** Renames GL Code table to "Project Summary" */
    featureRenameGLCodeToProjectSummary: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS,

    /** Renames Budget Form tab to "Budget Revision" */
    featureRenameBudgetFormToBudgetRevision: (tempRoles) => 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FLYNN,

      featureUseBudgetDrafts: (tempRoles) => 
        tempRoles.IS_ACC || 
        tempRoles.IS_CARDINAL_DEVELOPMENT || 
        tempRoles.IS_CARDINAL ||
        tempRoles.IS_ABACUS ||
        tempRoles.IS_FLYNN ||
        tempRoles.IS_EOS ||
        tempRoles.IS_ACC_DEV,

      // Depends on featureUseBudgetDrafts. Display and allow to edit draft budgets in budget history tab instead of budgetform Tab.
      featureShowBudgetDraftsInBudgetHistoryTab: (tempRoles) =>
        tempRoles.IS_ACC || tempRoles.IS_FLYNN || tempRoles.IS_ACC_DEV,


    /** Renames GL Code column to "Job Code" */
    featureUseGlCodeRenameToJobCode: (tempRoles) => tempRoles.IS_STARWOOD,

    /** Renames GL Code column to "Category Code" */
    featureUseGlCodeRenameToCategoryCode: (tempRoles) =>
      tempRoles.IS_MAA || tempRoles.IS_MORGAN || tempRoles.IS_MORGAN_BUDGETING || tempRoles.IS_MORGAN_CONTRACTS || tempRoles.IS_WATERTON,

    /** Renames the GL Code column to "Cost Code" */
    featureUseGlCodeRenameToCostCode: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_EOS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseShowRetainageColumn: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_29TH_STREET,

    featureUseBalanceColumnOverride: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL,
  });

  const _PROJECT_SHELL_RENOVATIONS = typeMustExtend(mapType, {
    /** Renovations Tab in Project Shell */
    featureUseRenovationsTable: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_AIR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,

    /** Select Box on top of Renovations Table */
    featureProjectShellRenovationsSelectUnitType: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_AIR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,

    featureEditShellRenovationsUnitType: (tempRoles) => 
      tempRoles.IS_TIDES || 
      tempRoles.IS_RADCO || 
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,

      featureProjectShellRenovationsDefaultPageSize: (tempRoles) => {
        if (tempRoles.IS_TIDES || tempRoles.IS_AIR) {
          return [200,25,50,100];
        } else if (tempRoles.IS_RADCO || tempRoles.IS_LIVCOR_ROI || tempRoles.IS_MAGNOLIA) {
          return [25,50,100] 
        }
        return undefined; //Default Page Sizes are [25,50,100] pre-configured in DataTable
      },

    featureProjectShellRenovationsDefaultUnitType: (tempRoles) => {
      if (tempRoles.IS_HARBOR || tempRoles.IS_RADCO || tempRoles.IS_AIR) {
        return 'All';
      }

      return 'Renovated Unit';
    },

    featureHideShellRenovationTurnType: (tempRoles) => tempRoles.IS_LIVCOR_ROI,
    featureUseRenovationsAccountColumn: (tempRoles) => tempRoles.IS_LIVCOR_ROI,
    featureUseRenovationsPBCSColumn: (tempRoles) => tempRoles.IS_LIVCOR_ROI,

    featureProjectShellRenovationsUnitTypeRegularTurn: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_AIR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET,

    featureProjectShellRenovationsUnitTypeHistoricalUnit: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_AIR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET,

    featureProjectShellRenovationsUnitTypeRenovatedUnit: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_AIR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET,

    featureProjectShellRenovationsAssignTypeIfUnitNumberInSOV: (tempRoles) => tempRoles.IS_TITAN_CORP,

    featureProjectShellRenovationsColumnVendorNotificationDate: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnMoveOutNotificationDate: (tempRoles) => tempRoles.IS_HARBOR || tempRoles.IS_AIR,
    featureProjectShellRenovationsColumnRenovationComplete: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnAmenityPremium: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnWasherDryerPremium: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnSqFt: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_TITAN_CORP || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnEstimatedConstructionEndDate: (tempRoles) => false,
    featureProjectShellRenovationsColumnConstructionEndDate: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationsColumnConstructionStartDate: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO && !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX && !tempRoles.IS_AIR,
    featureProjectShellRenovationsColumnDaysInConstruction: (tempRoles) => false,
    featureProjectShellRenovationsColumnOccupancyStatus: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnTotalDaysVacant: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnCostIncludingCmFee: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX || tempRoles.IS_29TH_STREET,
    featureProjectShellRenovationsColumnCmFee: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX || tempRoles.IS_29TH_STREET,
    featureProjectShellRenovationsColumnTradeOut: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnPriorRent: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_TITAN_CORP || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnUnrennovatedMarketRent: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnNewlyLeasedRent: (tempRoles) => tempRoles.IS_TITAN_CORP || tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnPricePerSqFt: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnProFormaRent: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnRenovationPremium: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_TITAN_CORP || tempRoles.IS_DEMO_ENV_CAPEX,
    featureProjectShellRenovationsColumnEstimatedMakeReadyDate: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnMakeReadyDate: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnStatus: (tempRoles) => tempRoles.IS_HARBOR || tempRoles.IS_TITAN_CORP || tempRoles.IS_WATERTON,
    featureProjectShellRenovationsColumnConcessions: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX || tempRoles.IS_29TH_STREET,
    featureProjectShellRenovationsColumnCostOfRenovationBasedOnGlCode: (tempRoles) => tempRoles.IS_HARBOR,
    featureProjectShellRenovationsColumnCostOfRenovationBasedOnSOVName: (tempRoles) => false,
    featureProjectShellRenovationsColumnCostOfRenovationBasedOnSOVUnit: (tempRoles) => 
      tempRoles.IS_TITAN_CORP || 
      tempRoles.IS_AIR|| 
      tempRoles.IS_TIDES || 
      tempRoles.IS_DEMO_ENV_CAPEX || 
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,
    featureRenovationsColumnBudgetingCost: (tempRoles) => tempRoles.IS_TITAN_CORP,
    /**
     * Note: implementation of `_budgetingDelta` is based on
     * {@link F.featureProjectShellRenovationsColumnCostOfRenovationBasedOnSOVName}
     *
     * not {@link F.featureProjectShellRenovationsColumnCostOfRenovationBasedOnGlCode}
     */
    featureRenovationsColumnBudgetingDelta: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationColumnDiffsRenoCompletionToLeaseStart: (tempRoles) => false,
    featureRenovationColumnDiffsRenoStartToMoveOut: (tempRoles) => false,
    /** Last I checked, `_renoStartToActualStart` is still blank - having TODO as not yet implemented */
    featureRenovationColumnDiffsRenoStartToActualStart: (tempRoles) => false,
    featureRenovationColumnDiffsEstRenoStartToActualStart: (tempRoles) => false,

    featureUseRenovationUnitTurnTypeAdjustment: (tempRoles) => tempRoles.IS_TIDES  || tempRoles.IS_DEMO_ENV_CAPEX || tempRoles.IS_29TH_STREET,
    featureRenovationsColumnEstimatedConstructionBeginDate: (tempRoles) => false,

    featureRenovationsColumnLeaseApprovalTxt: (tempRoles) => false,
    featureRenovationsColumnLeasedDate: (tempRoles) => false,
    featureRenovationsColumnLeaseExpiration: (tempRoles) => false,
    featureRenovationsColumnOrganicRentIncrease: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationsColumnRenovationType: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationsColumnEditableDates: (tempRoles) => true,
    featureRenovationsColumnRoi: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO && !tempRoles.IS_AIR,
    featureRenovationColumnRoiRenoPremium: (tempRoles) => false,

    featureRenovationHistoricalUnit: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO && !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX && !tempRoles.IS_AIR,

    featureRenovationMoveInDate: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO,

    featureRenovationNotes: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX && !tempRoles.IS_AIR,

    featureRenovationsColumnVacateDate: (tempRoles) => !tempRoles.IS_LIVCOR_ROI && !tempRoles.IS_RADCO,

    featureRenovationTrackerSortByUnitCode: (tempRoles) => tempRoles.IS_LIVCOR_ROI || tempRoles.IS_29TH_STREET,
    featureRenovationTrackerBusinessPlan: (tempRoles) => !tempRoles.IS_TITAN_CORP && !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX,
    featureRenovationTrackerEditableDateColumnsAsTags: (tempRoles) => false,
    featureRenovationTrackerUnitCompleteFromConstructionEndDate: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX || tempRoles.IS_29TH_STREET,
    featureRenovationTrackerUnitCompleteFromStatusComplete: (tempRoles) => !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX,
    featureRenovationTrackerUnitInProcessExcludeCancelled: (tempRoles) => tempRoles.IS_HARBOR,
    /** Use the value used for ROI calculation instead of default Renovation Cost */
    featureRenovationTrackerReplaceRenovationCost: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationTrackerQuarterTableShowHistorical: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureRenovationTrackerTotalsTableShowHistorical: (tempRoles) => false,
    featureRenovationTrackerTotalsTableShowAllYears: (tempRoles) => false,
    featureRenovationTrackerTotalsWasherDryerColumns: (tempRoles) =>
      !tempRoles.IS_TITAN_CORP && !tempRoles.IS_TIDES && !tempRoles.IS_DEMO_ENV_CAPEX,
    featureRenovationTrackerAvgDaysToCompleteFields: (tempRoles) => {
      if (tempRoles.IS_TITAN_CORP || tempRoles.IS_TIDES  || tempRoles.IS_DEMO_ENV_CAPEX) {
        return ['renovationComplete', 'constructionStartDate'];
      }
      return ['makeReadyDate', 'vendorNotificationDate'];
    },
    /** @type {(roles: _Roles) => [string, string][]} */
    featureRenovationTrackerQuarterDataFields: (tempRoles) => {
      if (tempRoles.IS_TIDES  || tempRoles.IS_DEMO_ENV_CAPEX) {
        return [
          ['vacateDate', 'constructionStartDate'],
          ['constructionStartDate', 'renovationComplete'],
          ['renovationComplete', 'moveInDate'],
        ];
      }
      if (tempRoles.IS_TITAN_CORP) {
        return [
          ['vacateDate', 'constructionStartDate'],
          ['constructionStartDate', 'renovationComplete'],
          ['renovationComplete', 'moveInDate'],
          ['vacateDate', 'moveInDate'],
        ];
      }
      return [
        ['vendorNotificationDate', 'constructionStartDate'],
        ['constructionStartDate', 'renovationComplete'],
        ['renovationComplete', 'makeReadyDate'],
        ['makeReadyDate', 'moveInDate'],
      ];
    },
    featureRenovationTrackerFieldToAssignQuarter: (tempRoles) => {
      if (tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX) {
        return ['vacateDate', 'constructionStartDate'];
      }
      if (tempRoles.IS_TITAN_CORP || tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX) {
        return ['constructionStartDate'];
      }
      return ['vendorNotificationDate'];
    },
    featureRenovationTrackerOnlyTurnTypeRenovatedUnit: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,
  });

  const _DASHBOARD_HIDE_THINGS = typeMustExtend(mapType, {
    
    /** Turns off using first shell cost item for budget transfers */
    featureTurnOffUseFirstCostItem: (tempRoles) =>
      tempRoles.IS_ACC ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_STONEWEG,


    /** Hides the Budget Tab from the Dashboard Page */
    featureHideBudget: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MRA ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_AIR,

    featureHideCosts: (tempRoles) =>  tempRoles.IS_HARBOR || tempRoles.IS_LIVCOR_ROI,

    featureHideContractRemaining: (tempRoles) => false,
    
    /** Hides the Long Term Budget Tab from the Dashboard Page */
    featureHideLongTermBudget: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_STARWOOD || 
      tempRoles.IS_MILEWAY_DEMO || 
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_MAA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_STONEWEG,

    /** Allow User to Select All Properties in the Contracts Table in Dashboard page */
    featureAllowLoadAllContracts: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_FCP ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_AIR
  });

  const _DASHBOARD_BUDGETING = typeMustExtend(mapType, {
    featureUseBudgetTransfersFormLabelsBudgetMinusCommitted: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_MORGAN,
    
    featureUseBudgetTransfers: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_AIR ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ACC ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MRA ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_KPR ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_FLYNN,


    /** Budget Transfer Form at Dashboard Page. Budget Trasnfers cannot be made Across Shells */
    featureDashboardProhibitTransferBetweenShells: (tempRoles) =>
      tempRoles.IS_MORGAN || tempRoles.IS_FLYNN,

    /** Budget Transfer Form at Dashboard Page. Budget Trasnfers are done at Shell level only*/
    featureDashboardProhibitCostItemTransfers: (tempRoles) =>
      tempRoles.IS_ACC || tempRoles.IS_STONEWEG || tempRoles.IS_AIRBNB,

    /** Budget Transfer Form at Shell Page. Budget Trasnfers are done at Cost Item level */
    featureShellPageBudgetTransfersForCostItemsOnly: (tempRoles) =>
      tempRoles.IS_ACC || tempRoles.IS_FLYNN || tempRoles.IS_DAYRISE || tempRoles.IS_AIRBNB || tempRoles.IS_WILLOW_BRIDGE,
    
    /** Budget Transfer Form at Shell Page. Budget Trasnfers are done at SOV level */
    featureUseBudgetSOVTransfers: (tempRoles) =>
      tempRoles.IS_MAGNOLIA || 
      tempRoles.IS_EOS || 
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_WATERTON || 
      tempRoles.IS_DEMO_ENV_GU,

    featureUseBudgetTransferFormColDescription: (tempRoles) =>  tempRoles.IS_ACC_DEV,
    featureUseBudgetTransferFormColDescriptionRequired: (tempRoles) => tempRoles.IS_ACC_DEV,
    /** Allows the user to enter the negative budget greater than sov current budget for all sovs */
    featureAllowOverBudgetSOVTransfer: (tempRoles) => tempRoles.IS_ACC_DEV,

    featureUseBudgetTransferFormColIdNumber: (tempRoles) => 
      tempRoles.IS_ACC_DEV,

    featureUseBudgetTransferFormSovTags: (tempRoles) => 
      tempRoles.IS_ACC_DEV,

    featureShowBudgetRevisionGlCode: (tempRoles) => tempRoles.IS_MORGAN,

    // This is the modal for the transfer form
    featureUseModalBudgetEdit: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_ACC ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_FLYNN,
  });

  const _DASHBOARD_MISC = typeMustExtend(mapType, {
    featureRenameCostToContract: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL || tempRoles.IS_ACC_DEV,

    featureRenameContractToContractTable: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL,

    featureUseCommentsPreviewOnHover: (tempRoles) =>
      tempRoles.IS_STONEWEG || tempRoles.IS_JRK || tempRoles.IS_HARBOR,

    /** Notes are similar to comments with rich text */
    featureDashboardUseNotes: (tempRoles) =>
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_ABACUS,

    featureDashboardCommittedNotIncludeInvoiced: (tempRoles) => tempRoles.IS_OSSO || tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS,
    featureUseDashboardSections: (tempRoles) =>
      tempRoles.IS_BANNER || tempRoles.IS_JRK || tempRoles.IS_GREENPOINTE || tempRoles.IS_TOURMALINE,

    /** Year Selector on the Dashboard Page  - needed for long term [LONG TERM BUDGET MODULE] */
    featureForceYearFilter: (tempRoles) =>
      tempRoles.IS_FCP ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_AIR ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACC ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Provide Ability to Edit a Comment any time */
    featureUseEditShellComments: (tempRoles) =>
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_OSSO || 
      tempRoles.IS_KPR ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_ABACUS ,

      featureUseEditCustomTaskComments: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_MORGAN,

    featureFilterShellBudgetHistoryFromDashboard: (tempRoles) => tempRoles.IS_TOURMALINE,
    
    /** Provide "Mark Paid" checkbox in the Invoice table and Check Number column in the Invoice Table. */
    featureUseCheckNumberColumn: (tempRoles) =>
      tempRoles.IS_TIDES || // Required by Userrs to show Check# column in invoices table on dashboard
      tempRoles.IS_BANNER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_OSSO || tempRoles.IS_STARWOOD ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MAA ||
      tempRoles.IS_FCP || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_EOS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MRA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_STONEWEG,
  });

  const _DASHBOARD_DEPRECATED = typeMustExtend(mapType, {
    featureUseDocumentsPreviewOnHover: (tempRoles) => true,
    featureUseProjectManager: (tempRoles) => false,

    featureUseProjectCoordinator: (tempRoles) => false,

    featureUseLenderRepair: (tempRoles) => tempRoles.IS_STONEWEG,

    featureUseGreenImprovement: (tempRoles) => tempRoles.IS_STONEWEG,

    featureUseProjectInfoTile: (tempRoles) => tempRoles.IS_STONEWEG,

    featureAlternateDashboardRowColor: (tempRoles) => tempRoles.IS_STONEWEG,
    featureUseNumberOfBidsInProjectTooltip: (tempRoles) => tempRoles.IS_STONEWEG,

    featureUseSubmitCommentOnEnter: (tempRoles) => tempRoles.IS_STONEWEG || tempRoles.IS_JRK,
    featureUsePaidToDate: (tempRoles) => tempRoles.IS_METROVATION,
    featureUseStonewegDetails: (tempRoles) => tempRoles.IS_STONEWEG,
    featureUseBuildingSummaryTable: (tempRoles) => tempRoles.IS_STARLIGHT || tempRoles.IS_UNIT_DEMO,
    featureUseInvoicePayments: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_PAULSCORP,
    featureUseDashboardAssetFiles: (tempRoles) =>
      tempRoles.IS_BANNER || tempRoles.IS_JRK || tempRoles.IS_OSSO || tempRoles.IS_MORGAN_CONTRACTS || tempRoles.IS_FCP,

    /** [Bespoke][Deprecated] Alows user to quick add invoices from dashboard */
    featureQuickAddInvoice: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_STONEWEG,
    /** DEPRECATED */
    featureUseGenerateMRAInvoiceExport: (tempRoles) => tempRoles.IS_MRA,
  });

  const _SHELL_TABS = typeMustExtend(mapType, {
    // search for costs table
    featureUseShellSearch: (tempRoles) => true,

    /** Renames both Shell and Dashboard Cost Table to "Contracts" */
    featureRenameProjectShellCostTrackerToContracts: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Hides the ability to create free form shell budgets and Budget Table in Shell Page. T
     *  Typically turned on in conjuction with budget transfer form */ 
    featureUseShellBudgets: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_HARBOR &&
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_WATERTON &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_AIR,

    featureShellGlCodeMonthlyBudgets: (tempRoles) => tempRoles.IS_HARBOR,

    /** Draw Reporting Module */
    featureUseDrawReporting: (tempRoles) =>
      !IS_ENTERPRISE_MFA(tempRoles) &&
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_FCP &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_WATERTON &&
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_LANTOWER &&
      !tempRoles.IS_STONEWEG,

    /** Provide ability to assign GL Code at Cost Item level. Three-dot-menu in shell cost table */
    featureUseCostItemGlCodes: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MAA ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseLongTermBudgetGL: (tempRoles) => false,

    featureUseSovUnits: (tempRoles) => 
      tempRoles.IS_TITAN_CORP || 
      tempRoles.IS_TIDES || 
      tempRoles.IS_MORGAN ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,

    featureAllUnitsForUnitSelection: (tempRoles) => 
      tempRoles.IS_TITAN_CORP || 
      tempRoles.IS_TIDES ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET,

    featureSetLineItemNameFromSovUnit: (tempRoles) => 
      tempRoles.IS_TIDES ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_MAGNOLIA,

    featureUseSOVTags: (tempRoles) => true,

    /** Ability to assign GL codes to SOVs */
    featureUseSOVGLCodes: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_MAA ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MRA || 
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseCOLabelAsNumber: (tempRoles) => false,
    featureUseCOGLCodes: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX,

    /** Ability to assign GL codes when adding a Change Order from Contract Room */
    featureUseSOVCOGLCodes: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB || 
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseShellCosts: (tempRoles) =>
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_LIVCOR_ROI,

    
    /** A Table at Shell Page showing SOV Budget line items */
    featureUseShellSOVBudgetTable: (tempRoles) =>
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC_DEV,

    featureUseUnitRenovationReport: (tempRoles) =>
      tempRoles.IS_HARBOR || tempRoles.IS_TITAN_CORP || tempRoles.IS_29TH_STREET,

    // relies on featureUseUnitRenovationReport
    featureUseUnitRenovationReportAddTitlesToSummary: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseUnitRenovationReportAddTitleToBusinessPlan: (tempRoles) => tempRoles.IS_HARBOR,

    featureReportsGlCodeMonthlyBudgets: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseBudgetHistoryTab: (tempRoles) => !tempRoles.IS_RADCO,

    featureUseGlCodeBudgetTab: (tempRoles) => !tempRoles.IS_RADCO,

    /** Provide a Bottom Section for Documents and Team in the Shell Page */
    featureUseShellBottomTile: (tempRoles) =>
      !tempRoles.IS_STARLIGHT &&
      !tempRoles.IS_PAULSCORP &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_UNIT_DEMO &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_AIR &&
      !tempRoles.IS_HARBOR &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_FCP &&
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_LIVCOR_ROI &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_MAA &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_LANTOWER &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_GRIFFIN &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_CLEAR_HEIGHT &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_STONEWEG,
    
    featureCreateProjectsForShell: (tempRoles) =>
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_OSSO,

    featureUseHoldsReallocation: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_AIRBNB,
    // relies on featureUseHoldsReallocation && featureUseOriginalBudgetColumns
    featureUseHoldsReallocationOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,
    featureUseDebitHoldsOnContractApproval: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    featureUseSetHoldsFromBudgets: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || tempRoles.IS_GARDINER_AND_THEOBALD,
    // relies on featureUseSetHoldsFromBudgets && featureUseOriginalBudgetColumns
    featureUseSetHoldsFromBudgetsOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,
    
    /** Budget Remaining Column in Shell Cost Table. Budget Remaining = Budget - Forecast */
    featureUseProjectShellColumnBudgetRemaining: (tempRoles) =>
      tempRoles.IS_MRA || tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_MAA || tempRoles.IS_DAYRISE || tempRoles.IS_AIR,


    featureRenameSqftToUnits: (tempRoles) =>
      tempRoles.IS_MORGAN || 
      tempRoles.IS_MORGAN_BUDGETING || 
      tempRoles.IS_MORGAN_CONTRACTS || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_FCP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MAA ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_APRIL_HOUSING ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,


    /** [GL-Code-Alias] table in Project Shell page */
    featureUseGLCodesTable: (tempRoles) =>
      tempRoles.IS_MAA || 
      tempRoles.IS_TITAN_CORP || 
      tempRoles.IS_EOS || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_LANTOWER,

    featureUseProjectShellUnitCostsTable: (tempRoles) => tempRoles.IS_TIDES || tempRoles.IS_DEMO_ENV_CAPEX,

    featureUseProjectShellAuditTable: (tempRoles) => tempRoles.IS_BANNER_EMAIL,
    
    featureUseGLCodeBudgets,

    /** Disable Editing [GL-Alias] code from 3-dot-menu if the contract is synced with 3rd party system */
    featurePreventGLCodeEditingForApproved: (tempRoles) => (tempRoles.IS_MORGAN && !tempRoles.IS_MORGAN_ADMIN),

    featureUseProjectSummaryAutoForecast: (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureHideProjectedOverUnderForContractAndShells: (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureUseProjectSummaryAsDrawSummary: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS,

    /** Sorts the sections and items within them alphanumerically in draw report cost table. Depends on featureUseProjectSummaryAsDrawSummary */
    featureUseSortDrawReportItems: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureHideCostItemsInCostTable: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_RADCO || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL || tempRoles.IS_ACC_DEV,

    /** Turn on [GL-Alias] Table in Dashboard Table */
    featureUseGLCodeBudgetsInDashboard: (tempRoles) =>
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION && 
      !tempRoles.IS_ACC_DEV && 
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    /** Automatically expand GL Code rows in the [GL-Alias] table */
    featureUseGLCodeBudgetsAutoExpandGLCodeRows: (tempRoles) => 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_29TH_STREET,
    /** depends on `featureUseGLCodeBudgets` */
    featureUseGLCodeBudgetsWithoutBuildings: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureRenameTenantAllowanceToTenantResponsibility: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_TOURMALINE,
    featureRenameLandlordAllowanceToLandlordResponsibility: (tempRoles) =>  tempRoles.IS_TOURMALINE,
    featureRenameTenantPaidToTenantCostToDate: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_TOURMALINE,
    featureRenameLandlordPaidToLandlordCostToDate: (tempRoles) =>  tempRoles.IS_TOURMALINE,
    featureUseTIOrgAutofill: (tempRoles) =>  tempRoles.IS_TOURMALINE,

    /** depends on `featureUseTiAllowance` */
    featureUseCmFeeTICosts: (tempRoles) =>  tempRoles.IS_TOURMALINE,
    featureUseCostTrackerTIAllowanceColumns: (tempRoles) =>  tempRoles.IS_TOURMALINE,

    /** SOV Pivot Tag table at Shell Level. Required to have a SOV Pivot Tag selected from Tag Settings */
    featureUseSOVTagTable: (tempRoles) =>
      tempRoles.IS_EOS || 
      tempRoles.IS_ACC || 
      tempRoles.IS_WATERTON || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_FLYNN || 
      tempRoles.IS_DEMO_ENV_GU||
      tempRoles.IS_MAA ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MRA ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_STONEWEG,
    
    
    featureGroupSOVTagTableByGlCode: (tempRoles) =>  
      !tempRoles.IS_FLYNN && 
      !tempRoles.IS_DAYRISE && 
      !tempRoles.IS_AIRBNB && 
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_BMC_INVESTMENTS &&
      !tempRoles.IS_GRIFFIN,
    
    featureGroupSOVTagTableByShell: (tempRoles) => !tempRoles.IS_WATERTON && !tempRoles.IS_ACC_DEV && !tempRoles.IS_FLYNN,

    /** Provides option to add a non-contract cost item in Shell cost table 3-dot menu */
    featureAddNonContract: (tempRoles) => !tempRoles.IS_HARBOR && !tempRoles.IS_MORGAN && !tempRoles.IS_DEMO_ENV_CAPEX,
    
    /** Provides option to add a complex non-contract cost item in Shell cost table 3-dot menu */
    featureAddComplexNonContract: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_ACC_DEV,
    
    /** Provide Invoice Table at Shell Page. Allow user to ignore invoices from Committed cost */
    featureUseShellInvoices: (tempRoles) => 
      tempRoles.IS_HARBOR || 
      tempRoles.IS_BANNER ||
      tempRoles.IS_DEMO_ENV_CAPEX,

    /** Shows the Documents as a tab in the Shell Cost Tracker Table */
    featureShowDocumentsTabInShellTopSection: (tempRoles) =>
      tempRoles.IS_HARBOR ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_WILLOW_BRIDGE || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ACC ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_MORGAN_CONTRACTS || 
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_EOS ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_STONEWEG,

    // relies on featureUseGLCodesTable
    featureUseProjectShellDefaultTabAsGLCodesTable: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseShellCostsTable: (tempRoles) => !tempRoles.IS_LIVCOR_ROI,
    featureDefaultRenoTab: (tempRoles) => tempRoles.IS_LIVCOR_ROI,

    featureUseBudgetRemainingColumn: (tempRoles) => tempRoles.IS_ACC_DEV || tempRoles.IS_RADCO,
  });

  const _SHELL_COST_TRACKER_COLUMNS = typeMustExtend(mapType, {
    featureUseColumnOrderOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    featureUseProjectShellColumnOwnershipDefault: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_TOURMALINE || 
      tempRoles.IS_BANNER ||
      tempRoles.IS_GRIFFIN,

    featureUseProjectShellColumnPaidToDate: (tempRoles) =>
      !tempRoles.IS_MAA && !tempRoles.IS_HARBOR && !tempRoles.IS_MORGAN_CONTRACTS,

    /** Show/Hide Pending Invoice Column in Shell Cost Tracker */
    featureUseProjectShellColumnPendingInvoices: (tempRoles) =>
      !tempRoles.IS_MAA && 
      !tempRoles.IS_ACC && 
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_RADCO && 
      !tempRoles.IS_MORGAN_CONTRACTS && 
      !tempRoles.IS_KETTLER && 
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_OSSO,

    featureUseProjectShellColumnCommitted: (tempRoles) =>
      !tempRoles.IS_GARDINER_AND_THEOBALD && !tempRoles.IS_PRG_REAL_ESTATE,

    featureUseProjectShellColumnApprovedBids: (tempRoles) => !tempRoles.IS_MRA && !tempRoles.IS_HARBOR &&!tempRoles.IS_MORGAN_CONTRACTS && !tempRoles.IS_WILLOW_BRIDGE,

    featureUseProjectShellColumnApprovedBidsOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** Shell Cost Table Remaining Column. Remaining = Forecast - Invoiced */
    featureUseProjectShellColumnRemaining: (tempRoles) =>
      !tempRoles.IS_HARBOR && 
      !tempRoles.IS_RADCO && 
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_CONTRACTS && 
      !tempRoles.IS_TIDES && 
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_DAYRISE,

    /** Contract Remaining Column in Shell page. 
     *  Contract Remaining = Forecast - Invoiced
     */
    featureUseProjectShellColumnRemainingContract: (tempRoles) => 
      tempRoles.IS_MAA || 
      tempRoles.IS_RADCO ||
      tempRoles.IS_FLYNN,

    /** Forecast Column in Shell Cost Tracker. If turning on/off check Dashboard column turned on/off */
    featureUseProjectShellColumnForecast: (tempRoles) =>
      !tempRoles.IS_HARBOR &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_TIDES &&
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_DEMO_ENV_CAPEX &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,

    featureUseProjectShellColumnForecastOverride: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    featureUseProjectShellColumnForecastOverRSF: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || tempRoles.IS_GARDINER_AND_THEOBALD || tempRoles.IS_KENNEDY_WILSON,

    featureUseProjectShellColumnForecastOverPSF: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    /** Pending CO Column in Shell Cost Tracker */
    featureUseProjectShellColumnPendingCOs: (tempRoles) =>
      !tempRoles.IS_PRG_REAL_ESTATE && 
      !tempRoles.IS_MAA && 
      !tempRoles.IS_RADCO && 
      !tempRoles.IS_WILLOW_BRIDGE &&
      !tempRoles.IS_ACC && 
      !tempRoles.IS_MORGAN_CONTRACTS && 
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_TITAN_CORP &&
      !tempRoles.IS_DAYRISE,

    featureUseProjectShellColumnApprovedCOs: (tempRoles) => !tempRoles.IS_HARBOR && !tempRoles.IS_MORGAN_CONTRACTS && !tempRoles.IS_WILLOW_BRIDGE,

    /** Show the Single Budget Column in Shell Cost Table */
    featureUseProjectShellColumnBudget: (tempRoles) =>
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ACC &&
      !tempRoles.IS_AIR &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_RADCO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_CARDINAL && !tempRoles.IS_CARDINAL_DEVELOPMENT &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_EOS &&
      !tempRoles.IS_RIDC &&
      !tempRoles.IS_DEMO_ENV_GU &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_29TH_STREET &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_STONEWEG &&
      !tempRoles.IS_FLYNN,


    featureProjectShellBudgetTableTotalInvoicedColumn: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    
    /** Provide ability to select section when adding a cost item */
    featureProjectShellCostTrackerMoveRowsDropdownActions: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAA ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MRA ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_WILLOW_BRIDGE,


      /** Budget Remaining column in Shell page. Budget Remaining = Budget - Committed.
       *  Column is called "Budget Remaining"
       */
      featureUseProjectShellBudgetCommittedRemaining: (tempRoles) => 
        tempRoles.IS_ACC || 
        tempRoles.IS_MORGAN ||
        tempRoles.IS_FLYNN ||
        tempRoles.IS_RADCO ||
        tempRoles.IS_DAYRISE,
  });

  const _SHELL_MISC = typeMustExtend(mapType, {
    featureUseContractRetainage: (tempRoles) => !tempRoles.IS_TOURMALINE && !tempRoles.IS_HARBOR && !tempRoles.IS_LANTOWER,
    featureUseContractVatBased: (tempRoles) => tempRoles.IS_CONVENE || tempRoles.IS_BANNER,

    /** Provides Ability to set Retainage per SOV for Invoices */
    featureUseSOVRetainage: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_29TH_STREET,

    /** Allow user to add negative value in retainage. Use case is ambigious */
    featureUseNegativeSOVRetainage: (tempRoles) => 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_FLYNN || 
      tempRoles.IS_EOS ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_MRA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_29TH_STREET,
    /**
     * relies on featureUseSOVRetainage being turned on
     * Probably this is not compatible with `featureCurrencySystem`
     */
    featureUseReleasePartialRetainage: (tempRoles) =>
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MRA ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_29TH_STREET,

    featureUseRetainageNotEditableByVendor: (tempRoles) => tempRoles.IS_CONVENE,
    /**
     * Relies on featureUseSOVRetainage being turned on. Allows to enter retainage by $ amount instead of %
     */
    featureUseEnterRetainageByAmount: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_AIRBNB || 
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_MRA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_29TH_STREET,

    featureUseVendorSummary: (tempRoles) => tempRoles.IS_SUMMIT || tempRoles.IS_ACACIA_CAPITAL_CORPORATION,

    // specialized logic for starwood, creates a contract ID for each cost item
    featureGenerateContractID: (tempRoles) => tempRoles.IS_STARWOOD,

    featureStatusNameBanner: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL,
    
    /** Provide Ability to Show Cost Item Tags in Contracts Table in Dashboard Page */
    featureCostItemTagColumnsInContractTable: (tempRoles) => 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO || 
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_CONVENE ||
      true,

    featureContractIDFormat: (tempRoles) => {
      if (tempRoles.IS_MORGAN) return CONTRACT_ID_FORMAT.INCREMENTBASED;
      return CONTRACT_ID_FORMAT.BANNER;
    },

    featureUseIndustriousTiles: (tempRoles) => tempRoles.IS_INDUSTRIOUS,
    featureUseIndustriousTileReportFinancials: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    featureUseKennedyWilsonTiles: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    featureUseKennedyWilsonTileReportFinancials: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,

    // relies on featureUseTiAllowance
    featureUseTiAllowanceTileTotalProjectCost: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_GRIFFIN,
    featureUseTiAllowanceTileTenantFundingReceived: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_GRIFFIN,

    featureUseAddSov: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_ACC ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Provide ability to select section when adding a cost item to the Shell Cost Tracker */
    featureShellCostItemModalSectionDropdown: (tempRoles) => true,
    // was:
    // tempRoles.IS_GARDINER_AND_THEOBALD ||
    // tempRoles.IS_TITAN_CORP ||
    // tempRoles.IS_KENNEDY_WILSON ||
    // tempRoles.IS_RIDC ||
    // tempRoles.IS_RADCO ||
    // tempRoles.IS_WATERTON,

    featureUseContractPO: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_GREENPOINTE ||
      tempRoles.IS_JRK ||
      tempRoles.IS_LIVCOR ||
      tempRoles.IS_METROVATION ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_PAULSCORP ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_STARLIGHT ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_UNIT_DEMO,

    /** must have  featureUseContractPO enabled */
    featureUseContractPOEdit: (tempRoles) => tempRoles.IS_CLEAR_HEIGHT,
    
    /** Shows Contract ID in Contract Room, Cost Tracker, and Custom Reports */
    featureUseShowContractID: (tempRoles) =>
      tempRoles.IS_MORGAN || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_WATERTON ||
      tempRoles.IS_AIR ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_MORGAN_BUDGETING,


    featureUseShowFullSovNameForGrouped: (tempRoles) =>
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_EOS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseShellBudgetOverride: (tempRoles) => tempRoles.IS_TOURMALINE && tempRoles.IS_BANNER_EMAIL,
    featureCopyGLCodeInCreateProjectForShell: (tempRoles) => tempRoles.IS_KETTLER,
    featureShowGLCodeOnNav: (tempRoles) => 
      tempRoles.IS_MRA || 
      tempRoles.IS_BANNER ||
      tempRoles.IS_TIDES || 
      tempRoles.IS_FCP || 
      tempRoles.IS_KETTLER ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_29TH_STREET,

    featureUseProjectShellInvoiceDateAdjuster: (tempRoles) => false,

    /** Provide an option to assign a GL code to a cost item in the three-dot-menu for cost items */
    featureUseAssignCostItemToGlCode: (tempRoles) =>
      !tempRoles.IS_MAA &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_KETTLER &&
      !tempRoles.IS_OSSO &&
      !tempRoles.IS_SUMMIT &&
      !tempRoles.IS_CONVENE &&
      !tempRoles.IS_AIRBNB &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL &&
      !tempRoles.IS_FCP,

    featureAllowNegativeBudgets: (tempRoles) =>
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACC ||
      tempRoles.IS_EOS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_DAYRISE,

    featureAutoGenerateBudgetRevisionName: (tempRoles) => tempRoles.IS_TOURMALINE,

    featureKeepIgnoredNonContractInvoicesInInvoicedAmount: (tempRoles) => !tempRoles.IS_HARBOR, 
  });

  const _SHELL_HARBOR = typeMustExtend(mapType, {
    featureUseCostRollUp: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseGLCodeCosts: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseCostCommitted: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseGLDrawReports: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseShellCostsAsContracts: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseGLCodeCostInfo: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseGlCodeBudgetTransfers: (tempRoles) =>
      tempRoles.IS_HARBOR || featureUseGLCodeBudgets(tempRoles),

    featureUsePortfolioRollup: (tempRoles) => tempRoles.IS_HARBOR,

    featureDisableDrillDown: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseUpdateGLCodeStatus: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseContractCompletionDate: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseProjectShellCostTrackerBidOutOption: (tempRoles) => !tempRoles.IS_HARBOR,

    featureProjectShellCostTrackerMoveRows: (tempRoles) => !tempRoles.IS_HARBOR,
    featureDifferenceIsTotalVsInvoice: (tempRoles) => tempRoles.IS_HARBOR,
    featureBudgetRemainingIsBudgetMinusInvoiced: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL,

    /** Filter bids to use for "Under Contact" calculation, Target Completion Date must be in current year. Affects dashboard, shell, reports. */
    featureFilterUnderContractAmountByTargetCompletionYear: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseBudgetHistoryComments: (tempRoles) => !tempRoles.IS_HARBOR,
    featureHasGLCodeTable: (tempRoles) => tempRoles.IS_HARBOR,

    featureShellCostsSplitNonContractCostItems: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseProjectShellCostTrackerSections: (tempRoles) => !tempRoles.IS_HARBOR,
    featureUseContractInvoices: (tempRoles) => !tempRoles.IS_HARBOR,
    featureRenameBudgetHistoryCostItemToGlCode: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseCostItemStageTag: (tempRoles) => tempRoles.IS_HARBOR,

    featureShowDrawDocument: (tempRoles) =>
      !tempRoles.IS_HARBOR && !featureUseGLCodeBudgets(tempRoles),
    featureCommentNotificationForSpecificRoles: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseRevisedCheckNumber: (tempRoles) => tempRoles.IS_HARBOR,
    /** [Bespoke]Lets user add an invoice to project shell invoices table. Categorizes based on GL */
    featureAddShellInvoice: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseCostItemAndSOVSharedGLCalculation: (tempRoles) => tempRoles.IS_HARBOR,
  });

  const _SHELL_DEPRECATED = typeMustExtend(mapType, {
    featureUseSimpleBudget: (tempRoles) => tempRoles.IS_GREENPOINTE,
    featureUseVendorTracking: (tempRoles) => tempRoles.IS_METROVATION,
    
    /** Hides Milestone Module from the Shell Page */
    featureHideMilestones: (tempRoles) =>
      tempRoles.IS_STARLIGHT ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_PAULSCORP ||
      tempRoles.IS_WILLOW_BRIDGE ||
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_FCP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_LANTOWER,

    featureHideProjectShellSummary: (tempRoles) =>
      tempRoles.IS_STARLIGHT ||
      tempRoles.IS_PAULSCORP ||
      tempRoles.IS_MORGAN_CONTRACTS || 
      tempRoles.IS_UNIT_DEMO ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseUpgradeUnitTable: (tempRoles) => tempRoles.IS_STARLIGHT || tempRoles.IS_UNIT_DEMO,
    featureUseUnitRenovationPoTracker: (tempRoles) => tempRoles.IS_PAULSCORP || tempRoles.IS_UNIT_DEMO,
    featureUseRoiTable: (tempRoles) => tempRoles.IS_STARLIGHT || tempRoles.IS_UNIT_DEMO,

    // feature allows use to utitlize edit shell modal inside a shell
    featureUseProjectEditInShell: (tempRoles) => 
      tempRoles.IS_STONEWEG || 
      tempRoles.IS_CLEAR_HEIGHT || 
      tempRoles.IS_RIDC ||
      tempRoles.IS_LANTOWER,
  });

  
  const _DRAW_REPORTING = typeMustExtend(mapType, {
    featureUseDrawReportPortalColumnScheduledValue: (tempRoles) => !tempRoles.IS_TITAN_CORP && !tempRoles.IS_SUMMIT,
    featureRenameDrawReportPortalColumnScheduledValueToBudget: (tempRoles) => tempRoles.IS_SUMMIT,
    featureUseDrawReportPortalColumnChangeOrders: (tempRoles) => !tempRoles.IS_TITAN_CORP && !tempRoles.IS_SUMMIT,
    featureUseDrawReportPortalColumnAdjustedValue: (tempRoles) => !tempRoles.IS_TITAN_CORP,
    featureRenameDrawReportPortalAdjustedValueToCommitted: (tempRoles) => tempRoles.IS_SUMMIT,
    featureUseDrawReportPortalColumnBudget: (tempRoles) => tempRoles.IS_TITAN_CORP || tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_SUMMIT,
    featureUseDrawReportShellAdjustedColumn: (tempRoles) => tempRoles.IS_SUMMIT,
    featureRenameDrawReportPortalColumnBudgetToRevisedBudget: (tempRoles) => tempRoles.IS_TITAN_CORP,
    featureUseDrawReportPortalColumnPreviousPayments: (tempRoles) => true,
    featureUseDrawReportPortalColumnCurrentApplication: (tempRoles) => true,
    featureUseDrawReportPortalColumnTotalApplications: (tempRoles) => true,
    featureUseLenderDraws: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseCardinalDrawCover: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL,
    featureUseSovTagGroupedDrawCover: (tempRoles) => tempRoles.IS_ACC_DEV,
    featureUseGroupVendorDrawTotal: (tempRoles) => tempRoles.IS_UPCAMPUS,
    featureDownloadMultipleInvoiceAttachmentsForDrawReport: (tempRoles) => tempRoles.IS_UPCAMPUS,

    /** Sorts Invoices by Date Submited in descending order on shell invoices table */
    featureUseDrawReportTableAutoSorting: (tempRoles) => 
      tempRoles.IS_ACC || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_LANTOWER,

    featureUseIncludeDrawCostTracker: (tempRoles) => !tempRoles.IS_UPCAMPUS && !tempRoles.IS_ACC_DEV,

    featureUseDocumentListingSummary: (tempRoles) => {
      if(tempRoles.IS_UPCAMPUS) return 'Disbursement Listing'
      if(tempRoles.IS_KENNEDY_WILSON) return 'Current Draw Invoices'
      return 'Document Inventory'
    },

    featureUseDrawReportSignatureSectionFields: (tempRoles) => {
      if (tempRoles.IS_KENNEDY_WILSON)
        return {
          tags: [
            {
              label: 'Legal Name',
              field: 'Legal Name',
            },
            { label: 'Address', field: 'Legal Address' },
            { label: 'Name', field: 'Draw Signatory' },
          ],
          fields: [
            {
              label: 'Date',
              field: 'drawDate',
            },
          ],
        };
      return null;
    },
    // Dependent on featureUseDrawReportSignatureSectionFields
    featureUseSignatureBlockOnFirstPage: (tempRoles) => false,

    featureUseBlockCODraw: (tempRoles) => tempRoles.IS_SUMMIT,

    featureUseGLDrawReportExport: (tempRoles) => tempRoles.IS_HARBOR,

    featureShowDrawGLCode: (tempRoles) => tempRoles.IS_HARBOR,
    featureShowDrawInvoiceNumber: (tempRoles) => !tempRoles.IS_HARBOR,
    featureShowDrawVendorAddress: (tempRoles) => tempRoles.IS_UPCAMPUS,

    featureUseCreateCMFeeInvoice: (tempRoles) => tempRoles.IS_HARBOR,
    featureUseShowAllCostItemsInDraw: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_TITAN_CORP || tempRoles.IS_SUMMIT,
    featureUseShowSectionsInDraw: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_MRA || tempRoles.IS_SUMMIT,

    featureUseDrawReportInvoiceForm: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_TITAN_CORP,

    featureUseDrawReportOverrideForKW: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    featureUseDrawReportCoverPage: (tempRoles) => !tempRoles.IS_KENNEDY_WILSON,
    featureUseDrawReportPropertyLine: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    featureUseDrawReportTotalCurrentDraw: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    featureUseDrawReportTotalBudget: (tempRoles) => false,
    featureUseDrawReportVendor: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,
    featureUseInvoicesDrawNumber: (tempRoles) =>
      tempRoles.IS_MRA ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_FCP ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER,
    featureUseInvoicesDrawTitle: (tempRoles) => 
      tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureUseDrawReportCoverPagLabels: (tempRoles) => !tempRoles.IS_SUMMIT,
    featureUseDrawSummaryOnSecondPage: (tempRoles) => tempRoles.IS_SUMMIT,

    featureUseDrawReportSubmittedDate: (tempRoles) => !tempRoles.IS_KENNEDY_WILSON && !tempRoles.IS_EOS,
    featureUseDrawReportInvoiceDate: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_EOS,
    featureReplaceCostItemsWithDrawCategory: (tempRoles) => tempRoles.IS_EOS,

    featureUseDrawTotalInCustomReports: (tempRoles) => !tempRoles.IS_RADCO,

    // Revise the formula for “Total Costs to Date” to be →
    // Contract Invoices + Non-Contract Invoices
    featureDrawTotalInvoices: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL,

    featureUseDrawReportRemainingBudgetMinusTotalApplications: (tempRoles) => tempRoles.IS_TITAN_CORP,

    featureUseDrawReportAgainstDrawCategories: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,
    // feature allows to show paid column in document inventory table of draw report
    featureUseDrawReportInvoicePaidToDate: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS,
  });

  // Allows for individual selection of projects for Custom reports
  const featureReportsCustomReportsShellFilter = (/** @type {_Roles} */ tempRoles) => true;

  const _REPORTING_CUSTOM_REPORTS = typeMustExtend(mapType, {
    // custom report fields that came with budget summary report
    featureUseAcquisitionBudgetReportFields: (tempRoles) => tempRoles.IS_TOURMALINE,

    /*** Custom Reports module in Banner. Users have ability to access custom reports from Reports page */
    featureUseCustomReports: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MRA ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_MORGAN_CONTRACTS ||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_RUDIN ||
      tempRoles.IS_MAA ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_FCP ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_BANNER_DEMO_ENV_1 ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_HARBOR||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_WILLOW_BRIDGE,

    // Reiles on featureUseGLCodeBudgets
    featureUseCustomReportsBalanceToFinishColumn: (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_UPCAMPUS,

    featureUseIncreasedCustomReportColumnsLimit: (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_UPCAMPUS,
    
    /** Invoice Report Group in Custom Reporting */
    featureUseInvoicesCustomReport: (tempRoles) => true,

    /** Breakdown invoices at sov level in custom report */
    featureUseSOVbreakDownForInvoicesCustomReport: (tempRoles) => true,
    // For Custom Reports
    featureUseMaxBudgetCommittedColumn: (tempRoles) =>
      tempRoles.IS_TOURMALINE || tempRoles.IS_CLEAR_HEIGHT || tempRoles.IS_MRA,
    featureUseInvoiceMaxRemainingColumn: (tempRoles) =>
      tempRoles.IS_TOURMALINE || tempRoles.IS_CLEAR_HEIGHT || tempRoles.IS_MRA,
    featureUseCustomReportHoldsColumn: (tempRoles) =>
      !tempRoles.IS_TOURMALINE &&
      !tempRoles.IS_MORGAN &&
      !tempRoles.IS_MORGAN_BUDGETING &&
      !tempRoles.IS_MORGAN_CONTRACTS &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_STARWOOD &&
      !tempRoles.IS_GARDINER_AND_THEOBALD &&
      !tempRoles.IS_DAYRISE &&
      !tempRoles.IS_DEMO_ENV_GL,
    
    featureUseCustomReportCurrentDrawAmtColumn: (tempRoles) => tempRoles.IS_UPCAMPUS,
    featureUseCustomReportPreviouslyDrawnColumn: (tempRoles) => tempRoles.IS_UPCAMPUS,
    featureUseCustomReportVendorNamesColumn: (tempRoles) => tempRoles.IS_UPCAMPUS,

    /** Ability to export Notes Column through Custom Reports. 
     *  Only the latest note will be exported
     */
    featureUseExportNotesColumn: (tempRoles) =>
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_RIDC || 
      tempRoles.IS_MAA || 
      tempRoles.IS_SUMMIT || 
      tempRoles.IS_OSSO ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_ABACUS,
    
    /** Turn ons the Comments column in [GL-Alias] table */
    featureUseExportGLAliasNotesColumn: (tempRoles) => 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_RADCO || 
      tempRoles.IS_UPCAMPUS || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseAccountGroup: (tempRoles) => tempRoles.IS_STARWOOD,
    featureUseCustomTemplateReports: (tempRoles) =>
      !tempRoles.IS_LIVCOR_ROI,

    featureUseStandardForecastCustomReports : (tempRoles) =>
      !tempRoles.IS_EOS && !tempRoles.IS_DEMO_ENV_GU,
     

    featureUseCustomReportShellsProjectCategory: (tempRoles) =>
      tempRoles.IS_TOURMALINE || tempRoles.IS_ACC || tempRoles.IS_ACC || tempRoles.IS_WATERTON,

    featureUseCustomReportForSubGroupHighmark: (tempRoles) => tempRoles.IS_STARWOOD,
    featureUseCustomReportsShowArchived: (tempRoles) => true,

    featureUseCustomReportCoColumns: (tempRoles) => 
      !_CHANGE_ORDERS.featureUseAllowChangeOrderExistingSOV(tempRoles),

    featureRenameHoldsToBalance: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,
    featureReportGroupDefaultRenosOnly: (tempRoles) => tempRoles.IS_LIVCOR_ROI,
    featureHideSavedReports: (tempRoles) => tempRoles.IS_LIVCOR_ROI,

    featureReportsCustomReportsShellFilter,

    // Requires individual shell selection, only doable through
    // Adds a column with the most recent draw title, and a run down of how those invoices are
    // propogated through the SOVs
      featureUseMostRecentDrawAmount: (tempRoles) => featureReportsCustomReportsShellFilter(tempRoles),

    /**
     * Depends on {@link F.featureReportsCustomReportsShellFilter}!
     * 
     * For Report Group "Cost Codes - Property" and "Cost Codes - Project",
     * when only one Shell is selected, add checkbox `includeSections`.
     */
    featureReportsCustomReportsSectionsGlCodes: (tempRoles) =>
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DAYRISE,

    /**
     * Depends on {@link F.featureReportsCustomReportsShellFilter}!
     * 
     * For Report Group "Cost Items",
     * when only one Shell is selected, add checkbox `includeSections`.
     */
    featureReportsCustomReportsSectionsCostItems: (tempRoles) => true,

    /**
     * Depends on {@link F.featureReportsCustomReportsShellFilter}!
     * 
     * For Report Group "[GL-Alias]-Project", 
     * when only one Shell is selected, add checkbox `includeSections`.
     */
    featureReportsCustomReportUseGLCodeSection: (tempRoles) => tempRoles.IS_UPCAMPUS || tempRoles.IS_DAYRISE,
  });

  const _REPORTING_OTHER = typeMustExtend(mapType, {
    // will be deprecated after merging feature: reporting phase 2 - cashflows
    featureUseBudgetSummaryReport: (tempRoles) => tempRoles.IS_TOURMALINE,

    featureUsePropertyCostReport: (tempRoles) => tempRoles.IS_GARDINER_AND_THEOBALD,

    featureUsePropertyReport: (tempRoles) => tempRoles.IS_HARBOR,
    featureChangeReporting: (tempRoles) =>
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_LIVCOR_ROI ||
      tempRoles.IS_AIR ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,

    featureUseExportCostTracker: (tempRoles) => true,

    featureUseAllProjectReport: (tempRoles) => tempRoles.IS_BANNER || tempRoles.IS_MRA,

    // For Budget Summary Export
    featureUseRemainingToSpendColumn: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** Remaining Contract Paid column in Shell Page. Remaining Contract Paid = Committed - Paid to Date */
    featureUseRemainingContractPaidColumn: (tempRoles) => tempRoles.IS_RADCO || tempRoles.IS_FLYNN,

    /** Invoice and PTD columns that calculates Invoice and PTD for the current year. 
     *  Calculation use Invoice Date to determine if an invoice in current year
     */
    featureUseYTDInvoiceColumns: (tempRoles) => 
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIR  ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseContractPaidToDateForBalanceColumn  : (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_UPCAMPUS,

    /** Invoice Report option within Reports page. Export team wide invoices in excel */
    featureUseTeamInvoicesReport: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MRA ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_CLEAR_HEIGHT||
      tempRoles.IS_INTRUST_PROPERTY_GROUP ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_MAA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_GARDINER_AND_THEOBALD ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_PRG_REAL_ESTATE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** relies on featureUseTeamInvoicesReport */
    featureUseTeamInvoicesReportBasedOnSOVs: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_KENNEDY_WILSON,

    featureUseIndustriousMilestoneReport: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || tempRoles.IS_MRA || tempRoles.IS_RIDC,

    featureUseLaunchOutputReport: (tempRoles) => tempRoles.IS_INDUSTRIOUS,

    featureUseVendorCoordOutputReport: (tempRoles) => tempRoles.IS_INDUSTRIOUS,
    featureUseKPISnapshot: (tempRoles) => tempRoles.IS_INDUSTRIOUS,
    featureUseKPIReport: (tempRoles) => tempRoles.IS_INDUSTRIOUS,
    featureUseVendorInvoicesReport: (tempRoles) =>
      tempRoles.IS_BANNER || tempRoles.IS_FCP || tempRoles.IS_KENNEDY_WILSON,
    featureExportProjectSOVs: (tempRoles) => tempRoles.IS_CLEAR_HEIGHT,

    /** Shows Team Logo in the Notification Emails sent to Vendors */
    featureUseTeamLogoInVendorEmails: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_FCP ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_APRIL_HOUSING ||
      tempRoles.IS_OLYMPUS_PROPERTY ||
      tempRoles.IS_29TH_STREET,
  });

  /**
   * See also [Milestones System in Notion](https://notion.so/382a0135a1ea4b14be45761cb423a5d0)
   */
  const _MILESTONES = typeMustExtend(mapType, {
    featureMilestoneTasks: (tempRoles) =>
      tempRoles.IS_MAA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO ||
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS,

    featureMilestoneExport: (tempRoles) =>
      tempRoles.IS_MAA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_UPCAMPUS ||
      true,

    featureMilestoneTableExport: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || tempRoles.IS_SUMMIT || tempRoles.IS_RIDC || true,

    featureMilestoneGanttVis: (tempRoles) =>
      tempRoles.IS_MAA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_HARBOR ||
      tempRoles.IS_MRA ||
      tempRoles.IS_KPR ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS,

    /** Make the Gantt Default tab in Milestone section */
    featureMilestoneGanttDefaultTab: (tempRoles) => 
      tempRoles.IS_MAA || 
      tempRoles.IS_KPR ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT,

    /** Always Export Year Granularity in Gantt Chart */
    featureGanttExportYear: (tempRoles) => tempRoles.IS_SUMMIT || tempRoles.IS_ACC || tempRoles.IS_ABACUS,

    featureGanttExportClarity: (tempRoles) => tempRoles.IS_SUMMIT || tempRoles.IS_ABACUS,

    featureMilestoneGanttEditorPromptNotes: (tempRoles) =>
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_KPR ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ,

    /** Disabling this was a requirement from MAA */
    featureMilestoneEditEndDate: (tempRoles) => !tempRoles.IS_MAA,

    /** Provide ability to block holidays in the date picker. Holidays are excluded from Duration calculations */
    featureUseProjectHolidays: (tempRoles) => tempRoles.IS_INDUSTRIOUS || tempRoles.IS_AIRBNB || tempRoles.IS_ABACUS,

    featureMilestoneAutoadjustTriggeredByPredecessorEnd: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || tempRoles.IS_MAA || tempRoles.IS_KPR || tempRoles.IS_ACC_DEV || tempRoles.IS_ACC || tempRoles.IS_AIRBNB,

    /** Sorts Milestones by Start Date as oppose to End Date which is the default */
    featureMilestonesSortByStartBeforeEnd: (tempRoles) => tempRoles.IS_MAA || tempRoles.IS_ACC || tempRoles.IS_AIRBNB,
    featureUseIndustriousLogMilestoneDates: (tempRoles) => tempRoles.IS_INDUSTRIOUS || tempRoles.IS_KPR,

    /**
     * If true, the `_messageTextForDependenciesLost` will have Cancel button only, and no Continue button.
     */
    featureMilestonesNoContinueDependencyDrop: (tempRoles) => tempRoles.IS_MAA,

    /** Ability to import a milestone template with start/end date specified. We will import
     *  the template as-is. Settings -> Company Settings -> Excel Templates -> Milestones
     */
    featureMilestoneUseDatesFromTemplate: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_MAA || 
      tempRoles.IS_KPR || 
      tempRoles.IS_MAGNOLIA || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_ACC ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_STONEWEG,

    /**
     * Convert their Milestones system from **Standard Predecessors Logic** to **Advanced Predecessors Logic**
     * 
     * Basically it takes the Predecessor List of the Milestone and adds options for
     * Linkage Type & Lag (Min Delay) on each predecessor
     * 
     * See also [Milestones System in Notion](https://notion.so/382a0135a1ea4b14be45761cb423a5d0)
     * 
     * See also {@link F.featureMilestonesParentsExactlyFollowChildren} which is an optional add-on
     * but neither of the two features necessarily depend on each other. :)
     */
    featureMilestonePredecessorsAdvanced: (tempRoles) => tempRoles.IS_INDUSTRIOUS || tempRoles.IS_KPR || tempRoles.IS_ACC_DEV || tempRoles.IS_AIRBNB,

    /**
     * If `false`, there is auto-expand parent logic if children are moved or expanded, but nothing to shrink the children.
     * If `true`, the parents will match exactly the min/max of its children.
     * The exact match of children will take precedence over any following of predessors if the two features are in conflict.
     */
    featureMilestonesParentsExactlyFollowChildren: (tempRoles) => 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_ACC ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_ABACUS,
  });

  const _DOCUSIGN = typeMustExtend(mapType, {
    /** Teams using DocuSign Integration */
    featureUseDOCUSIGN: (tempRoles) =>
      tempRoles.IS_MORGAN || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KETTLER,

    featureDocusignContractAgentTagName: (tempRoles) => {
      if (tempRoles.IS_MORGAN) return 'AVP';
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_DEMO_ENV_GL) return 'Senior Vice President';
      if (tempRoles.IS_TIDES || tempRoles.IS_STARWOOD) return 'DocuSign signator';
      return '';
    },
    featureDocusignContractConditionalFields: (tempRoles) => {
      const fields = {
        // used for conditional routing to agent2
        TextAgent2Name: { getValue: () => '' },
      };
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION) {
        fields.TextAgent2Name.getValue = (approvalPackage) =>
          parseFloat(approvalPackage.bid?.total || 0) > 25e3 ? 'Todd Darling' : '';
      }
      if (tempRoles.IS_29TH_STREET) {
        fields.TextAgent2Name.getValue = (approvalPackage) =>
          parseFloat(approvalPackage.bid?.total || 0) > 10e3 ? 'Ryan Berger' : '';
      }
      return fields;
    },

    featureDocusignCOAgentTagName: (tempRoles) => {
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO) return 'Senior Vice President';
      if (tempRoles.IS_STARWOOD) return 'DocuSign signator';
      return '';
    },
    featureDocusignCOConditionalFields: (tempRoles) => {
      const fields = {
        // used for conditional routing to agent2
        TextAgent2Name: { getValue: () => '' },
      };
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION) {
        fields.TextAgent2Name.getValue = (approvalPackage) =>
          parseFloat(approvalPackage.changeOrder?.total || 0) > 25e3 ? 'Todd Darling' : '';
      }
      if (tempRoles.IS_29TH_STREET) {
        fields.TextAgent2Name.getValue = (approvalPackage) =>
          parseFloat(approvalPackage.bid?.total || 0) > 10e3 ? 'Ryan Berger' : '';
      }
      return fields;
    },

    /** DocuSign Integration using Implicit Code Grant Flow */
    featureUseSendContractDocusign: (tempRoles) => 
      tempRoles.IS_MAGNOLIA || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_BANNER || 
      tempRoles.IS_WATERTON ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_KETTLER,

    /** Ability to automatically add documents to the Docusign template. */
    featureUseAPDocumentsInDocusignEnvelope: (tempRoles) => 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_FLYNN ||
      tempRoles.IS_FCP ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_STARWOOD ||
      tempRoles.IS_KETTLER,

    /** Provide an additional text field in Contract room to capture email for DS signatory for Vendor
     *  IMPORTANT: Make sure there is a Cost Item tag with exact name: "Vendor DocuSign Email" set for 
     *  Contract Room location */  
    featureUseVendorDocuSignEmailTagName: (tempRoles) => {
      if(tempRoles.IS_FCP || 
        tempRoles.IS_29TH_STREET || 
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
        tempRoles.IS_FLYNN || 
        tempRoles.IS_LANTOWER || 
        tempRoles.IS_FLYNN ||
        tempRoles.IS_TIDES ||
        tempRoles.IS_STARWOOD ||
        tempRoles.IS_KETTLER) 
        return 'Vendor DocuSign Email';
      else return '';
    }
    
  });

  const _APPROVALS = typeMustExtend(mapType, {
    featureLongTermBudgetApprovals: (tempRoles) => tempRoles.IS_ACC || tempRoles.IS_BANNER || tempRoles.IS_DAYRISE,
    featureLongTermBudgetRequirePriceQuantityAndTimeFrame: (tempRoles) => tempRoles.IS_MORGAN,
    featureContractGroupApprovals: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT || tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS,
    featureUseApprovalSectionCostItemFinancials: (tempRoles) =>
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION && 
      !tempRoles.IS_MILEWAY_DEMO && 
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT && 
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,
    
    featureUseApprovalSectionGlCodeFinancials: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    featureUseApprovalSectionGlCodeFinancialsAllGlCodes: (tempRoles) => 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS,
    featureHideGLCodeFinancialsOnInvoices: (tempRoles) => tempRoles.IS_CARDINAL,
    featureShowGLCodeFinancialsOnBudgetApproval: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT,

    featureGlCodeFinancialsAllowedColumsForContract: (tempRoles) => {
      if(tempRoles.IS_CARDINAL_DEVELOPMENT){
        return ['BUDGET_REVISED', 'COMMITTED', 'CUSTOM_REPORT_AUTO_FORECAST', 'DRAW_BALANCE']
      }
      if ( tempRoles.IS_CARDINAL ) {
        return ['BUDGET_REVISED', 'PENDING_COS', ];
      }
      if (tempRoles.IS_UPCAMPUS || tempRoles.IS_ABACUS) {
        return ['BUDGET_REVISED'];
      }
      // No restrictions
      return null;
    },
    featureGlCodeFinancialsAllowedColumsForCO: (tempRoles) => {
      if(tempRoles.IS_CARDINAL_DEVELOPMENT){
        return ['BUDGET_REVISED', 'APPROVED_BIDS', 'PENDING_COS', 'APPROVED_COS', 'COMMITTED','CUSTOM_REPORT_AUTO_FORECAST', 'DRAW_BALANCE' ]
      }
      if ( tempRoles.IS_CARDINAL ) {
        return ['BUDGET_REVISED', 'PENDING_COS' ];
      }
      if (tempRoles.IS_UPCAMPUS || tempRoles.IS_ABACUS) {
        return ['BUDGET_REVISED'];
      }
      // No restrictions
      return null;
    },
    featureGlCodeFinancialsAllowedColumsForInvoice: (tempRoles) => {
      if(tempRoles.IS_CARDINAL_DEVELOPMENT){
        return ['BUDGET_REVISED', 'COMMITTED','CUSTOM_REPORT_CURRENT_CONTRACT_BALANCE', 'INVOICED_NON_CONTRACT', 'CUSTOM_REPORT_AUTO_FORECAST', 'DRAW_BALANCE']
      }
      
      if ( tempRoles.IS_CARDINAL || tempRoles.IS_UPCAMPUS || tempRoles.IS_ABACUS) {
        return ['BUDGET_REVISED'];
      }
      // No restrictions
      return null;
    },
    featureGlCodeFinancialsAllowedColumsForBudget: (tempRoles) => {
      if(tempRoles.IS_CARDINAL_DEVELOPMENT){
      return ['BUDGET_ORIGINAL', 'BUDGET_TRANSFER' ,'BUDGET_REVISED', 'CUSTOM_REPORT_PROJECTED_OVER_UNDER','DRAW_PERCENT_COMPLETE','DRAW_BALANCE']
      }
      return null;
    },
    featureGlCodeFinancialsColumnEstimatedCosts: (tempRoles) => tempRoles.IS_CARDINAL,
    featureGlCodeFinancialsColumnLeftToSpendAfterApproval: (tempRoles) =>
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
      tempRoles.IS_MILEWAY_DEMO || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,
    
    featureGlCodeFinancialsColumnCommittedAfterApproval: (tempRoles) => 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,
    
      
    featureGlCodeFinancialsColumnBudgetRemainingAfterApproval: (tempRoles) => 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_UPCAMPUS || 
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,
      
    featureUseBudgetApproval: (tempRoles) =>
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_BANNER ||
      tempRoles.IS_ACC ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_KPR ||
      tempRoles.IS_RADCO || 
      tempRoles.IS_OLYMPUS_DEVELOPMENT ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIR ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    featureDetailedContractCostBreakdown: (tempRoles) => tempRoles.IS_MORGAN, 
     
    featureAddDocumentsToApprovedChangeOrder: (tempRoles) => tempRoles.IS_TOURMALINE || tempRoles.IS_BANNER,

    featureAddDocumentsToApprovedBid: (tempRoles) => tempRoles.IS_TOURMALINE || tempRoles.IS_BANNER,

    featureAddDocumentsToApprovedInvoice: (tempRoles) => tempRoles.IS_TOURMALINE || tempRoles.IS_BANNER,

    useFeatureApprovalFlowNotification: (tempRoles) => true,

    useFeatureLastApprover: (tempRoles) => tempRoles.IS_TOURMALINE,

    /** Provides ability to add extra approvers midstream an approval flow */
    featureAddApprover: (tempRoles) => 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_BANNER || 
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_DAYRISE || 
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_FCP ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_STONEWEG,

      /** Used in combination with useFeatureRemoveManagerFromApproval
       * If useFeatureRemoveManagerFromApproval is true, then this feature is useless
       * If useFeatureRemoveManagerFromApproval is false, then this feature is useful
       * Make sure if a team is added here, it is removed from useFeatureRemoveManagerFromApproval
      */
    featureRemoveManagerFromApprovalSelectTypes: (/** @type {_Roles} */ tempRoles) => {
      if (
        tempRoles.IS_FCP
      ) {
        return ['Invoice', 'Change Order'];
      }
      return [];
    },

    // If turned on then it will automatically skip the submitter if they are present in approval flow.
    useFeatureRemoveManagerFromApproval: (tempRoles) =>
      (tempRoles.IS_BANNER && !tempRoles.IS_BANNER_ADMIN) || // !tempRoles.IS_BANNER_ADMIN saves us from updating many Cypress tests
      tempRoles.IS_TIDES ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_WATERTON ||
      tempRoles.IS_ACC ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_FLYNN ||
      IS_ENTERPRISE_MFA(tempRoles) ||
      tempRoles.IS_DEMO_ENV_CAPEX ||
      tempRoles.IS_AIR ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_29TH_STREET,

    featureUseApprovalPageTables: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_MORGAN_BUDGETING ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

      featureHideCurrentPendingBudgetApprovalsTable: (tempRoles) => tempRoles.IS_CARDINAL,
      featureHideGLCodeFinacialsTableTags: (tempRoles) => tempRoles.IS_CARDINAL || tempRoles.IS_CARDINAL_DEVELOPMENT,
      featureHideGLCodeFinacialsPendingBids: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT,
      featureHideGLCodeFinacialsPendingInvoices: (tempRoles) => tempRoles.IS_CARDINAL_DEVELOPMENT,
      featureUseUpdatedGlCodeFinancialsColumns: (tempRoles) => tempRoles.IS_CARDINAL,


    featureUseNotifyAllOnApprovalCancellation: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles) || tempRoles.IS_FCP || tempRoles.IS_UPCAMPUS,

    createInvoiceContractorIfMissing: (tempRoles) => true,

    useOnlyGLCodeForProjectNamesWhenCreatingForInvoice: (tempRoles) => tempRoles.IS_HARBOR,

    featureUseMFATags: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureEditMFATagsOnUnplannedCPAs: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseSpecificValidatorForDV: (tempRoles) =>
      // eslint-disable-next-line no-nested-ternary
      tempRoles.IS_KENNEDY_WILSON
        ? 'mchalikian@kennedywilson.com'
        : tempRoles.IS_RIDC
          ? 'vmiller@ridc.org'
          : false,
    featureUseSpecifyDvBuilding: (tempRoles) => tempRoles.IS_KENNEDY_WILSON || tempRoles.IS_RIDC,
    featureUseDrawReportDocumentsComments: (tempRoles) => tempRoles.IS_KENNEDY_WILSON,

    /** Ability to Cancel a Contract post approval from contract room. 
     *  Available to both submitter and Admin */
    featureUseContractCancelAvailableForAdmin: (tempRoles) => 
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MRA ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIR ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FCP ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_EOS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MAA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Provide a Cancel Button for Contract Approval flow. Cancel nukes the contract object but
     * available in the bid room to resubmit
     */
    featureUseApprovalForContractCancel: (tempRoles) =>
      tempRoles.IS_BANNER ||
      tempRoles.IS_CONVENE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_MORGAN ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_EOS ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_KENNEDY_WILSON ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIR ||
      tempRoles.IS_KETTLER ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_MRA ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_ACC ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,

    /** Provide a Cancel button for cancelling an invoice during approval flow. Action nukes the data */
    featureUseApprovalForInvoiceCancel: (tempRoles) =>
      tempRoles.IS_CONVENE ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_EOS ||
      tempRoles.IS_ACC_DEV ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_MILEWAY_DEMO ||
      tempRoles.IS_KENNEDY_WILSON || 
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_BMC_INVESTMENTS ||
      tempRoles.IS_MAGNOLIA ||
      tempRoles.IS_SUMMIT ||
      tempRoles.IS_AIRBNB ||
      tempRoles.IS_FCP ||
      tempRoles.IS_TITAN_CORP ||
      tempRoles.IS_ACC ||
      tempRoles.IS_AIR ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FLYNN ||
      tempRoles.IS_INDUSTRIOUS ||
      tempRoles.IS_MRA ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_RIDC ||
      tempRoles.IS_TIDES ||
      tempRoles.IS_TOURMALINE ||
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_LANTOWER ||
      tempRoles.IS_GRIFFIN ||
      tempRoles.IS_29TH_STREET ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_STONEWEG,


    featureUseShowOtherProjectCostsOnContractApproval: (tempRoles) =>
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION && 
      !tempRoles.IS_MILEWAY_DEMO && 
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT  && 
      !tempRoles.IS_ACC_DEV && 
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,


    featureUseShowOtherProjectCostsOnChangeOrderApproval: (tempRoles) =>
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION && 
      !tempRoles.IS_MILEWAY_DEMO && 
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT  && 
      !tempRoles.IS_ACC_DEV && 
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,


    featureUseShowOtherProjectCostsOnInvoiceApproval: (tempRoles) =>
      !tempRoles.IS_METROVATION &&
      !tempRoles.IS_ACACIA_CAPITAL_CORPORATION &&
      !tempRoles.IS_MILEWAY_DEMO &&
      !tempRoles.IS_CARDINAL && 
      !tempRoles.IS_CARDINAL_DEVELOPMENT &&
      !tempRoles.IS_ACC_DEV &&
      !tempRoles.IS_UPCAMPUS &&
      !tempRoles.IS_ABACUS &&
      !tempRoles.IS_DEMO_ENV_GL,


    featureUseCaptureOwnershipTagAtInvoiceAproval: (tempRoles) => tempRoles.IS_INDUSTRIOUS,
    featureUseNotifySpecificUsersOnContractApproval: (tempRoles) => tempRoles.IS_MRA,
    featureTurnOnContractMaterialsOnlyByDefault: (tempRoles) => false,
    featureMaterialsOnlyText: (tempRoles) => {
      if (tempRoles.IS_MORGAN || tempRoles.IS_MORGAN_BUDGETING) return 'Materials Only';
      if (
        tempRoles.IS_BANNER ||
        tempRoles.IS_ACACIA_CAPITAL_CORPORATION || 
        tempRoles.IS_MILEWAY_DEMO || 
        tempRoles.IS_FLYNN || 
        tempRoles.IS_FCP || 
        tempRoles.IS_DEMO_ENV_GL ||
        tempRoles.IS_29TH_STREET ||
        tempRoles.IS_LANTOWER ||
          tempRoles.IS_TIDES ||
          tempRoles.IS_AIR ||
          tempRoles.IS_STARWOOD
        ) 
        return 'Skip DocuSign';
      return null; // client does not use isMaterialsOnly flag
    },
    featureUseLinkToInvoiceApprovalPackage: (tempRoles) => true,
    
    /** Option to select a secondary DocuSign Template in Contract Room when submitting a contract.
     *  Different teams, want to be able to use different names in the UI as to what the seconday
     *  template name is called. 
     *  NOTE: DEPRECATED - Replaced by Multi-template support
     */
    featurePOContractText: (tempRoles) => {
      if (tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_MILEWAY_DEMO || tempRoles.IS_DEMO_ENV_GL || tempRoles.IS_BANNER) return 'PO Contract';
      if (tempRoles.IS_FCP) return 'AIA Form';
      if (tempRoles.IS_29TH_STREET) return 'Renovations Contract';
      if (tempRoles.IS_AIR) return 'Long Form';
      return null; // client does not use isMaterialsOnly flag
    },

    // relies on featurePOContractText
    featurePOContractTurnOnByDefault: (tempRoles) => tempRoles.IS_FCP,

    /** Force user to enter a comment when rejecting a Change Order */
    featureRequireCommentsOnRejectionChangeOrder: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_LANTOWER,

    /** Force user to enter a comment when rejecting a Invoice */
    featureRequireCommentsOnRejectionInvoice: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_FCP ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL,

    /** Force user to enter a comment when rejecting a Contract/Bid */
    featureRequireCommentsOnRejectionBid: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_CARDINAL_DEVELOPMENT || 
      tempRoles.IS_CARDINAL || 
      tempRoles.IS_DEMO_ENV_GU || 
      tempRoles.IS_UPCAMPUS ||
      tempRoles.IS_CLEAR_HEIGHT ||
      tempRoles.IS_ACACIA_CAPITAL_CORPORATION ||
      tempRoles.IS_DAYRISE ||
      tempRoles.IS_FCP ||
      tempRoles.IS_OSSO ||
      tempRoles.IS_ABACUS ||
      tempRoles.IS_DEMO_ENV_GL ||
      tempRoles.IS_LANTOWER,

    featureRequireAttachmentsOnApprovalChangeOrder: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU || tempRoles.IS_29TH_STREET,
    featureRequireAttachmentsOnApprovalInvoice: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_MORGAN || tempRoles.IS_DEMO_ENV_GU,
    featureRequireAttachmentsOnApprovalBid: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,
    
    /** Validation to check if a Document is attached when Submitting a Change Order */
    featureRequireAttachmentsOnSubmitChangeOrder: (tempRoles) => 
      tempRoles.IS_EOS || 
      tempRoles.IS_ACC_DEV || 
      tempRoles.IS_DEMO_ENV_GU ||
      tempRoles.IS_29TH_STREET,

    featureRequireAttachmentsOnSubmitContractBid: (tempRoles) => tempRoles.IS_EOS || tempRoles.IS_DEMO_ENV_GU,

    /**
     * Obtain valid approval flow based on the value of a dropdown tag for a given cost item
     * Value should be the cost item tag's name
     *
     * ONLY FOR WATERTON'S DEMO
     */
    featureValidApprovalFlowByCostItemTag: (tempRoles) => {
      if (tempRoles.IS_WATERTON) {
        return 'Job Subtype';
      }
      return false;
    },

    featureAutoBudgetRevisionForSov: (tempRoles) =>
      tempRoles.IS_ACC_DEV,

    /**
     * Before using the assigned Approval Flow from settings, buildings, etc,
     * it will first always check for any manually db inserted flow on the same team
     * with matching `overrideMatchGlCodes` and that will take precedence.
     * 
     * This feature has similarity to {@link F.featureValidApprovalFlowByCostItemTag}
     * 
     * Not safe for non-EMFA teams.
     */
    featureEmfaOverrideApprovalFlowByGlCode: (tempRoles) => tempRoles.IS_APRIL_HOUSING,

    /**
     * - Multi-User Tag Type
     * - Approval Flows can be assigned to a Multi-User Tag instead of just a single User or single User Tag
     *   - Any one of the approvers in the set can approve the entire step.
     * 
     * Implemented by creating a 'grouping manager profile' (`{ asGroupingManagerProfile: { $ne: null }`) and
     * using that as the key to the `approvalPackage.orderStatus` dictionary.
     * 
     * The current approach of multi-user approval flows is concerning to some of the developers.
     * Ideally if this feature gets more widespread we will refactor any of the 'grouping manager profiles'
     * to be their own collection or something.
     */
    featureMultiUserTagsAndApprovals: (tempRoles) => tempRoles.IS_STARWOOD || tempRoles.IS_MORGAN,

    /** Hides budget projection amount and percentage in invoice approval package screen */
    featureHideBudgetProjectionInvoiceApproval: (tempRoles) => tempRoles.IS_ACACIA_CAPITAL_CORPORATION || tempRoles.IS_ABACUS || tempRoles.IS_DEMO_ENV_GL,

  });

  const _ENTERPRISE_MFA = typeMustExtend(mapType, {
    // Enterprise MFA Feature tags
    /** Livor has completely different Dashboard, etc */
    featureHideCompanySettings: (tempRoles) => tempRoles.IS_LIVCOR,

    featureSettingsOnlyShowProperties: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY ,

    IS_ENTERPRISE_MFA,
    IS_ENTERPRISE_MFA_ADMIN,

    IS_ENTERPRISE_MFA_OP: (tempRoles) =>
      tempRoles.IS_LIVCOR_OPERATING_PARTNER ||
      tempRoles.IS_OLYMPUS_PROPERTY_OPERATING_PARTNER ||
      tempRoles.IS_APRIL_HOUSING_OPERATING_PARTNER,

    IS_ENTERPRISE_MFA_OP_ADMIN,

    IS_ENTERPRISE_MFA_CAPEX_MEMBER,

    IS_ENTERPRISE_MFA_ANALYST,

    IS_ENTERPRISE_MFA_SUBGROUP_TEMPLATE_DOUBLE: (tempRoles) =>
      tempRoles.IS_LIVCOR_SUBGROUP_TEMPLATE_DOUBLE ||
      tempRoles.IS_OLYMPUS_PROPERTY_SUBGROUP_TEMPLATE_DOUBLE ||
      tempRoles.IS_APRIL_HOUSING_SUBGROUP_TEMPLATE_DOUBLE,

    featureUseSubGroupApprovalTemplates: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseCancelCPA: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseCancelCPAOnCPAPage: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseReassignProjectForCPA: (tempRoles) => tempRoles.IS_APRIL_HOUSING_ADMIN || tempRoles.IS_OLYMPUS_PROPERTY,
    featureShowProjectStatusOnCPA: (tempRoles) => tempRoles.IS_LIVCOR,


    useFeatureValidApprovalFlowOverride: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseAssignGLCode: (tempRoles) => IS_ENTERPRISE_MFA_ADMIN(tempRoles),

    featureUseAnalyst: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseOperatingPartnerAdmin: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseSkipAwardedBidID: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseLivcorImportMenuOption: (tempRoles) =>
      IS_ENTERPRISE_MFA_ADMIN(tempRoles) ||
      IS_ENTERPRISE_MFA_CAPEX_MEMBER(tempRoles) ||
      IS_ENTERPRISE_MFA_OP_ADMIN(tempRoles),

    featureUseLivcorAddProjectMenuOption: (tempRoles) =>
      IS_ENTERPRISE_MFA_ADMIN(tempRoles) ||
      IS_ENTERPRISE_MFA_CAPEX_MEMBER(tempRoles) ||
      tempRoles.IS_APRIL_HOUSING ||
      IS_ENTERPRISE_MFA_OP_ADMIN(tempRoles),

    featureUseEnterpriseMFAFinancialMap: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseEnterpriseMFACashflowProjected: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),

    featureUseShellCodeMap: (tempRoles) => IS_ENTERPRISE_MFA(tempRoles),
    featureUseEditCurrentYearDescription: (tempRoles) => tempRoles.IS_APRIL_HOUSING,
    featureUseProjectJustificationColumn: (tempRoles) => tempRoles.IS_APRIL_HOUSING,

    // Adds Building code column for April Housing
    featureUseBUColumn: (tempRoles) => tempRoles.IS_APRIL_HOUSING,

    // When creating a CPA, only planned projects will show up in planned, and documents are required to continue
    featureUseStricterCPACreation: (tempRoles) => tempRoles.IS_APRIL_HOUSING,


    featureTurnOffImportValidation: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureRenameBUToPropertyCode: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureExportProjectsColumnPropertyCode: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY || tempRoles.IS_LIVCOR,

    featureUseReversedBudgetVariance: (tempRoles) => tempRoles.IS_APRIL_HOUSING,

    featureUseCPAProjectSelectorDescription: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseSemiColonDelimitedShellTags: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,
    featureUseUpdateProjectFinancialsOnInvoiceImport: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseMFAYearSelector: (tempRoles) =>
      tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY || tempRoles.IS_APRIL_HOUSING,
    featureUseRenamePlannedProjects: (tempRoles) => tempRoles.IS_LIVCOR,

    featureUseRoleCOO: (tempRoles) => tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY,

    featureRenameCOOToPAO: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleBusinessManager: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleRegionalVPOfOperations: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleDirectorOfAssetManagement: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleSeniorVPOfOperations: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleRegionalManager: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleBlackstone: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleProjectCoordinator: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleSeniorDoC: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleRegionalDoC: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleVPOfCapEx: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleVPOfAssetManagement: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleSVPOfAssetManagement: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleEVP: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseRoleSrAssetManager: (tempRoles) => tempRoles.IS_LIVCOR,

    featureUsePropertyLegalNameColumn: (tempRoles) => tempRoles.IS_LIVCOR,

    featureRenameCMFilterToRM: (tempRoles) => tempRoles.IS_OLYMPUS_PROPERTY,

    /** Long Term: Read only and hides import button, Defer project disabled */
    featureBudgetingSeasonTemporaryLock: (tempRoles) => 
      ( tempRoles.IS_LIVCOR && !tempRoles.IS_LIVCOR_ANALYST && !tempRoles.IS_BANNER_EMAIL )
      || ( tempRoles.IS_APRIL_HOUSING && !tempRoles.IS_APRIL_HOUSING_ADMIN && !tempRoles.IS_APRIL_HOUSING_ANALYST),

    useFeatureChooseOverBudgetFlow: (tempRoles) => tempRoles.IS_LIVCOR,

    useFeatureUnplannedCPAMinThreshold: (tempRoles) => tempRoles.IS_LIVCOR || tempRoles.IS_APRIL_HOUSING,

    featureUseBHSubGroup: (tempRoles) => tempRoles.IS_LIVCOR_SUBGROUP_BH,

    featureUseGreystarSubGroup: (tempRoles) => tempRoles.IS_LIVCOR_SUBGROUP_GREYSTAR,

    featureUseTakeCMFee: (tempRoles) => tempRoles.IS_APRIL_HOUSING,
    
    featureUseOverwriteCurrentSpendDefaultValue: (/** @type {_Roles} */ tempRoles) => {
      if (tempRoles.IS_APRIL_HOUSING) {
        return false;
      }
      return true;
    },

    useFeatureGenerateGreystarPDF: (tempRoles) => tempRoles.IS_LIVCOR_SUBGROUP_GREYSTAR,

    useFeatureGenerateGreystarContract: (tempRoles) => tempRoles.IS_LIVCOR_SUBGROUP_GREYSTAR,

    useFeatureGenerateBHPDF: (tempRoles) => tempRoles.IS_LIVCOR_SUBGROUP_BH,

    featureUseBannerUploadTasks: (tempRoles) => !IS_ENTERPRISE_MFA(tempRoles),

    featureCompanyWideApprovalsTable: (tempRoles) => !IS_ENTERPRISE_MFA(tempRoles),

    featureSendCommentEmail: (tempRoles) => !IS_ENTERPRISE_MFA(tempRoles) && !tempRoles.IS_STARWOOD,

    featureUseCPAUnplannedOnApproveDefaultsToInprogress: (tempRoles) => tempRoles.IS_APRIL_HOUSING || tempRoles.IS_OLYMPUS_PROPERTY,

    featureEMFAInvoices: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING || tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY,

    featureEMFATotalInvoices: (tempRoles) => tempRoles.IS_LIVCOR,

    featureUseCurrentYearDeleteProject: (tempRoles) => tempRoles.IS_APRIL_HOUSING_ADMIN || tempRoles.IS_OLYMPUS_PROPERTY,

    featureUseLongTermDeleteProject: (tempRoles) => tempRoles.IS_APRIL_HOUSING_ADMIN || tempRoles.IS_OLYMPUS_PROPERTY,

    featureEMFAInvoicesDVSum: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING || tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY,

    featureEMFAApprovedSpend: (tempRoles) =>
      tempRoles.IS_APRIL_HOUSING || tempRoles.IS_LIVCOR || tempRoles.IS_OLYMPUS_PROPERTY,

    featureEMFAAddInvoiceToProject: (tempRoles) =>
      IS_ENTERPRISE_MFA_OP_ADMIN(tempRoles) || tempRoles.IS_BANNER_EMAIL,

    featurePropertyInfoUserTagRestrictedToSubGroups: (tempRoles) => !tempRoles.IS_OLYMPUS_PROPERTY,
  });

  const _ENTERPRISE_MFA_LONG_TERM_TABLE = /** @type {const} */ ({
    featureUseDiscretionaryWorkColumn: (tempRoles) => !tempRoles.IS_APRIL_HOUSING,
    featureUseNextYearCapitalStartDateColumn: (tempRoles) => tempRoles.IS_APRIL_HOUSING,
    featureUseNextYearCapitalEndDateColumn: (tempRoles) => tempRoles.IS_APRIL_HOUSING,
  });

  const _VENDOR_FEATURES = typeMustExtend(mapType, {
    featureUseVendorReleaseRetainage: (tempRoles) =>
      tempRoles.IS_MRA || 
      tempRoles.IS_INDUSTRIOUS || 
      tempRoles.IS_CONVENE || 
      tempRoles.IS_RIDC ||
      tempRoles.IS_STONEWEG ||
      tempRoles.IS_CARDINAL ||
      tempRoles.IS_CARDINAL_DEVELOPMENT,
  });

  return /** @type {const} */ ({
    ..._UNCATEGORIZED,
    ..._PERMISSIONS,
    ..._TAGS,
    ..._INVOICES,
    ..._VALIDATIONS,
    ..._DATA_VALIDATIONS,
    ..._VENDORS,
    ..._SETTINGS_GENERAL,
    ..._EXPORT_CONFIGURATION,
    ..._CONTRACT_ROOM,
    ..._BID_ROOM,
    ..._CM_FEES,
    ..._CHANGE_ORDERS,
    ..._CASHFLOW,
    ..._DASHBOARD_TOP_TILES,
    ..._DASHBOARD_TABS,
    ..._DASHBOARD_COST_COLUMNS,
    ..._DASHBOARD_HIDE_THINGS,
    ..._DASHBOARD_BUDGETING,
    ..._DASHBOARD_ADD_PROJ_SHELL_MODAL,
    ..._DASHBOARD_MISC,
    ..._DASHBOARD_DEPRECATED,
    ..._SHELL_TABS,
    ..._SHELL_COST_TRACKER_COLUMNS,
    ..._SHELL_MISC,
    ..._SHELL_HARBOR,
    ..._SHELL_DEPRECATED,
    ..._DRAW_REPORTING,
    ..._REPORTING_CUSTOM_REPORTS,
    ..._REPORTING_OTHER,
    ..._MILESTONES,
    ..._DOCUSIGN,
    ..._APPROVALS,
    ..._ENTERPRISE_MFA,
    ..._ENTERPRISE_MFA_LONG_TERM_TABLE,
    ..._VENDOR_FEATURES,
    ..._PROJECT_SHELL_RENOVATIONS,
  });
})();

//------------------------------------------------------------------------------

/** @typedef {typeof FEATURE_GENERATOR_MAP} F */

const FEATURE_KEYS = Object.keys(FEATURE_GENERATOR_MAP);
/** @typedef {keyof F} FeatureKey */

/**
 * @typedef {import("../../server-ts/src/types/team").TeamRolesValues} TeamRolesValues
 * @typedef {import("../../server-ts/src/types/team").ITeamDoc} ITeamDoc
 * @typedef {import("../../server-ts/src/types/sub-group").ISubGroupDoc} ISubGroupDoc
 * 
 * @typedef {import("../../server-ts/src/types/manager-profile").IManagerProfileDoc} IManagerProfileDoc
 * @typedef {import("../client/libraries/managerProfile").ManagerProfileApiObj} ManagerProfileApiObj
 * @typedef {import('../client/libraries/managerProfile').ManagerProfileContext} ManagerProfileClient
 * @typedef {import("../server/utility/userUtilities").ManagerProfile} ManagerProfileServer
 * @typedef {ManagerProfileClient | ManagerProfileServer} ManagerProfileCommon
 */

/**
 * @typedef {{}
 *   & _TempRoleFunctionsSeed_roles
 *   & _TempRoleFunctionsSeed_withBanner
 *   & _TempRoleFunctionsSeed_managerProfile_subGroupNames
 *   & _TempRoleFunctionsSeed_subGroupTemplates
 *   & _TempRoleFunctionsSeed_teamName
 *   & _TempRoleFunctionsSeed_managerProfile_companies
 *   & _TempRoleFunctionsSeed_ufg
 * } _Roles
 */
/** @typedef {keyof _Roles} _RoleKey */

/** @typedef {FeatureKey | _RoleKey | UserFeatureKey} FeatureKeyEx */

/**
 * @typedef {{ [key in `${KNOWN_TEAM_KEYS}_${TeamRolesValues}`]?: true }} _TempRoleFunctionsSeed_roles
 * @typedef {{ IS_BANNER_EMAIL?: true }} _TempRoleFunctionsSeed_withBanner
 * @typedef {{ [key in `${KNOWN_TEAM_KEYS}_SUBGROUP_${KNOWN_MANAGER_PROFILE_SUB_GROUP_NAMES}`]?: true }}  _TempRoleFunctionsSeed_managerProfile_subGroupNames
 * @typedef {{ [key in `${KNOWN_TEAM_KEYS}_SUBGROUP_TEMPLATE_${Uppercase<Exclude<ISubGroupDoc["template"], null | undefined>>}`]?: true }}  _TempRoleFunctionsSeed_subGroupTemplates
 * @typedef {{ [key in KNOWN_TEAM_KEYS]?: true }} _TempRoleFunctionsSeed_teamName
 * @typedef {{ [key in `${KNOWN_TEAM_KEYS}_${KNOWN_MANAGER_PROFILE_COMPANY_NAMES}`]?: true }} _TempRoleFunctionsSeed_managerProfile_companies
 * @typedef {{ [key in `${KNOWN_TEAM_KEYS}_UFG_${KNOWN_UFG_NAMES}`]?: true }} _TempRoleFunctionsSeed_ufg
 */

/**
 * @template T
 * @typedef {T extends null | undefined ? false : T} UndefinedIsFalse
 */

const _TEMP_ROLES_CACHE = Symbol('_TEMP_ROLES_CACHE');

/**
 * @param manager -
 * - `src/server` will pass {@link ManagerProfileServer}
 * - `src/client` will pass {@link ManagerProfileApiObj}
 * - `src/client` may pass `''` empty string when `getManagerProfile` returns `{ status: 200, response: null }`
 *    which via `src/server` will result in empty string response
 *    as per jsdoc comments in `type SrcServerResJson`
 */
function getFeatureMapBasedOnManager(/** @type {ManagerProfileApiObj | ManagerProfileServer | ''} */ manager) {
  const tempRoles = getTempRoles(manager);
  const featureMap = _mainSimpleFeatureMap(tempRoles);
  const ufgs = featuresBasedOnUserFeaturesGroup(manager);
  const res = {
    ...tempRoles,
    ...featureMap,
    ...ufgs,
  };
  return res;
}

function _mainSimpleFeatureMap(/** @type {_Roles} */ tempRoles) {
  /** @typedef {typeof FEATURE_GENERATOR_MAP} FnMap */

  const featureMap = /** @type {{ [key in keyof FnMap ]: UndefinedIsFalse<ReturnType<FnMap[key]>>}} */ ({});
  for (const key of FEATURE_KEYS) {
    /** @type {any} type any required here because sometimes `featureMap[key]` only allows `true` and other times it only allows `false` which would otherwise result in ts errpr: Type 'boolean' is not assignable to type 'never'. */
    (featureMap)[key] = FEATURE_GENERATOR_MAP[key](tempRoles) ?? false;
  }

  return featureMap;
}

function getTempRoles(/** @type {ManagerProfileApiObj | ManagerProfileServer | ''} */ _manager) {
  if (_manager === '') {
    // src/client  may pass '' empty string when getManagerProfile returns { status: 200, response: null }
    // which via src/server will result in empty string response
    return {};
  }

  const manager = /** @type {typeof _manager & { [_TEMP_ROLES_CACHE]?: _Roles }} */ (_manager);

  if (manager[_TEMP_ROLES_CACHE]) {
    return /** @type {typeof tempRoles} */ (manager[_TEMP_ROLES_CACHE]);
  }

  const tempRoles = _getTempRolesNoCache(manager);
  manager[_TEMP_ROLES_CACHE] = tempRoles;
  return tempRoles;
}

function _getTempRolesNoCache(/** @type {ManagerProfileApiObj | ManagerProfileServer} */ manager) {
  const tempTeamName = manager.team ? manager.team.name : '';

  const teamNameUpper = createFeatureKey(tempTeamName);
  const keyIsTeam = /** @type {KNOWN_TEAM_KEYS} */ (createFeatureKey(`IS_${tempTeamName}`));

  /** @type {_TempRoleFunctionsSeed_roles} */
  const tempRoleFunctions_roles = {};
  manager?.role?.forEach((role) => {
    tempRoleFunctions_roles[`IS_${teamNameUpper}_${role}`] = true;
  });

  /** @type {_TempRoleFunctionsSeed_withBanner} */
  const tempRoleFunctions_withBanner = {};
  if (isBannerEmail(manager?.email)) {
    tempRoleFunctions_withBanner.IS_BANNER_EMAIL = true;
  }

  /** @type {_TempRoleFunctionsSeed_managerProfile_subGroupNames} */
  const tempRoleFunctions_subGroupNames = {};
  const subGroupNameUpper = createFeatureKey(manager?.subGroupName) || 'NOSUBGROUP';
  tempRoleFunctions_subGroupNames[`IS_${teamNameUpper}_SUBGROUP_${subGroupNameUpper}`] = true;

  /** @type {_TempRoleFunctionsSeed_subGroupTemplates} */
  const tempRoleFunctions_subGroupTemplates = {};
  if (manager?.subGroupTemplate) {
    tempRoleFunctions_subGroupTemplates[
      `IS_${teamNameUpper}_SUBGROUP_TEMPLATE_${manager?.subGroupTemplate.toUpperCase()}`
    ] = true;
  }

  const tempRoleFunctions_teamName = _seedTeamName(teamNameUpper);

  /** @type {_TempRoleFunctionsSeed_managerProfile_companies} */
  const tempRoleFunctions_companies = {};
  tempRoleFunctions_companies[`IS_${teamNameUpper}_${manager?.companyName?.toUpperCase()}`] = true;

  /** @type {_TempRoleFunctionsSeed_ufg} */
  const tempRoleFunctions_ufgs = manager.userFeaturesGroup
    ? /** @type {const} */ ({
        [createFeatureKey(
          `${keyIsTeam}_UFG_${/** @type {KNOWN_UFG_NAMES} */ (manager.userFeaturesGroup.name)}`,
        )]: true,
      })
    : {};

  /** @type {_Roles} */
  const tempRoles = {
    ...tempRoleFunctions_roles,
    ...tempRoleFunctions_withBanner,
    ...tempRoleFunctions_subGroupNames,
    ...tempRoleFunctions_subGroupTemplates,
    ...tempRoleFunctions_teamName,
    ...tempRoleFunctions_companies,
    ...tempRoleFunctions_ufgs,
  };
  return tempRoles;
}

function getUserFeaturesMap(manager) {
  if (!manager._userFeaturesMap) {
    manager._userFeaturesMap = featuresBasedOnUserFeaturesGroup(manager);
  }
  return manager._userFeaturesMap;
}

function checkRequiredFeaturesForUserFeature(requiredFeatures, tempRoles) {
  let featureEnabled = true;
  requiredFeatures.forEach((feature) => {
    const featureFalgExists = FEATURE_GENERATOR_MAP[feature](tempRoles) ?? false;
    if (!featureFalgExists) {
      featureEnabled = false;
    }
  });
  return featureEnabled;
}

function featuresBasedOnUserFeaturesGroup(/** @type {ManagerProfileApiObj | ManagerProfileServer | ''} */ manager) {
  const tempRoles = getTempRoles(manager);

  if (manager !== '' && manager.userFeaturesGroup) {
    const { name, method, features: ufgFeatureKeys } = manager.userFeaturesGroup;

    const keyIsTeam = /** @type {KNOWN_TEAM_KEYS} */ (createFeatureKey(`IS_${manager.team?.name}`));
    const keyIsUfgName = createFeatureKey(`${keyIsTeam}_UFG_${name}`);

    const base = Object.fromEntries([[keyIsUfgName, true]]);

    const ufgMap = Object.fromEntries(ufgFeatureKeys.map((feature) => [feature, true]));

    if (method === 'INCLUDE') {
      return {
        ...base,
        ...Object.fromEntries(
          USER_FEATURES.map((feature) => {
            
            if (
              feature.requiredFeatureFlags?.length &&
              !checkRequiredFeaturesForUserFeature(feature.requiredFeatureFlags, tempRoles) &&
              ufgMap[feature.code]
            ) {
              return [feature.code, !ufgMap[feature.code]];
            }
            return [feature.code, !!ufgMap[feature.code]];
          }),
        ),
      };
    } else if (method === 'EXCLUDE') {
      return {
        ...base,
        ...Object.fromEntries(
          USER_FEATURES.map((feature) => /** @type {const} */ ([feature.code, !ufgMap[feature.code]])),
        ),
      };
    } else {
      return /** @type {never} */ (base);
    }
  }
  return Object.fromEntries(
    USER_FEATURES.map((f) => {
      if (
        f.requiredFeatureFlags?.length &&
        !checkRequiredFeaturesForUserFeature(f.requiredFeatureFlags, tempRoles)
      ) {
        return [f.code, false];
      }
      return [f.code, f.default ?? true];
    }),
  );
}

/**
 * Optimized to read a single feature in common code without evaluating all features
 * @param {ManagerProfileCommon} manager Manager object from client or server
 * @param {FeatureKeyEx} feature Key of feature
 * @returns {any} Value of feature
 */
function getSingleFeatureFromManagerCommon(manager, feature) {
  if ('team' in manager) {
    return getSingleFeatureFromManager(manager, feature);
  }
  return manager[feature];
}

/**
 * Optimized to read a single feature in server code without evaluating all features
 * @param {ManagerProfileServer} manager Manager object
 * @param {FeatureKeyEx} feature Key of feature
 * @returns {any} Value of feature
 */
function getSingleFeatureFromManager(manager, feature) {
  const userFeaturesMap = getUserFeaturesMap(manager);
  if (feature in userFeaturesMap) return userFeaturesMap[feature];

  const tempRoles = getTempRoles(manager);
  const featureGenerator = FEATURE_GENERATOR_MAP[feature];
  if (featureGenerator) {
    return featureGenerator(tempRoles) ?? false;
  }

  return tempRoles[feature] ?? false;
}
/**
 * Evaluates all features and stores them in the manager object
 * @param {ManagerProfileServer} manager Manager object
 * @returns {ReturnType<typeof getFeatureMapBasedOnManager>} Map of all feature key-values
 */
function getCachedFeatureMapFromManager(manager) {
  if (!manager._featureMap) {
    manager._featureMap = getFeatureMapBasedOnManager(manager);
  }
  return manager._featureMap;
}

/**
 * @param {import("../../server-ts/src/types/team").ITeamDoc["name"]} teamName
 */
function getFeatureBasedOnTeamName(teamName, /** @type {keyof typeof FEATURE_GENERATOR_MAP} */ feature) {
  const tempRoles = _seedTeamName(createFeatureKey(teamName));

  return FEATURE_GENERATOR_MAP[feature]?.(tempRoles) ?? false;
}

/**
 * @typedef {ReturnType<typeof getFeatureMapBasedOnTeamName>} TeamRoleFunctions
 * Generally used in the Vendor Portal in instead of `ManagerProfileContext`
 */
function getFeatureMapBasedOnTeamName(
  /** @type {import("../../server-ts/src/types/team").ITeamDoc["name"]} */ teamName,
) {
  const tempRoles = _seedTeamName(createFeatureKey(teamName));

  const featureMap = _mainSimpleFeatureMap(tempRoles);

  return {
    ...tempRoles,
    ...featureMap,
  };
}

function _seedTeamName(/** @type {ReturnType<typeof createFeatureKey>} */ teamNameUpper) {
  return /** @type {_TempRoleFunctionsSeed_teamName} */ ({
    [`IS_${teamNameUpper}`]: true,
  });
}

const REGEX_SPACE = / /g;
/**
 * @template {string} T
 */
function createFeatureKey(/** @type {T} */ str) {
  if (str == null) return /** @type {never} */ ('');
  return replaceSpaceWithUnderscore(str).toUpperCase() ?? '';
}

/**
 * @template {string} T
 */
function replaceSpaceWithUnderscore(/** @type {T} */ str) {
  // temporarily disable prettier here until #4224 prettier bugfix is merged and we are confident everyone has run npm install once since then
  // prettier-ignore
  return (
    /**
     * @type {T extends `${infer A} ${infer B} ${infer C} ${infer D} ${infer E}` ? `${A}_${B}_${C}_${D}_${E}` : T extends `${infer A} ${infer B} ${infer C} ${infer D}` ? `${A}_${B}_${C}_${D}` : T extends `${infer A} ${infer B} ${infer C}` ? `${A}_${B}_${C}` : T extends `${infer A} ${infer B}` ? `${A}_${B}` : T extends `${infer A}` ? `${A}` : T}
     */ (str.replace(REGEX_SPACE, '_'))
  );
}

module.exports = {
  USER_FEATURES,
  DEFAULT_DATE_OPTIONS,
  featuresBasedOnUserFeaturesGroup,
  getFeatureMapBasedOnManager,
  getSingleFeatureFromManagerCommon,
  getSingleFeatureFromManager,
  getCachedFeatureMapFromManager,
  getFeatureMapBasedOnTeamName,
};
